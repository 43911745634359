@if (item(); as r) {
  <span>{{ cities() }}</span>
  @if (r.typeBien?.length) {
    <app-text-round-separator />
    <span class="type-bien">{{ r.typeBien?.join(', ') }} </span>
  }
  @if (r.nb_pieces) {
    <app-text-round-separator />
    <span>{{ r.nb_pieces }} pièces</span>
  }
  @if (r.surface_from || r.surface_to) {
    <app-text-round-separator />
  }
  @if (r.surface_from) {
    <span>{{ r.surface_from }}m²</span>
  }
  @if (r.surface_from && r.surface_to) {
    <span> - </span>
  }
  @if (r.surface_to) {
    <span>{{ r.surface_to }}m²</span>
  }
}
