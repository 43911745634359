@let a = annonce();

<a [routerLink]="[MainRoutes.Annonce, a.id]">
	<div class="br-10px relative img-container">
		<div class="red-tags">
			<div class="{{ a.mandat_exclusif === 1 && a.statut === 0 ? 'red-tag display-text-12px' : 'hidden' }}">
				<span>{{ 'exclusivity' | transloco }}</span>
			</div>
			<div class="{{ a.statut === 1 ? 'red-tag display-text-12px' : 'hidden' }}">
				<span>{{ 'preliminary_sale_agreement_c' | transloco }}</span>
			</div>
			<div class="{{ a.statut === 11 ? 'red-tag display-text-12px' : 'hidden' }}">
				<span>{{ 'preliminary_sale_agreement' | transloco }}</span>
			</div>
			<div class="{{ a.coup_coeur === 1 ? 'red-tag display-text-12px heart-throb' : 'hidden' }}">
				<span>{{ 'heart_throb' | transloco }}</span>
			</div>
		</div>

		<img
			class="h-full w-full object-cover img-bien"
			[src]="a.photo[0]"
			(error)="onImgError($event)"
			alt=""
		/>
	</div>
</a>

<div class="typeBien mt-12px">
	<a [routerLink]="[MainRoutes.Annonce, a.id]">
		<div>
			<div class="display-text-16px">{{ a.type_bien }}</div>
			<!-- <div class="display-text-16px date">
				depuis le <span>{{ a.date_publication | date }}</span>
			</div> -->
		</div>
	</a>
	<button (click)="onRemoveFromfavoris()">
		<div class="button-favoris">
			<img src="/assets/imgs/heart-fill-icon.png" height="12px" width="16px" />
		</div>
	</button>
</div>

<a class="adresses" [routerLink]="[MainRoutes.Annonce, a.id]">
	<div class="adresse-bien">
		<span class="icon-location color-location"></span>
		<span class="display-text-16px ville">
			{{ a.ville }} ({{ a.code_postal }})
		</span>
	</div>
	<div class="mt-30px mb-30px">
		<span class="display-text-24px prix">
			{{ numberWithSpaces(a.prix) }}&nbsp;€
		</span>
	</div>
	<div class="display-text-18px enseigne">
		{{ a.agence.enseigne }}
	</div>
	<div class="adresse-agence">
		<span class="icon-location color-location"></span>
		<span class="display-text-16px ville">
			{{ a.agence.adresse }}, {{ a.agence.codepostal }} {{ a.agence.ville }}
		</span>
	</div>
</a>

<div class="contact-content">
	<app-button-telephone
		[telephone]="a.agence.telephone"
		aspect="arrow-blue"
		size="large"
	/>
	<app-button
		[link]="'/' + a.agence.slug + '/contact'"
		aspect="arrow-blue"
		id="{{ index() }}"
		text="contact"
		size="large"
	/>
</div>
