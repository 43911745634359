@let showError = error() && validationErrors();
@let optLabel = itemLabel();
@let optKey = itemKey();

<div class="select-field">
  <div class="select-field-label" [ngClass]="{ 'error': showError }">

    <select [id]="id()" [(ngModel)]="selected" [disabled]="disabled">
      @if (placeholder(); as ph) {
        <option value="">{{ ph | transloco }}</option>
      }

      @for (item of items(); track item) {
        @let text = optLabel ? item[optLabel] : item;
        @let value = optKey ? item[optKey] : item;

        <option [value]="value" [selected]="value === selected">
          @if (translate()) {
            {{ text | transloco }}
          } @else {
            {{ text  }}
          }

          @if (unit(); as u) {
            {{ u }}
          }
        </option>
      }
    </select>

    <label [for]="id()" [ngClass]="{ 'transformed': !selected }" data-testid="label">
      @if (icon(); as i) {
        <span class="{{ i }} not-color"></span>
      }

      {{ label() | transloco }}

      @if (required) {
        <span data-testid="required">*</span>
      }
    </label>
  </div>
</div>

@if (showError) {
  <app-form-errors [field]="ngControl" />
}
