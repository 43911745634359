<div class="agence-groupement" [routerLink]="['/' + agence.slug]">
	<div class="agence-groupement-img">
		<img
			class="h-full w-full object-cover"
			src="{{ agence.photo ?? '/assets/imgs/agence.jpg' }}"
			alt=""
		/>
	</div>
	<div class="agence-groupement-text">
		<h2>
			{{ agence.enseigne }}
		</h2>
		<div class="flex mb-12px items-center">
			<span class="icon-location fs-20px mr-10px grey-icon"></span>
			<p>
				{{ agence.adresse }} <span class="display-block">{{ agence.codepostal }} {{ agence.ville }}</span>
			</p>
		</div>
	</div>
</div>
