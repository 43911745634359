import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapTypebienApiToDisplay',
  standalone: false
})
export class MapTypebienApiToDisplayPipe implements PipeTransform {

  private annonceMap: Record<string, string> = {
    'Appartement': 'apartment',
    'Bastide': 'country_house',
    'Bureaux': 'offices',
    'Château': 'castle',
    'Commerce': 'trade',
    'Demeure': 'residence',
    'Demeure de Prestige': 'prestige_residence',
    'Fonds de commerce': 'commercial_property',
    'Hotel particulier': 'mansion_hotel',
    'Immeuble': 'building',
    'Local commercial': 'commercial_premises',
    'Loft': 'loft',
    'Longère': 'farmhouse',
    'Maison': 'house',
    'Maison bourgeoise': 'mansion_house',
    'Maison d\'architecte': 'architects_house',
    'Maison de maître': 'mansion_master',
    'Maison de pays': 'country_house',
    'Maison de village': 'village_house',
    'Mas': 'mas',
    'Moulin': 'mill',
    'Parking / box': 'carpark',
    'Péniche': 'barge',
    'Programme Neuf': 'new_real_estate_program',
    'Terrain': 'land',
    'Villa': 'villa'
  };

  transform(value: string): string {
    return this.annonceMap[value] ?? '';
  }
}
