import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Agence } from '@/models';

@Component({
  selector: 'app-agence-card',
  templateUrl: './agence-card.component.html',
  styleUrls: ['./agence-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AgenceCardComponent implements OnInit {
  @Input() agence!: Agence;

  public agenceImmobiliere!: boolean;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.agenceImmobiliere = this.router.url.startsWith('/agence-immobiliere');
  }

  onImgError(event: Event): void {
    (event.target as HTMLImageElement).src = '/assets/imgs/erabw.png';
  }
}
