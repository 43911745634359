<div class="blog">
	<div class="breadcrumb w-full">
		<div class="breadcrumb-content">
			<div class="breadcrumb-back" (click)="goBack()">
				<div class="breadcrumb-back-arrow"><span class="icon-arrow-left"></span></div>
				<p class="display-text-16px">
					{{ 'previous' | transloco | titlecase }}
				</p>
			</div>
			<div class="breadcrumb-links">
				<a routerLink="/">{{ 'homepage' | transloco }}</a>
				<span class="icon-chevron-right"></span>
				<span>{{ 'blog' | transloco }} </span>
			</div>
		</div>
	</div>

	@if (loading && !actualites.length) {
		<div class="map-loader">
			<div class="background-opacifier"></div>
			<div class="map-loader">
				<app-dot-loader />
			</div>
		</div>
	}

	<div class="main-containers" [ngClass]="{ 'no-actualite': !actualites.length }">
		<div class="flex w-full title">
			<h1 class="display-h2 mr-auto mb-20px">
				<em>{{ 'real estate news' | transloco }}</em>
			</h1>
		</div>
		<div class="description display-text-16px mb-40px">
			{{ 'real estate news description' | transloco }}
		</div>

		<app-tags-container [tags]="actualitesTags" [template]="tag" name="filter_by" class="mb-40px" />
		<ng-template #tag let-tag let-active="active" let-inactive="inactive">
			<tag (click)="toggleTag(tag.id)" [ngClass]="[actualitesTagsSelected.includes(tag.id) ? active : inactive]">
				@if (tag.icon) {
					<span class="mr-8px icon-{{tag.icon}}"></span>
				}
				<span class="tag">{{ tag.nom }}</span>
			</tag>
		</ng-template>

		<div class="actualites flex flex-row flex-wrap w-full mb-80px">
			@if (loading) {
				<div class="fake-loader">
					<app-dot-loader />
				</div>
			}

			@for (actualite of actualites	| paginate:{ itemsPerPage, currentPage, totalItems }; track actualite.id; let index = $index) {
				<app-blog-card
					[ngClass]="{ firstCard: index === 0, secondAndThirdCard: index === 1 || index === 2 }"
					(actualiteEmt)="getArticleDetails($event)"
					[actualite]="actualite"
					[agence]="agence"
					[index]="index"
					page="blog"
				/>
			}
		</div>

		@if (!loading) {
			<div class="not-found" [hidden]="!!actualites.length">
				<div class="flex items-center">
					<span class="display-h2 mr-10px">0</span> <span class="display-h2">{{ 'news' | transloco }}</span>
				</div>
				<img src="/assets/imgs/no-event.png" />
				<p class="display-text-15px">{{ 'no news found' | transloco }}</p>
			</div>
		}

		<app-pagination-link prompt="blog" [itemsPerPage]="itemsPerPage" />
	</div>
</div>
