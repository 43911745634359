@let showError = error() && validationErrors();
@let textarea = inputType === 'textarea';
@let suff = suffix();
@let inputId = id();

<div class="input-field">
  <div class="input-field-label" [ngClass]="{ 'error': showError, textarea }">

    @if (textarea) {
      <textarea [id]="inputId" [(ngModel)]="value" [disabled]="disabled" (focus)="focus = true" (blur)="focus = false"></textarea>
    } @else {
      <input [id]="inputId" [type]="inputType" [(ngModel)]="value" [disabled]="disabled" (focus)="focus = true" (blur)="focus = false" />
    }

    @let noValue = value === '' || value === undefined || value === null;

    <label [for]="inputId" [ngClass]="{ transformed: noValue && !focus }">
      @if (inputIcon) {
        <span class="{{ inputIcon }} not-color"></span>
      }

      {{ label() | transloco }}

      @if (required) {
        <span>*</span>
      }
    </label>

  </div>

  @if (showEye) {

    <span
      class="cursor-pointer"
      [ngClass]="{ 'icon-eye': inputType === 'password', 'icon-eye-off': inputType === 'text' }"
      (click)="togglePasswordVisibility()"
    ></span>

  } @else if (suff) {

    <p class="suffix" [ngClass]="{ 'error': showError }">{{ suff }}</p>

  }

</div>

@if (showError) {
  <app-form-errors [field]="ngControl" />
}

@if (passwordTips()) {
  <app-password-tips [field]="ngControl" />
}
