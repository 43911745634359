@let a = agence();

<app-modal-container>
	<div class="modal-legal-notice {{ pageType() ?? '' }}">
		<div class="container" [ngClass]="{ 'list-agences': !!agences() }">
			<div class="container-header flex mb-15px">
				<h5 class="display-h3-28px">{{ 'legal_notice_agency' | transloco }}</h5>
				<div (click)="onCloseModalLegalNotice()">
					<span class="icon-close closeDrawModal"></span>
				</div>
			</div>

			@if (agences(); as ags) {
				<div class="container-content" [ngClass]="{ 'list': !!agences }">
					@for (agency of ags; track agency.id) {
						<div class="mb-40px">
							<p class="display-text-14px">
								{{ 'company_name' | transloco }} : {{ agency.raison_sociale || '-' }}
							</p>
							<p class="display-text-14px">
								{{ 'trade_name' | transloco }} : {{ agency.enseigne || '-' }}
							</p>
							<p class="display-text-14px">
								{{ 'registered_address' | transloco }} : {{ agency.adresse_siege_social || '-' }}
							</p>
							<p class="display-text-14px">
								{{ 'phone' | transloco }} : {{ agency.telephone || '-' }}
							</p>
							<p class="display-text-14px">
								{{ 'email' | transloco }} : {{ agency.email || '-' }}
							</p>
							<p class="display-text-14px">
								{{ 'legal_form' | transloco }} : {{ agency.forme_juridique || '-' }}
							</p>
							<p class="display-text-14px">
								{{ 'amount_of_share_capital' | transloco }} : {{ agency.capital ? agency.capital + ' €' : '-' }}
							</p>
							<p class="display-text-14px">
								N°SIREN : {{ agency.siren || '-' }} {{ agency.rcs ? 'RCS ' + agency.rcs : '-' }}
							</p>
							<p class="display-text-14px">
								N°TVA intra : {{ agency.tva_cee || '-' }}
							</p>
							<p class="display-text-14px">
								{{ 'professional_card_number_transaction' | transloco }} : {{ agency.carte_transac || '-' }} CCI {{ agency.cartes_prefecture || '-' }}
							</p>
							<p class="display-text-14px">
								{{ 'professional_card_number_management' | transloco }} : {{ agency.carte_gestion || '-' }} CCI {{ agency.cartes_prefecture || '-' }}
							</p>
							<p class="display-text-14px">
								{{ 'guarantors_name_and_address' | transloco }} : {{ agency.caisse_garantie || '-' }} {{ agency.caisse_garantie_adresse || '-' }}
							</p>
							<p class="display-text-14px font-bold">
								{{ agency.non_detention_fond ? ('without_holding_funds' | transloco) : ('with_holding_funds' | transloco) }}
							</p>
							<p class="display-text-14px">
								{{ 'amount_of_the_financial_guarantee' | transloco }} : {{ agency.montant_garantie ? agency.montant_garantie + ' €' : '-' }}
							</p>
							<div class="display-text-14px">
								<p>
									{{ 'seizing_a_mediator_free' | transloco }} :
								</p>
								<p class="ml-10px">{{ agency.mediateur?.nom }}</p>
								<p class="ml-10px">{{ agency.mediateur?.email }}</p>
								<p class="ml-10px">{{ agency.mediateur?.adresse }}</p>
							</div>
							<p class="display-text-14px">
								{{ 'franchise_notice' | transloco }}
							</p>
							<app-button text="see_fees" [href]="agency.bareme" target="_blank" class="mt-15px" />
						</div>
					}
				</div>

			} @else if (a) {

				<div class="container-content">
					<p class="display-text-14px">
						{{ 'company_name' | transloco }} : {{ a.raison_sociale || '-' }}
					</p>
					<p class="display-text-14px">
						{{ 'trade_name' | transloco }} : {{ a.enseigne || '-' }}
					</p>
					<p class="display-text-14px">
						{{ 'registered_address' | transloco }} : {{ a.adresse_siege_social || '-' }}
					</p>
					<p class="display-text-14px">
						{{ 'phone' | transloco }} : {{ a.telephone || '-' }}
					</p>
					<p class="display-text-14px">
						{{ 'email' | transloco }} : {{ a.email || '-' }}
					</p>
					<p class="display-text-14px">
						{{ 'legal_form' | transloco }} : {{ a.forme_juridique ??'-' }}
					</p>
					<p class="display-text-14px">
						{{ 'amount_of_share_capital' | transloco }} : {{ a.capital ? a.capital + ' €' : '-' }}
					</p>
					<p class="display-text-14px">
						N°SIREN : {{ a.siren || '-' }} {{ a.rcs ? 'RCS ' + a.rcs : '-' }}
					</p>
					<p class="display-text-14px">
						N°TVA intra : {{ a.tva_cee || '-' }}
					</p>
					<p class="display-text-14px">
						{{ 'professional_card_number_transaction' | transloco }} : {{ a.carte_transac || '-' }} CCI {{ a.cartes_prefecture || '-' }}
					</p>
					<p class="display-text-14px">
						{{ 'professional_card_number_management' | transloco }}  : {{ a.carte_gestion || '-' }} CCI {{ a.cartes_prefecture || '-' }}
					</p>
					<p class="display-text-14px">
						{{ 'guarantors_name_and_address' | transloco }} : {{ a.caisse_garantie || '-' }} {{ a.caisse_garantie_adresse || '-' }}
					</p>
					<p class="display-text-14px font-bold">
						{{ a.non_detention_fond ? ('without_holding_funds' | transloco) : ('with_holding_funds' | transloco) }}
					</p>
					<p class="display-text-14px">
						{{ 'amount_of_the_financial_guarantee' | transloco }} : {{ a.montant_garantie ? a.montant_garantie + ' €' : '-' }}
					</p>
					<div class="display-text-14px">
						<p>{{ 'seizing_a_mediator_free' | transloco }}:</p>
						<p class="ml-10px">{{ a.mediateur?.nom }}</p>
						<p class="ml-10px">{{ a.mediateur?.email }}</p>
						<p class="ml-10px">{{ a.mediateur?.adresse }}</p>
					</div>
					<p class="display-text-14px">
						{{ 'franchise_notice' | transloco }}
					</p>
					<app-button text="see_fees" [href]="a.bareme" target="_blank" class="mt-15px" />
				</div>

			}

		</div>
	</div>
</app-modal-container>
