import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';

import { SearchLocation } from '@/models';

@Component({
  selector: 'app-search-bar-suggestion',
  templateUrl: './search-bar-suggestion.component.html',
  styleUrl: './search-bar-suggestion.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SearchBarSuggestionComponent {
  location = input.required<SearchLocation>();

  selected = signal(false);

  select(select: boolean): void {
    this.selected.set(select);
  }
}
