import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { RouteObserverService } from '@/services/route-observer.service';
import { BlogImmobilierService } from '@/services/blog.service';
import { ContentService } from '@/services/content.service';
import { AnnoncesService } from '@/services/annonces.service';
import { AgencesService } from '@/services/agences.service';
import { ConfigService } from '@/services/config.service';
import { Actualite, Agence, AgenceGroupe, Annonce, Avis, CurrentRoute, SearchFilters, SeoFaqEntry } from '@/models';
import { ANNONCE_STATE, MainRoutes } from '@/constants';

@Component({
  selector: 'app-metiers',
  templateUrl: './metiers.component.html',
  styleUrls: ['./metiers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class MetiersComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  public MainRoutes = MainRoutes;

  public metier!: string;
  public actualites?: Actualite[];
  public faqs: SeoFaqEntry[] = [];
  public isOpen = false;
  public gestion_locative?: any;
  public syndic_corpro?: any;
  public locaux_commerciaux?: any;
  public annonces: Annonce[] = [];
  public topAvisAgences?: Avis[];
  public agence?: Agence;
  public eraAgenceIds = [];
  public pathUrl!: string;
  public source = 'SOURCE.SITE_NATIONAL';
  public url_photos_from_BO!: string;
  public totalBiens = 0;
  public allAvisCount = 0;
  public avisCount?: number;
  public isGroupe = false;
  public currentRoute!: CurrentRoute;
  public blogLink = '';
  public avisLink = '';

  public typeBiens = {
    particulier: [
      'Maison',
      'Appartement',
      'Duplex',
      'Terrain',
      'Bien de Vacances',
      'Commerce',
      'Immeuble',
      'Propriété',
      'Ferme',
      'Parking-Box'
    ],
    professionnel: [
      'Local commercial',
      'Fonds de commerce',
      'Bureaux',
      'Entrepôts',
      'Terrains',
      'Cession de bail',
      'Murs'
    ]
  };

  constructor(
    private agencesService: AgencesService,
    private blogService: BlogImmobilierService,
    private annoncesService: AnnoncesService,
    private contentService: ContentService,
    private routeObserver: RouteObserverService,
    private cd: ChangeDetectorRef,
    configService: ConfigService,
  ) {
    this.url_photos_from_BO = configService.config.url_photos_from_BO;
  }

  ngOnInit(): void {
    this.currentRoute = this.routeObserver.currentRoute;

    const { url, data } = this.currentRoute;

    this.metier = data.metier!;
    this.pathUrl = url;

    this.subscriptions.add(
      this.agencesService.agence$.subscribe((agence) => {
        this.isGroupe = (agence?.type === 'groupe');
        this.agence = agence?.data;

        if (agence && this.agence) {
          if (this.isGroupe) {
            this.blogLink = MainRoutes.Groupes + '/' + this.agence.slug + MainRoutes.Blog;
            this.avisLink = MainRoutes.Groupes + '/' + this.agence.slug + '-' + this.agence.id + '/temoignages';
            this.avisCount = (this.agence as AgenceGroupe).avis_total;
          } else {
            this.blogLink = '/' + this.agence.slug + MainRoutes.Blog;
            this.avisLink = '/' + this.agence.slug + MainRoutes.Avis;
            this.avisCount = this.agence.avis.length;
          }
        } else {
          this.blogLink = MainRoutes.Blog;
          this.avisLink = MainRoutes.Avis;
          this.avisCount = undefined;
        }

        let ids: string | number = '';

        if (agence) {
          this.topAvisAllAgences(this.agence!.id);
          this.getAnnoncesHomePage(this.agence!.id);

          if (this.isGroupe) {
            const groupe = this.agence as AgenceGroupe;
            ids = groupe.agences.map((a) => a.era_id).toString();
          } else {
            ids = this.agence!.era_id;
          }

          this.getActualites(ids);
          this.agencesAvisCount();
          this.getAnnoncesHomePage();
        }

        this.cd.markForCheck();
      })
    );

    this.getContentGestionLocative();
    this.getContentSyndicCopro();
    this.getContentLocauxCommerciaux();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private topAvisAllAgences(agenceId?: number) {
    this.subscriptions.add(
      this.agencesService.getTopAvisAllAgences(agenceId).subscribe((data) => {
        this.topAvisAgences = data.slice(0, 2);
        this.cd.markForCheck();
      })
    );
  }

  private agencesAvisCount() {
    this.subscriptions.add(
      this.agencesService.getAllAgenceAvis().subscribe((data) => {
        this.allAvisCount = data.nbr_avis;
        this.cd.markForCheck();
      })
    );
  }

  private getContentGestionLocative() {
    this.subscriptions.add(
      this.contentService.getContentPages('GESTION_LOCATIVE').subscribe((data) => {
        this.gestion_locative = data.GESTION_LOCATIVE;
        this.cd.markForCheck();
      })
    );
  }

  private getContentSyndicCopro() {
    this.subscriptions.add(
      this.contentService.getContentPages('SYNDIC_COPROPRIETE').subscribe((data) => {
        this.syndic_corpro = data.SYNDIC_COPROPRIETE;
        this.cd.markForCheck();
      })
    );
  }

  private getContentLocauxCommerciaux() {
    this.subscriptions.add(
      this.contentService.getContentPages('LOCAUX_COMMERCIAUX').subscribe((data) => {
        this.locaux_commerciaux = data.LOCAUX_COMMERCIAUX;

        for (const [key, value] of Object.entries(this.locaux_commerciaux)) {
          const index = key.slice(-1);
          const content = this.locaux_commerciaux[`accordeon_title_${index}`];

          if (key.startsWith('accordeon_content') && content) {
            this.faqs.push({
              question: content,
              reponse: value as string
            });
          }
        }

        this.cd.markForCheck();
      })
    );
  }

  private getActualites(eraId?: string | number) {
    let tags = '';

    if (this.metier === 'gestion locative') {
      tags = '4';
    } else if (this.metier === 'gestion de syndic') {
      tags = '5';
    } else {
      tags = '';
    }

    if (eraId || tags) {
      this.subscriptions.add(
        this.blogService.getActualites(
          2, 1, 'desc', 'published_at', eraId ? 'SOURCE.AGENCE' : 'SOURCE.ERA', tags, eraId
        ).subscribe((data) => {
          this.actualites = data.data;
          this.cd.markForCheck();
        })
      );
    } else {
      this.topAvisAllAgences();
    }
  }

  private getAnnoncesHomePage(agenceId?: number) {
    const options: SearchFilters = {
      type_annonce: 'vente',
      exclusif: false,
      coup_coeur: false,
      typeBien: this.typeBiens.professionnel
    };

    this.subscriptions.add(
      this.annoncesService.getAnnonces(
        4, 'desc', ANNONCE_STATE, options, false, agenceId, this.isGroupe
      ).subscribe((data) => {
        this.totalBiens = data.meta.total || 0;
        this.annonces = data.data;
        this.cd.markForCheck();
      })
    );
  }
}
