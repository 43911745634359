import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { MainRoutes } from '@/constants';
import { CurrentRoute } from '@/models';

import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root'
})
export class RouteObserverService {
  private _currentRoute = new BehaviorSubject<CurrentRoute>({
    url: '',
    type: '',
    data: {},
    params: {},
    queryParams: {}
  });

  get currentRoute$(): Observable<CurrentRoute> {
    return this._currentRoute.asObservable().pipe(
      filter(({ url }) => !!url)
    );
  }

  get currentRoute(): CurrentRoute {
    return this._currentRoute.value;
  }

  constructor(
    private filterService: FilterService,
    private router: Router
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(({ urlAfterRedirects }) => {
      this.setPageTypeFromUrl(urlAfterRedirects);
    });
  }

  private setPageTypeFromUrl(url: string): void {
    let type = Object.values(MainRoutes).find((value) => {
      return url.startsWith(value);
    }) as string;

    if (type === undefined) {
      type = url.split('/')[1];
      if (type.length) {
        console.debug(`RouteObserverService: route not found in MainRoutes values, using "${type}"`);
      }
    }

    const currentRoute: CurrentRoute = {
      ...this.mergeRouteParams(),
      type,
      url
    };

    currentRoute.slugId = this.getSlugId(currentRoute);

    this.filterService.buildFiltersFromRoute(currentRoute);
    this._currentRoute.next(currentRoute);
  }

  /**
   * Merge route params, queryParams and config data
   * @returns An object containing the merged attributes
   */
  private mergeRouteParams(): {
    queryParams: Record<string, any>,
    params: Record<string, any>,
    data: Record<string, any>,
    fragment: string
  } {
    let route: ActivatedRouteSnapshot | null = this.router.routerState.snapshot.root;
    const fragment = route.fragment ?? '';
    let queryParams: any = {};
    let params: any = {};
    let data: any = {};

    do {
      queryParams = { ...queryParams, ...route.queryParams };
      params = { ...params, ...route.params };
      data = { ...data, ...route.data };

      route = route.firstChild;
    } while (route);

    return { queryParams, params, data, fragment };
  }

  /**
   * Decode slug url parameter if present.
   * @param currentRoute The current route
   * @returns The slug id or undefined
   */
  private getSlugId(currentRoute: CurrentRoute): number | undefined {
    const slug = currentRoute?.params['slug'];
    if (slug) {
      try {
        const id = slug.split(/[-]+/).pop();
        if (id) {
          const slugId = +id;
          return Number.isNaN(slugId) ? undefined : slugId;
        }
      }
      catch (_e) { /* empty */ }
    }
    return undefined;
  }
}
