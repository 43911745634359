@let cardType = type();
@let empl = emploi();
@let a = agence();

<div
	class="block-contact-agency-presentation"
	[ngClass]="cardType === 'agence' || cardType === 'negociateur' || cardType === 'annonce' ? 'flex-col' : 'flex-row'"
>
	<div
		[ngClass]="{
			'emploi': empl || cardType === 'era',
			'agence': cardType === 'agence' || cardType === 'negociateur'
		}"
		class="block-contact-agency-presentation-img"
	>
		<img
			[ngClass]="{ 'agence': cardType === 'agence' || cardType === 'negociateur' }"
			src="{{ a?.photo ?? '/assets/imgs/agence.jpg' }}"
			alt=""
		/>
	</div>

	<div class="block-contact-agency-presentation-address">
		<h1
			class="display-text-18px"
			[ngClass]="{ 'mt-30px': (cardType === 'agence' || cardType === 'negociateur' || cardType === 'annonce' || cardType === 'era') }"
		>
			{{ a?.enseigne || a?.nom }}
		</h1>

		<div class="display-flex mb-20px">
			@if (empl || (cardType === 'era')) {
				<p><span class="icon-location"></span></p>
			}
			<div>
				<p class="display-text-16px">{{ a?.adresse }}</p>
				<p class="display-text-16px">{{ a?.codepostal }} {{ a?.ville }}</p>
			</div>
		</div>

		@if (cardType !== 'noPhone') {
			@if (empl) {
				<app-button-telephone
					[telephone]="empl.agence.telephone"
					aspect="arrow-blue"
					size="large"
				/>
			}	@else	if (((cardType === 'era') && a) || (cardType === 'agence')) {
				<app-button-telephone
					[telephone]="a?.telephone"
					aspect="arrow-blue"
					size="large"
				/>
			}
		}

		@if (a) {
			@if (cardType === 'era') {
				<app-button
					[href]="a.slug"
					[text]="a.enseigne"
					[translate]="false"
					aspect="arrow-blue"
					size="large"
				/>
			} @else	if (cardType !== 'negociateur') {
				<app-button
					[link]="'/' + a.slug"
					aspect="arrow-blue"
					size="large"
					text="web"
				/>
			}
		}

	</div>
</div>