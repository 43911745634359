import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { Actualite, Agence, BlogPageMode } from '@/models';

@Component({
  selector: 'app-actualites-container',
  templateUrl: './actualites-container.component.html',
  styleUrl: './actualites-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ActualitesContainerComponent {
  readonly actualites = input<Actualite[]>();
  readonly agence = input<Agence>();
  readonly groupe = input(false);
  readonly page = input<BlogPageMode>();
}
