import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { ButtonAspect, ButtonSize, ButtonType } from '../button/button.component';

@Component({
  selector: 'app-button-loading',
  templateUrl: './button-loading.component.html',
  styleUrl: './button-loading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ButtonLoadingComponent {
  readonly aspect = input<ButtonAspect>('arrow-red-dark');
  readonly extraClasses = input('');
  readonly disabled = input(false);
  readonly loading = input(false);
  readonly text = input('');
  readonly size = input<ButtonSize>('large');
  readonly type = input<ButtonType>('submit');
}
