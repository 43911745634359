import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Annonce, AnnoncePOIS, ApiAnnonceResponse, ApiAnnoncesResponse, SearchFilters, TopCities } from '@/models';
import { StorageKey, StorageService } from './storage.service';
import { ConfigService } from './config.service';
import { ToolsService } from './tools.service';
import { SortType } from '@/constants';

@Injectable({
  providedIn: 'root'
})
export class AnnoncesService {
  private baseUrl: string;
  private hiddenAnnonces: number[] = [];

  constructor(
    private http: HttpClient,
    private toolsService: ToolsService,
    private storageService: StorageService,
    configService: ConfigService
  ) {
    this.baseUrl = `${configService.config.url}/annonces`;
    this.hiddenAnnonces = this.storageService.getObject(StorageKey.AnnoncesToHide) ?? [];
  }

  getAllAnnonces(
    itemsPerPage?: number,
    order_dir?: string,
    statut?: string[],
    type_annonce?: string,
    page?: number,
    agence_id?: number,
    is_groupe?: boolean,
    order_by?: string
  ): Observable<ApiAnnoncesResponse> {
    const params: any = {};

    params.per_page = itemsPerPage || 1;
    params.page = page || 1;

    if (order_dir) params.order_dir = order_dir;
    if (statut) params.statut = statut.join(',');
    if (type_annonce) params.type_annonce = type_annonce;
    if (agence_id) params.agence_id = agence_id;
    if (is_groupe) params.is_groupe = 1;
    if (order_by) params.order_by = order_by;

    return this.http.get<ApiAnnoncesResponse>(this.baseUrl, {
      params: new HttpParams({ fromObject: params })
    });
  }

  getAnnonces(
    itemsPerPage?: number,
    order_dir?: SortType,
    statut?: string[],
    filters: SearchFilters = {},
    hideAnnonces = true,
    agence_id?: number,
    is_groupe = false,
    order_by?: string
  ): Observable<ApiAnnoncesResponse> {

    const {
      exclusif, coup_coeur, polygon, typeBien, criteres, searchLocations = [],
      prix_from, prix_to, surface_from, surface_to, terrain_from, terrain_to,
      nb_pieces, nb_chambres, distance, type_annonce, fields, page,
      geoVillesIds, geoRegionsIds, geoDepartementsIds
    } = filters;

    const params: any = {};

    params.page = page || 1;

    if (type_annonce) params.type_annonce = type_annonce;
    if (distance) params.distance = distance;

    if (nb_chambres) params.nb_chambres = nb_chambres;
    if (nb_pieces) params.nb_pieces = nb_pieces;

    if (prix_from) params.prix_from = prix_from;
    if (prix_to) params.prix_to = prix_to;
    if (surface_from) params.surface_from = surface_from;
    if (surface_to) params.surface_to = surface_to;
    if (terrain_from) params.terrain_from = terrain_from;
    if (terrain_to) params.terrain_to = terrain_to;

    if (criteres?.length) criteres.forEach((c) => params[c] = true);

    // Search locations parsing
    if (searchLocations?.length) {
      const departements: number[] = [];
      const regions: number[] = [];
      const villes: number[] = [];

      searchLocations.forEach(({ type, id }) => {
        switch (type) {
          case 'departement': departements.push(+id); break;
          case 'region': regions.push(+id); break;
          case 'ville': villes.push(+id); break;
        }
      });

      if (departements.length) params.geo_departement_id = departements.toString();
      if (regions.length) params.geo_region_id = regions.toString();
      if (villes.length) params.geo_ville_id = villes.toString();

    } else {
      if (geoDepartementsIds) params.geo_departement_id = geoDepartementsIds.toString();
      if (geoRegionsIds) params.geo_region_id = geoRegionsIds.toString();
      if (geoVillesIds) params.geo_ville_id = geoVillesIds.toString();
    }

    params.per_page = itemsPerPage || 1;

    if (order_dir) params.order_dir = order_dir;
    if (order_by) params.order_by = order_by;
    if (exclusif) params.mandat_exclusif = 1;
    if (coup_coeur) params.coup_coeur = 1;
    if (polygon) params.polygon = polygon;
    if (fields) params.fields = fields;
    if (statut) params.statut = statut.join(',');
    if (typeBien?.length) params.type_bien = typeBien.join(',');
    if (hideAnnonces && this.hiddenAnnonces.length) params.hide = this.hiddenAnnonces.join(',');
    if (agence_id && !is_groupe) params.agence_id = agence_id;
    if (agence_id && is_groupe) params.groupe_id = agence_id;

    return this.http.get<ApiAnnoncesResponse>(`${this.baseUrl}/search`, {
      params: new HttpParams({ fromObject: params })
    });
  }

  getSimilarAnnonces(
    itemsPerPage: number,
    type_annonce: string,
    geo_ville_id: number,
    distance: number,
    nb_pieces: string,
    type_bien: string,
    order: string,
    exclusif: boolean,
    surface_from: number,
    surface_to: number,
    prix_from: number,
    prix_to: number,
    statut?: string[]
  ): Observable<ApiAnnoncesResponse> {
    const params: any = {};

    params.per_page = itemsPerPage || 1;

    if (type_annonce) params.type_annonce = type_annonce;
    if (distance) params.distance = distance;
    if (geo_ville_id) params.geo_ville_id = geo_ville_id;
    if (nb_pieces) params.nb_pieces = nb_pieces;
    if (type_bien?.length) params.type_bien = type_bien;
    if (surface_from) params.surface_from = surface_from;
    if (surface_to) params.surface_to = surface_to;
    if (prix_from) params.prix_from = prix_from;
    if (prix_to) params.prix_to = prix_to;
    if (order) params.order_dir = order;
    if (exclusif) params.mandat_exclusif = 1;
    if (statut) params.statut = statut.join(',');

    return this.http.get<ApiAnnoncesResponse>(`${this.baseUrl}/search`, {
      params: new HttpParams({ fromObject: params })
    });
  }

  getAnnoncesGeoloc(type_annonce: string, location: string, distance: number, statut?: string[]): Observable<ApiAnnoncesResponse> {
    /**
      We use decodeURIComponent here to prevent double encoding of the 'location' parameter.
      The 'geoloc' string might already contain encoded characters (e.g., '%2C' for a comma),
      and Angular's HttpParams will encode it again, leading to an incorrect format (e.g., '%252C').
      By decoding the string first, we ensure that HttpParams encodes it correctly only once.
     */
    const params: any = {};

    if (type_annonce) params.type_annonce = type_annonce;
    if (location) params.location = decodeURIComponent(location);
    if (distance) params.distance = distance;
    if (statut) params.statut = statut.join(',');

    return this.http.get<ApiAnnoncesResponse>(`${this.baseUrl}/search`, {
      params: new HttpParams({ fromObject: params })
    });
  }

  getAnnoncePoi(id: number): Observable<AnnoncePOIS> {
    return this.http.get<AnnoncePOIS>(`${this.baseUrl}/${id}/poi`);
  }

  getSimilarAnnoncesEstimate(
    type_bien?: string[],
    type_annonce?: string,
    location?: string,
    distance?: number,
    nb_pieces?: number,
    order?: string,
    exclusif?: boolean,
    coup_coeur?: boolean
  ): Observable<any> {
    const params: any = {};

    if (type_bien?.length) params.type_bien = type_bien;
    if (type_annonce) params.type_annonce = type_annonce;
    if (location) params.location = decodeURIComponent(location);
    if (distance) params.distance = distance;
    if (nb_pieces) params.nb_pieces = nb_pieces;
    if (order) params.order_dir = order;
    if (exclusif) params.mandat_exclusif = 1;
    if (coup_coeur) params.coup_coeur = 1;

    return this.http.get(`${this.baseUrl}/search`, {
      params: new HttpParams({ fromObject: params })
    });
  }

  getAllAnnoncesGeoloc(type_annonce: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/geoloc?limit=-1&type_annonce=${type_annonce}`);
  }

  getAnnonceById(id: number, view?: string): Observable<ApiAnnonceResponse> {
    return this.http.get<ApiAnnonceResponse>(`${this.baseUrl}/${id}${view ? `?${view}` : ''}`);
  }

  getTopAnnonces(type: 'Vente' | 'Location', geoloc: 'ville' | 'departement' | 'region'): Observable<any> {
    return this.http.get(`${this.baseUrl}/top?type=${type}&geoloc=${geoloc}&nbr=10`);
  }

  getTopCities(geoloc?: string, type_bien?: string, type?: string, nbr?: number): Observable<TopCities> {
    const params: any = {};

    if (geoloc) params.geoloc = geoloc;
    if (type_bien) params.type_bien = type_bien;
    if (type) params.type = type;
    if (nbr) params.nbr = nbr;

    return this.http.get<TopCities>(`${this.baseUrl}/top`, {
      params: new HttpParams({ fromObject: params })
    });
  }

  subscribeToOpenHouses(details: object): Observable<any> {
    return this.http.post(`${this.baseUrl}/openhouse`, details);
  }

  // HIDDEN ANNONCES PROCESSING

  hideAnnonce(annonceId: number): boolean {
    if (this.hiddenAnnonces.indexOf(annonceId) < 0) {
      this.hiddenAnnonces.push(annonceId);
      this.storageService.setObject(StorageKey.AnnoncesToHide, this.hiddenAnnonces);
      return true;
    }
    return false;
  }

  filterHidden(annonces: Annonce[]): Annonce[] {
    return (annonces ?? []).filter(({ id }) => this.hiddenAnnonces.indexOf(id) < 0);
  }

  sortAnnonces(annonces?: Annonce[]): Annonce[] {
    return this.filterHidden(annonces || []).toSorted(
      (a, b) => Date.parse(b.date_publication) - Date.parse(a.date_publication)
    ).toSorted(
      (a, b) => b.mandat_exclusif - a.mandat_exclusif
    );
  }
}
