@let f = field();

@if (f && f.errors) {
  <div class="form-error">
    @if (f.errors['required']) {
      <div>{{ 'field_required' | transloco }}</div>
    }
    @if (f.errors['min']) {
      <div>{{ 'field_min' | transloco: { min: f.errors['min'].min } }}</div>
    }
    @if (f.errors['max']) {
      <div>{{ 'field_max' | transloco: { max: f.errors['max'].max } }}</div>
    }
    @if (f.errors['email']) {
      <div>{{ 'incorrect email' | transloco }}</div>
    }
    @if (f.errors['validatePhoneNumber']?.valid === false) {
      <div>{{ 'SELL.PHONE_WRONG' | transloco }}</div>
    }
    @if (f.errors['mustMatch']) {
      <p>{{ 'PROFILE.password_and_confirm_not_match' | transloco }}</p>
    }
  </div>
}
