import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, Self, input, signal } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ToolsService } from '@/services/tools.service';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrl: './form-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FormSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  /**
   * items?: any[]
   *
   * This is the array of items provided as input to populate the options of the select field.
   * Each item in the array represents an option in the dropdown. The structure of these items
   * can be customized, and the labels or values to display can be further controlled using `itemKey` and `itemLabel`.
 */
  readonly items = input<readonly any[]>();
  /**
   * itemKey?: string
   *
   * If provided, `itemKey` specifies which field from each item in the `items` array should be
   * used as the value that is returned when the user selects an option. If this is not provided,
   * the entire item will be returned upon selection.
 */
  readonly itemKey = input<string>();
  /**
   * itemLabel?: string
   *
   * If provided, `itemLabel` specifies which field from each item in the `items` array should
   * be used as the label to display in the dropdown. If this is not provided, the entire item
   * will be used as the label.
 */
  readonly itemLabel = input<string>();
  readonly placeholder = input<string>();
  readonly id = input.required<string>();
  readonly icon = input<string>();
  readonly unit = input<string>();
  readonly options = input<Record<string, string>>();
  readonly label = input('select item');
  readonly validationErrors = input(true);
  readonly translate = input(false);

  public error = signal(false);
  public required = false;
  public disabled = false;

  private subscriptions = new Subscription();

  private onChange: any = () => {};
  private onTouched: any = () => {};
  private _selected?: string | number;

  /**
    The constructor uses Dependency Injection (DI) to access the NgControl directive and manually assign
    this component as the control's value accessor. This approach avoids the circular dependency issue
    caused by registering the component via NG_VALUE_ACCESSOR provider. By doing so, we directly set the
    component as the value accessor, allowing it to handle form control interactions (read and write value).
   */
  constructor(
    @Self() protected ngControl: NgControl,
    private toolsService: ToolsService,
    private el: ElementRef
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit() {
    const options = this.options();

    this.required = this.toolsService.hasRequiredValidator(this.ngControl.control);

    this.subscriptions.add(
      this.ngControl.statusChanges!.subscribe((status) => {
        this.error.set(!!this.ngControl.dirty && (status === 'INVALID'));
      })
    );

    if (options) {
      Object.entries(options).forEach(([key, value]) => {
        this.el.nativeElement[key] = value;
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @Input()
  get selected(): string | number | undefined {
    return this._selected;
  }

  set selected(val: string | number) {
    if (this._selected !== val) {
      this._selected = val;

      this.onChange(val);
      this.onTouched();
    }
  }

  // ControlValueAccessor methods

  writeValue(val: string | number): void {
    this._selected = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
