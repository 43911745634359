@let t = team();

<div class="contact-content display-text-16px">
  <app-button-telephone
    aspect="arrow-blue"
    [telephone]="phone"
    size="large"
  />

  @if (contact) {
    <app-button
      [link]="contact"
      aspect="arrow-blue"
      text="contact"
      size="large"
    />
  }

  @if (t && t?.status && showSite()) {
    <app-button
      [text]="(t.civilite === 'Monsieur') ? 'advisor_male' : 'advisor_female'"
      [link]="MainRoutes.Effectifs + '/' + (t.website_slug ?? t.id)"
      [fragment]="isAgence ? 'agence' : ''"
      size="large"
    />
  }
</div>
