<div class="tabs">
  @for (tab of tabs(); track tab) {
    <div (click)="selectTab(tab)" [class.active]="tab.active">
      {{ tab.text() }}
    </div>
  }
</div>
<div class="tabs-content {{contentClass()}}">
  <ng-content />
</div>
