import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrl: './rating-stars.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class RatingStarsComponent {
  readonly rate = input(0);
  readonly maxStars = input(5);
}
