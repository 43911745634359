@let nego = negociateur();

<div class="negociateur">
  <div class="flex items-center">
    <div class="image-container mr-20px">
      <img [src]="nego.photo" alt="" />
    </div>
    <div>
      <p class="display-text-18px">{{nego.nom}} {{nego.prenom}}</p>
      <p class="display-text-14px">{{nego.agence?.enseigne}}</p>
    </div>
  </div>
  <div class="contactContent display-text-16px mt-10px">
    <app-button-telephone
      telephone="{{ nego.telephone || nego.agence?.telephone}}"
      aspect="arrow-blue"
      size="large"
    />
  </div>
</div>
