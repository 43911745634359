import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

@Component({
  selector: 'app-rating-bars',
  templateUrl: './rating-bars.component.html',
  styleUrl: './rating-bars.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class RatingBarsComponent {
  readonly notes = input<number[]>([]);
  readonly total = input(1);

  readonly barClicked = output<number>();

  handleBarClick(index: number): void {
    this.barClicked.emit(index);
  }
}
