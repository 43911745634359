import { ChangeDetectionStrategy, Component, OnInit, input, output } from '@angular/core';

import { FilterService } from '@/services/filter.service';
import { ListDisplayMode } from '@/models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-mode',
  templateUrl: './view-mode.component.html',
  styleUrl: './view-mode.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { ngSkipHydration: 'true' },
  standalone: false
})
export class ViewModeComponent implements OnInit {
  readonly show = input<'large' | 'small'>('large');

  readonly modeChanged = output<ListDisplayMode>();

  mode: ListDisplayMode;

  constructor(
    private filterService: FilterService,
    private route: ActivatedRoute
  ) {
    this.mode = this.filterService.displayMode;
  }

  ngOnInit(): void {
    this.modeChanged.emit(this.mode);
  }

  switchMode(): void {
    this.mode = (this.mode === 'list') ? 'map' : 'list';
    this.filterService.updateDisplayMode(this.mode, this.route);
    this.modeChanged.emit(this.mode);
  }
}
