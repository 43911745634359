@let items = list();

@if (items?.length) {
  @if (title(); as t) {
    <li class="separator">{{ t | transloco }}</li>
  }
  @for (item of items; track item.id) {
    <li class="suggest" (click)="handleLocationSelect(item)">
      {{ item.nom }}
      <app-text-round-separator />
      @if (mode() === 'where') {
        <span>({{ item.code }})</span>
      }
    </li>
  }
}
