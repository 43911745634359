import { ChangeDetectionStrategy, Component, OnChanges, input } from '@angular/core';

import { RouteObserverService } from '@/services/route-observer.service';
import { Agence, Negociateur } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ContactCardComponent implements OnChanges {
  readonly team = input<Negociateur>();
  readonly agence = input<Agence>();
  readonly teamAll = input<any>();
  readonly showSite = input(false);

  public MainRoutes = MainRoutes;
  public isAgence = false;
  public isGroupe = false;
  public contact = '';
  public phone = '';

  constructor(routeObserver: RouteObserverService) {
    this.isGroupe = routeObserver.currentRoute.url.includes(MainRoutes.Groupes);
    this.isAgence = routeObserver.currentRoute.url.startsWith('/agence-');
  }

  ngOnChanges(): void {
    const teamAll = this.teamAll();
    const agence = this.agence();
    const team = this.team();

    this.contact = '';

    if (teamAll) {
      this.phone = teamAll.telephone || teamAll.agence.telephone || '';
    } else if (team || agence) {
      this.phone = team?.telephone || agence?.telephone || '';
    }

    if (teamAll) {
      this.contact = teamAll.status ? `${MainRoutes.Effectifs}/${teamAll.website_slug}` : `/${teamAll.agence.slug}`;
    } else if (agence && this.isGroupe) {
      this.contact = team?.status ? `${MainRoutes.Effectifs}/${team.website_slug}` : `${MainRoutes.Groupes}/${agence.slug}`;
      this.contact += `-${agence.id}`;
    } else if (team?.status) {
      this.contact = `${MainRoutes.Effectifs}/${team?.website_slug}`;
    } else if (agence) {
      this.contact = `/${agence?.slug}`;
    }

    if (this.contact) {
      this.contact += '/contact';
    }
  }
}
