<div class="agences-container">
	@if (loading()) {
		@for (i of [0, 1, 2, 3, 4, 5, 6, 7]; track i) {
			<app-agence-card-loader />
		}
	} @else {
		@for (agence of agences() | paginate: { itemsPerPage: itemsPerPage(), currentPage, totalItems: totalItems() }; track agence.id) {
			<app-agence-card [agence]="agence" />
		}
	}
</div>

@if (!loading()) {
	@if (navByLink()) {
		<app-pagination-link prompt="Agencies" [itemsPerPage]="itemsPerPage()" />
	} @else {
		<app-pagination (pageChange)="changePage($event)" prompt="Agencies" [itemsPerPage]="itemsPerPage()" />
	}
}
