import { Directive, HostListener, output } from '@angular/core';

@Directive({
  selector: '[appKeydown]',
  standalone: false
})
export class KeydownDirective {
  readonly appKeydown = output<any>();

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: any): void {
    if (['Enter', 'ArrowDown', 'ArrowUp'].includes(event.code)) {
      event.preventDefault();
      event.stopPropagation();

      this.appKeydown.emit(event);
    }
  }
}
