<div class="biens-vendus" #container>
	<div class="breadcrumb w-full">
		<div class="breadcrumb-content">
			<div class="breadcrumb-back" (click)="back()">
				<div class="breadcrumb-back-arrow"><span class="icon-arrow-left"></span></div>
				<p class="display-text-16px">{{ 'previous' | transloco }}</p>
			</div>
			<div class="breadcrumb-links">
				@if (agence?.id) {
					<a [routerLink]="['/' + agence?.slug]">{{ agence?.enseigne }}</a>
				} @else {
					<a routerLink="/">{{ 'homepage' | transloco }}</a>
				}
				<span class="icon-chevron-right"></span>
				<span>{{ 'properties sold' | transloco }}</span>
			</div>
		</div>
	</div>

	<div class="main-containers">
		<header>
			<h2 class="display-h2">
				{{ 'properties sold' | transloco }}
			</h2>
			<app-form-select
				id="item_type"
				[formControl]="sort"
				[items]="sorts"
				itemKey="value"
				itemLabel="label"
				label="sort recent"
				icon="icon-clock"
			/>
		</header>

		<div class="annonces">

			@if (isLoading) {

				<app-dot-loader />

			} @else if (annonces && annonces.length) {

				@for (annonce of annonces | paginate:{ currentPage, itemsPerPage, totalItems }; track annonce.id) {
					<app-annonce-card [annonce]="annonce" [agence]="agence" />
				}
				<div class="flex flex-col items-center">
					<app-pagination-link prompt="properties sold" [itemsPerPage]="itemsPerPage" />
				</div>
			} @else {
				<div>
					<h2 class="text-center">{{ 'no property sold in agency' | transloco }}</h2>
				</div>
			}
		</div>

	</div>

	<!-- TOP CITIES -->
	<div class="main-containers background">
		<div class="w-full flex justify-start mb-41px">
			<app-top-city />
		</div>
	</div>
</div>