import { ChangeDetectionStrategy, Component, OnChanges, input } from '@angular/core';

import { Agence, AgenceGroupe, Negociateur } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-estimation-card',
  templateUrl: './estimation-card.component.html',
  styleUrls: ['./estimation-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EstimationCardComponent implements OnChanges {
  readonly type = input.required<'agence' | 'annonces' | 'contact' | 'portes-ouvertes'>();
  readonly agenceSlug = input<string>();
  readonly agence = input<Agence>();
  readonly groupeAgence = input<AgenceGroupe>();
  readonly team = input<Negociateur>();

  public MainRoutes = MainRoutes;
  public queryParams: any = {};

  ngOnChanges(): void {
    const groupe = this.groupeAgence();
    const agence = this.agence();

    if (groupe) {
      this.queryParams = { groupe_id: groupe.id };
    } else if (agence) {
      this.queryParams = { agence_id: agence.id };
    } else {
      this.queryParams = {};
    }
  }
}
