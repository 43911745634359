import { Component, ElementRef, forwardRef, ChangeDetectionStrategy, input, viewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SliderComponent),
    multi: true
  }],
  standalone: false
})
export class SliderComponent implements ControlValueAccessor {
  readonly sliderThumb = viewChild.required<ElementRef<HTMLLIElement>>('sliderThumb');
  readonly sliderRange = viewChild.required<ElementRef<HTMLLIElement>>('sliderRange');

  readonly min = input<number, string>(0, { transform: (value: string) => +value });
  readonly max = input<number, string>(100, { transform: (value: string) => +value });
  readonly step = input<number, string>(5, { transform: (value: string) => +value });
  readonly margin = input<number, string>(1, { transform: (value: string) => +value });
  readonly labelFormatFn = input<(value: number) => string>();
  readonly showLabel = input(true);

  private onChange: any = () => { };
  private onTouched: any = () => { };
  private _value = 0;

  get sliderThumbNative(): HTMLLIElement {
    return this.sliderThumb().nativeElement;
  }

  get sliderRangeNative(): HTMLLIElement {
    return this.sliderRange().nativeElement;
  }

  get value(): number {
    return this._value;
  }

  setNearestValue(val: number): void {
    let newValue = val;

    if (val > this.value) {
      newValue = val >= this.max() ? this.max() : Math.ceil(val / this.step()) * this.step();
    } else {
      newValue = val <= this.min() ? this.min() : Math.floor(val / this.step()) * this.step();
    }

    this._value = newValue;
    this.onValueChange();
  }

  setControlsValue(val: number) {
    this._value = val || 0;
    this.onValueChange(false);
  }

  onValueChange(emit = true): void {
    const percent = `${Math.floor(((this.value - this.min()) / (this.max() - this.min())) * 100)}%`;
    this.sliderThumbNative.style.left = percent;
    this.sliderRangeNative.style.left = percent;

    if (emit) {
      this.onChange(this.value);
      this.onTouched();
    }
  }

  onRangeClick(event: MouseEvent, track?: HTMLElement): void {
    const { clientX } = event;
    const target = track || event.target;
    const { left, width } = (target as HTMLElement).getBoundingClientRect();
    const pre = Math.floor(((clientX - left) / width) * 100);
    const val = ((pre / 100) * (this.max() - this.min())) + this.min();

    this.setNearestValue(val);
  }

  labelFormat(val: number): string {
    const labelFormatFn = this.labelFormatFn();
    return (typeof labelFormatFn === 'function') ? labelFormatFn(val) : val.toString();
  }

  trackByFn(i: number) {
    return i;
  }

  writeValue(obj: any): void {
    this.setControlsValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(_isDisabled: boolean): void {
  }
}
