import { Negociateur } from '@/models';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-team-mini-card',
  templateUrl: './team-mini-card.component.html',
  styleUrl: './team-mini-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TeamMiniCardComponent {
  readonly negociateur = input.required<Negociateur>();
}
