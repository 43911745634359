@if (mode) {
	<div class="toggle {{show()}}" (click)="switchMode()">
		<p class="flex items-center">
			@if (mode === 'list') {
				<span class="icon-map"></span> {{ 'map_view' | transloco }}
			} @else {
				<span class="icon-grid"></span> {{ 'list_view' | transloco }}
			}
		</p>
	</div>
}
