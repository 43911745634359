import {
  AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, OnChanges, SimpleChanges, input, viewChild
} from '@angular/core';

export type ButtonAspect =
  'red' | 'black' | 'dark-border' | 'red-border' | 'transparent' | 'blue' | 'blue-border' |
  'arrow-red-dark' | 'arrow-red' | 'arrow-blue' | 'arrow-white';

export type ButtonSize = 'fit' | 'large';
export type ButtonType = 'button' | 'submit';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ButtonComponent implements AfterViewInit, OnChanges {
  readonly ref = viewChild.required<ElementRef>('ref');

  readonly aspect = input<ButtonAspect>('arrow-red-dark');
  readonly icon = input<string>('icon-arrow-right');
  readonly extraClasses = input('');
  readonly disabled = input(false);
  readonly text = input('');
  readonly link = input<string | string[]>('');
  readonly size = input<ButtonSize>('fit');
  readonly wrap = input(false);
  readonly translate = input(true);
  readonly target = input('_blank');
  readonly fragment = input<string>();
  readonly href = input<string>();
  readonly queryParams = input<Record<string, any>>();
  readonly id = input<string>();
  readonly type = input<ButtonType>();

  @HostBinding('style.width') width!: string;

  ngOnChanges(changes: SimpleChanges): void {
    const { size } = changes;

    if (size && (size.currentValue !== size.previousValue)) {
      this.width = (this.size() === 'large') ? '100%' : 'fit-content';
    }
  }

  ngAfterViewInit(): void {
    const ref = this.ref();

    if (ref) {
      const type = this.type();
      const id = this.id();

      if (id) {
        ref.nativeElement.id = id;
      }
      if (type) {
        ref.nativeElement.type = type;
      }
    }
  }
}
