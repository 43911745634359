<pagination-template class="pagination" #p="paginationApi" (pageChange)="onPageChange($event)">
	@let totalItems = p.getTotalItems();
	@let currentPage = p.getCurrent();
	@let countPerPage = itemsPerPage();

	@if ((totalItems / countPerPage) > 1) {
		<div class="pagination-inner">
			@if (!p.isFirstPage()) {
				<div class="nav" (click)="p.previous()">
					<span class="icon-arrow-left fs-20px"></span>
				</div>
			}

			@for (page of p.pages; track page.label) {
				<div class="pagination-buttons" [class.current]="currentPage === page.value">
					@if (currentPage === page.value) {
						<div class="cur">
							<span>{{ page.label }}</span>
						</div>
					} @else if (page.label === '...') {
						<div class="dots"	(click)="p.setCurrent(page.value)">
							<span>{{ page.label }}</span>
						</div>
					} @else {
						<div class="unactive" (click)="p.setCurrent(page.value)">
							<span>{{ page.label }}</span>
						</div>
					}
				</div>
			}

			@if (!p.isLastPage()) {
				<div class="nav" (click)="p.next()">
					<span class="icon-arrow-right fs-20px"></span>
				</div>
			}
		</div>

		<div class="pagination-resume">
			{{ prompt() | transloco }} {{ 'of' | transloco }}
			<span class="value">
				{{ totalItems === 0 ? 0 : currentPage === 1 ? 1 : countPerPage * currentPage - countPerPage + 1 }}
			</span>
			{{ 'to' | transloco }}
			<span class="value">
				{{ p.isLastPage() ? totalItems : countPerPage * currentPage }}
			</span>
			{{ 'on' | transloco }}
			<span class="value">{{ totalItems }}</span>
		</div>
	}
</pagination-template>
