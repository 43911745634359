import { Directive, ElementRef, AfterViewInit, Renderer2, output } from '@angular/core';

@Directive({
  selector: '[appFocusBlur]',
  standalone: false
})
export class FocusBlurDirective implements AfterViewInit {
  readonly focusEvent = output<boolean>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    const inputElement = this.el.nativeElement.querySelector('input');

    if (inputElement) {
      this.renderer.listen(inputElement, 'focus', () => {
        this.focusEvent.emit(true);
      });

      this.renderer.listen(inputElement, 'blur', () => {
        this.focusEvent.emit(false);
      });
    }
  }
}
