export type ContactType = {
  contact_type: string;
  source: string;
  value?: string;
  prestige: boolean;
};

export type LeadContact = {
  lastname?: string;
  firstname: string;
  email: string;
  phone: string;
  contact_type: CONTACT_TYPE
  address?: string;
  zip_code?: string;
  city?: string;
  department?: string;
  job_type_id?: number;
  message: string;
  call_back: boolean;
  agence_id: number;
  effectif_id?: number;
  origin_contact?: string;
  contact_agreement: boolean;
  partner_agreement: boolean;
  api_source?: API_SOURCE
};

export type EstimationContact = {
  agence_id: number;
  address: string;
  city_name: string;
  city_zip: string;
};

export enum CONTACT_TYPE {
  SELL_PROPERTY = 'CONTACT_TYPE.SELL_PROPERTY',
  RENT_PROPERTY = 'CONTACT_TYPE.RENT_PROPERTY',
  BUY_PROPERTY = 'CONTACT_TYPE.BUY_PROPERTY',
  OPEN_AGENCY = 'CONTACT_TYPE.OPEN_AGENCY',
  RENTAL_MANAGEMENT = 'CONTACT_TYPE.RENTAL_MANAGEMENT',
  COMMERCIAL_SPACE = 'CONTACT_TYPE.COMMERCIAL_SPACE',
  SYNDIC = 'CONTACT_TYPE.SYNDIC',
  APPLICATION = 'CONTACT_TYPE.APPLICATION',
  OTHER = 'CONTACT_TYPE.OTHER',
  PARTNERSHIP = 'CONTACT_TYPE.PARTNERSHIP'
}

export enum API_SOURCE {
  SITE_NATIONAL = 'SOURCE.SITE_NATIONAL',
  SITE_AGENCE = 'SOURCE.SITE_AGENCE',
  SITE_GROUPE = 'SOURCE.SITE_GROUPE',
  SITE_NEGO = 'SOURCE.SITE_NEGO'
}
