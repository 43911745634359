import { Component, input } from '@angular/core';

import { SeoFaqEntry } from '@/models';

@Component({
  selector: 'app-faq-container',
  templateUrl: './faq-container.component.html',
  styleUrls: ['./faq-container.component.scss'],
  standalone: false
})
export class FaqContainerComponent {
  readonly faqs = input<SeoFaqEntry[]>([]);
  readonly tag = input('');
}
