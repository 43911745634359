import { Agence, ContactType } from '@/models';
import { SelectOption } from '@/models';

export const CONTACT_ORIGINS: SelectOption[] = [
  { key: 'ORIGINE.BOUCHE', label: 'word_of_mouth' },
  { key: 'ORIGINE.PUB', label: 'publicity' },
  { key: 'ORIGINE.INTERNET', label: 'internet' },
  { key: 'ORIGINE.LOCAL', label: 'local_agency' },
  { key: 'ORIGINE.CLIENT', label: 'by_a_customer' },
  { key: 'ORIGINE.AUTRE', label: 'other' }
] as const;

export const TAGS: ContactType[] = [
  { contact_type: 'CONTACT_TYPE.SELL_PROPERTY', source: 'eraAndAgence', prestige: true },
  { contact_type: 'CONTACT_TYPE.RENT_PROPERTY', source: 'eraAndAgence', prestige: true },
  { contact_type: 'CONTACT_TYPE.BUY_PROPERTY', source: 'eraAndAgence', prestige: true },
  { contact_type: 'CONTACT_TYPE.OPEN_AGENCY', source: 'era', prestige: false },
  { contact_type: 'CONTACT_TYPE.APPLICATION', source: 'eraAndAgence', prestige: false },
  { contact_type: 'CONTACT_TYPE.PARTNERSHIP', source: 'agence', prestige: false },
  { contact_type: 'CONTACT_TYPE.RENTAL_MANAGEMENT', value: 'gestion_locative', source: 'eraAndAgence', prestige: false },
  { contact_type: 'CONTACT_TYPE.COMMERCIAL_SPACE', value: 'entreprise_commerce', source: 'eraAndAgence', prestige: false },
  { contact_type: 'CONTACT_TYPE.SYNDIC', value: 'syndic_copropriete', source: 'eraAndAgence', prestige: false },
  { contact_type: 'CONTACT_TYPE.OTHER', source: 'agence', prestige: false }
] as const;

export const DEFAULT_AGENCE: Agence = {
  id: 1,
  enseigne: 'ERA FRANCE IMMOBILIER (Siège social)',
  adresse: '18 rue mansart',
  codepostal: '78000',
  ville: 'VERSAILLES',
  telephone: '0139246900'
} as Agence;
