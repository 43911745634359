 @if (agence) {
	<div class="negociators-page agence teams">
		<div class="agence-header w-full h-140px flex flex-col fixed bg-white top-0 z-50 header-shadow md:h-80px"></div>
		<div class="flex flex-col items-center justify-center w-full">

			<div class="breadcrumb w-full">
				<div class="breadcrumb-content">
					<div class="breadcrumb-back" (click)="backButton()">
						<div class="breadcrumb-back-arrow"><span class="icon-arrow-left"></span></div>
						<p class="display-text-16px">{{ 'previous' | transloco }}</p>
					</div>
					<div class="breadcrumb-links">
						@if (agence.id) {
							@if (eraAgencePrincipaleId) {
								<a [routerLink]="[MainRoutes.Groupes + '/' + agence.slug + '-' + agence.id]">
									{{ agence.nom }}
								</a>
							} @else {
								<a [routerLink]="['/' + agence.slug]">
									{{ agence.enseigne }}
								</a>
							}
						}
						<span class="icon-chevron-right"></span>
						<span>{{ 'team' | transloco }}</span>
					</div>
				</div>
			</div>

			<!-- TEAM -->
			@if (agence.effectifs && (agence.effectifs.length > 0) && (agence.statut !== 2)) {
				<div class="main-containers">
					<div class="w-full flex items-center justify-between mb-41px">
						<h2 class="display-h2">
							{{ agence.effectifs.length }} <span class="display-text-24px">{{ 'contact person' | transloco }}s</span>
						</h2>
					</div>
					<div class="flex w-full justify-center flex-start flex-wrap gap-4">
						@if (allTeam) {
							@for (team of agence.effectifs; track team.id) {
								<app-team-card [team]="team" [agence]="agence" />
							}
						} @else {
							@for (team of agence.effectifs.slice(0, 15); track team.id) {
								<app-team-card [team]="team" [agence]="agence" />
							}
							@if (agence.effectifs.length > 15) {
								<app-button (click)="allTeam = true" aspect="arrow-white" text="see all team" />
							}
						}
					</div>
				</div>
			}
		</div>

		<!-- TOP CITIES -->
		<div class="main-containers background">
			<div class="w-full flex justify-start mb-41px">
				<app-top-city />
			</div>
		</div>
	</div>
 }
