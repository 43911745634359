<div class="middle">
  <input
    type="range"
    [step]="step()"
    [min]="min()"
    [max]="max()"
    [ngModel]="value"
    (ngModelChange)="setNearestValue($event)"
  >
  <div class="slider">
    <div #track class="track" (click)="onRangeClick($event)"></div>
    <div #sliderRange class="range" (click)="onRangeClick($event, track)"></div>
    <div #sliderThumb class="thumb">
      <span #thumbLabel class="thumb-label">
        @if (showLabel()) {
          <span class="label-data">{{ labelFormat(value) }}</span>
        }
      </span>
    </div>
  </div>
</div>
