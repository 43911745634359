import { Meta, Title } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Metadata } from '@/models';
import { ConfigService } from './config.service';

const DEFAULT_TITLE = 'ERA France';
const DEFAULT_IMAGE = 'https://www.erafrance.com/assets/imgs/era-logo-national.png';
const DEFAULT_ROBOTS = 'follow,index';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  private fbDevAppId: string;

  constructor(
    private meta: Meta,
    private title: Title,
    private translate: TranslocoService,
    @Inject(DOCUMENT) private document: any,
    configService: ConfigService
  ) {
    this.fbDevAppId = configService.config.facebook_developper_app_id;
  }

  setMetadata(data: Metadata, translate = true): void {
    const params = data.params ? { ...data.params } : {};

    this.meta.updateTag({ property: 'fb:app_id', content: this.fbDevAppId });
    this.meta.updateTag({ property: 'og:type', content: 'website' });

    if (data.title) {
      this.title.setTitle(this.localize(translate, data.title, params));
    } else {
      this.title.setTitle(DEFAULT_TITLE);
    }

    if (data.description) {
      const content = this.localize(translate, data.description, params);
      this.meta.updateTag({ name: 'description', content });
    } else {
      this.meta.removeTag('name=\'description\'');
    }

    const url = data.ogUrl ?? data.url;
    if (url) {
      const content = this.localize(translate, url, params);
      this.meta.updateTag({ property: 'og:url', content });
      this.createLinkForCanonicalURL(!!data.singleArticle, content);
    }

    if (data.ogTitle) {
      const content = this.localize(translate, data.ogTitle, params);
      this.meta.updateTag({ property: 'og:title', content });
    } else {
      this.meta.removeTag('property=\'og:title\'');
    }

    if (data.ogDescription) {
      const content = this.localize(translate, data.ogDescription, params);
      this.meta.updateTag({ property: 'og:description', content });
    } else {
      this.meta.removeTag('property=\'og:description\'');
    }

    this.meta.updateTag({ property: 'og:image', content: data.ogImage || DEFAULT_IMAGE });
    this.meta.updateTag({ name: 'robots', content: data.robots || DEFAULT_ROBOTS });
  }

  /**
   * Remove canonical links from current HTML document
   */
  private removeAllCanonicalLinks() {
    const canonicalElement = this.document.querySelectorAll('[rel="canonical"]');
    for (const el of canonicalElement) {
      el.parentNode.removeChild(el);
    }
  }

  /**
   * Create a canonical link element into the HTML document
   * @param singleArticle
   * @param url The app url
   */
  private createLinkForCanonicalURL(singleArticle: boolean, url: string): void {
    this.removeAllCanonicalLinks();

    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);

    if (singleArticle) {
      link.setAttribute('href', url);
    } else {
      // SSR does not know the protocol of the server, force HTTPS
      link.setAttribute('href', this.document.URL.replace('http://', 'https://'));
    }
  }

  private localize(translate: boolean, text: string, params: Record<string, any>): string {
    return (translate ? this.translate.translate(text, params) : text) || '';
  }
}
