<div class="iti iti--allow-dropdown" [ngClass]="separateDialCodeClass">
	<div class="iti__flag-container" dropdown [ngClass]="{ 'disabled': disabled }">

		<div class="iti__selected-flag dropdown-toggle" (click)="toggleDropdown($event)">
			<div class="iti__flag" [ngClass]="selectedCountry?.flagClass || ''"></div>
      @if (separateDialCode()) {
        <div class="selected-dial-code">+{{selectedCountry?.dialCode}}</div>
      }
			<div class="iti__arrow"></div>
		</div>

    @if (openDropdown) {
      <div class="dropdown-menu" (clickOutside)="toggleDropdown()">
        @if (searchCountryFlag() && searchCountryField()) {
          <div class="search-container">
            <input id="country-search-box"
              [(ngModel)]="countrySearchText"
              (keyup)="searchCountry()"
              (click)="$event.stopPropagation()"
              [placeholder]="searchCountryPlaceholder()"
              autofocus>
          </div>
        }

        <ul class="iti__country-list" #countryList>

          @for (country of preferredCountriesInDropDown; track country.dialCode) {
            <li class="iti__country iti__preferred" (click)="onCountrySelect(country, focusable)" [id]="country.htmlId + '-preferred'">
              <div class="iti__flag-box">
                <div class="iti__flag" [ngClass]="country.flagClass"></div>
              </div>
              <span class="iti__country-name">{{country.name}}</span>
              <span class="iti__dial-code">+{{country.dialCode}}</span>
            </li>
          }

          @if (preferredCountriesInDropDown.length) {
            <li class="iti__divider"></li>
          }

          @for (country of allCountries; track country.dialCode) {
            <li class="iti__country iti__standard" (click)="onCountrySelect(country, focusable)" [id]="country.htmlId">
              <div class="iti__flag-box">
                <div class="iti__flag" [ngClass]="country.flagClass"></div>
              </div>
              <span class="iti__country-name">{{country.name}}</span>
              <span class="iti__dial-code">+{{country.dialCode}}</span>
            </li>
          }
        </ul>

      </div>
    }
	</div>

	<input
    type="tel"
		[id]="inputId()"
		autocomplete="off"
		[ngClass]="cssClass()"
		(blur)="onTouched()"
		(keypress)="onInputKeyPress($event)"
		[(ngModel)]="phoneNumber"
		(ngModelChange)="onPhoneNumberChange()"
		[disabled]="disabled"
		[placeholder]="resolvePlaceholder()"
		[attr.maxLength]="maxLength()"
		[attr.validation]="phoneValidation()"
		#focusable
  >
</div>
