import {
  AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation,
  input,
  contentChildren
} from '@angular/core';

import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TabsComponent implements AfterContentInit {
  readonly tabs = contentChildren(TabComponent);

  readonly contentClass = input('');

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this.selectTab(this.tabs().at(0)!);
  }

  selectTab(tab: TabComponent) {
    if (tab) {
      this.tabs().forEach((tab) => tab.setActive(false));
      tab.setActive(true);
      this.cd.markForCheck();
    }
  }
}
