import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-opinion-title',
  templateUrl: './opinion-title.component.html',
  styleUrl: './opinion-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class OpinionTitleComponent {
  readonly avisLink = input<string>(MainRoutes.Avis);
  readonly total = input(0);
  readonly rate = input(0);
}
