@let nego = team();
@let a = agence();

<div class="team-member-card transition-all" [ngClass]="{ 'column': column() }">
  <div class="image-container">
    <img [src]="nego.photo ? nego.photo : '/assets/imgs/era_bordure.svg'" alt="" />
  </div>

  <div class="member-details">
    <p class="display-text-18px name">
      <strong>{{ nego.prenom }} {{ nego.nom }}</strong>
    </p>

    <p class="display-text-12px uppercase">
      {{ nego.fonction }}
    </p>

    <div class="tags">
      @if (nego.club_excellence === 1) {
        <p class="display-text-14px">Club Excellence</p>
      }

      @if (nego.club_excellence_premium === 1) {
        <p class="display-text-14px">Club Excellence Premium</p>
      }

      @if (langues) {
        <p class="display-text-14px">{{ langues }}</p>
      }
    </div>

    @if (nego.agent_co === 1) {
      <p class="agent-co">
        {{ 'commercial_agent' | transloco | titlecase }}
        {{ 'N° RSAC' + nego.rsac + ' - EI' }}
      </p>
    }

    @if ((nego.enseigne || a?.enseigne || nego.agence?.enseigne) && showAddress) {
      <div class="team-card-address">
        @if (nego.enseigne) {
          <p class="display-text-14px">
            {{ nego.enseigne }}

            @if (nego.arrondissement || nego.ville) {
              <span>{{ nego.ville }} {{ nego.arrondissement ? '(' + nego.arrondissement + ')' : '' }}</span>
            }
          </p>
        }

        @if (a) {
          <p class="display-text-14px">
            {{ a.enseigne }}

            @if (a.arrondissement || a.ville) {
              <span>{{ a.ville }} {{ a.arrondissement ? '(' + a.arrondissement + ')' : '' }}</span>
            }
          </p>
        }

        @if (nego.agence && nego.agence.enseigne) {
          <p class="display-text-14px">
            {{ nego.agence.enseigne }}

            @if (nego.agence.arrondissement || nego.agence.ville) {
              <span>{{ nego.agence.ville }} {{ nego.agence.arrondissement ? '(' + nego.agence.arrondissement + ')' : '' }}</span>
            }
          </p>
        }
      </div>
    }
  </div>

  <div [ngClass]="isList() ? 'list' : 'buttons'">
    <app-contact-card [team]="nego" [agence]="a ?? nego.agence" [showSite]="true" />
  </div>

</div>
