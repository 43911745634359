@let hours = horaires();

@if (now()) {
  <div class="hour display-text-{{height()}}px gap-1">
    <span class="icon-clock fs-20px mr-10px grey-icon"></span>
    @if (hours?.length) {
      @let curDay = getScheduleCurrentDay();
      <p>{{( getScheduleDayName(curDay)) }}</p>
      <p class="whitespace-nowrap">{{ getScheduleDayToRepr(curDay) }}</p>
      <p class="dayMeet">{{ getScheduleDayToReprMeetOnSite(curDay) }}</p>
    } @else {
      {{ currentDayName() }} : {{ 'not_specified' | transloco }}
    }
  </div>
} @else {
  <div class="flex flex-col">
    @for (day of dayMap | keyvalue; track day; let index = $index) {
      <div class="grid grid-cols-3 gap-4 items-center mb-2">
        <p>{{ getScheduleDayName(getDayByIndex(index)) }}</p>
        <p class="whitespace-nowrap">{{ getScheduleDayToRepr(hours?.[index]) }}</p>
        <p class="dayMeet">{{ getScheduleDayToReprMeetOnSite(hours?.[index]) }}</p>
      </div>
    }
  </div>
}
