import { Component, input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { DateTime } from 'luxon';

import { AgenceHoraire, AgenceHoraireDay, AgenceHoraireStatus } from '@/models';

@Component({
  selector: 'app-agence-horaires',
  templateUrl: './agence-horaires.component.html',
  styleUrl: './agence-horaires.component.scss',
  standalone: false
})
export class AgenceHorairesComponent {
  readonly horaires = input<AgenceHoraire[]>();
  readonly height = input(14);
  readonly now = input(true);

  public dayMap: Record<AgenceHoraireDay, string> = {
    'lu': 'monday',
    'ma': 'tuesday',
    'me': 'wednesday',
    'je': 'thursday',
    've': 'friday',
    'sa': 'saturday',
    'di': 'sunday'
  };

  constructor(public translate: TranslocoService) { }

  mapDayToNames(shortName: AgenceHoraireDay): string {
    return this.dayMap[shortName];
  }

  getDayByIndex(index: number): AgenceHoraire | undefined {
    // Monday should be 0
    const defaultDayIndex = new Date().getDay();
    const dayIndex = defaultDayIndex === 0 ? 6 : defaultDayIndex - 1;

    return this.horaires()?.[index ?? dayIndex];
  }

  getScheduleCurrentDay(): AgenceHoraire | undefined {
    // Monday should be 0
    const defaultDayIndex = new Date().getDay();
    const dayIndex = defaultDayIndex === 0 ? 6 : defaultDayIndex - 1;

    return this.horaires()?.[dayIndex];
  }

  getScheduleDayToRepr(daySchedule?: AgenceHoraire): string {
    if (!daySchedule) {
      return '';
    }

    if (daySchedule.statut !== AgenceHoraireStatus.Open) {
      return this.translate.translate('closed');
    }

    let dayScheduleRepr: string = daySchedule.horaires[0].substring(0, 11);
    if (daySchedule.horaires.length > 1) {
      dayScheduleRepr += ' - ';
      dayScheduleRepr += daySchedule.horaires[1].substring(0, 11);
    }

    return dayScheduleRepr;
  }

  getScheduleDayToReprMeetOnSite(daySchedule?: AgenceHoraire): string {
    if (!daySchedule) {
      return '';
    }

    // Check sur rdv
    let meetOnly = '';

    if (daySchedule?.horaires[0] && daySchedule?.horaires[0].includes('|1')) {
      meetOnly = this.translate.translate('appointment_morning');
    } else if (daySchedule?.horaires[1] && daySchedule?.horaires[1].includes('|1')) {
      meetOnly = this.translate.translate('appointment_afternoon');
    }

    return meetOnly;
  }

  getScheduleDayName(daySchedule?: AgenceHoraire): string {
    return daySchedule ? this.translate.translate(this.mapDayToNames(daySchedule.jour)) : '';
  }

  currentDayName(): string {
    try {
      const dayName = DateTime.now().setLocale(this.translate.getActiveLang()).toLocaleString({ weekday: 'long' });
      return dayName.charAt(0).toUpperCase() + dayName.slice(1);
    } catch (_e) {
      return '';
    }
  }
}
