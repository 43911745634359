<div class="statement-page" #content>
	<div class="breadcrumb">
		<div class="breadcrumb-content">
			<div (click)="backButton()" class="header-close">
				<span class="icon-arrow-left"></span>
				<p class="display-text-16px">{{ 'previous' | transloco }}</p>
			</div>
			<div class="breadcrumb-links">
				<span (click)="backButton()">{{ 'homepage' | transloco }}</span>
				<span class="icon-chevron-right"></span>
				@if (agence?.id) {
					<a [routerLink]="[(isGroupe ? MainRoutes.Groupes : '') + '/' + agence?.slug]">{{ name }}</a>
					<span class="icon-chevron-right"></span>
				}
				<span>{{ 'AGENCE.TESTIMONY' | transloco }}</span>
			</div>
		</div>
	</div>

	@if (isLoading) {

		<app-dot-loader />

	} @else if (avis && avis.length) {

		<div class="main-containers">
			<div class="w-full flex items-center justify-between mb-41px">
				<h1 class="display-h2">
					{{ 'AGENCE.TESTIMONY' | transloco }}
					<span>{{ name }}</span> ({{ avis.length }})
				</h1>
			</div>

			<div class="statement-flex">

				<div class="statement-status">
					<div class="statement-status-item">
						<p class="display-text-18px">{{ 'AGENCE.NOTE' | transloco }}</p>
						<app-rating-stars [rate]="moyenne" />
						<p class="display-text-18px">
							{{ moyenne | number:'':'fr-FR' }} <span>{{ 'on' | transloco }}</span> 5
						</p>
					</div>
					<app-rating-bars [notes]="notes" [total]="avis.length" />
				</div>

				<div class="statement-container">
					@for (a of avis | paginate:{ currentPage, itemsPerPage }; track a) {
						<app-statement-card [agence]="agence" [avis]="a" />
					}
					<div class="opinion">
						<p class="display-text-10px grey-icon">{{ 'opinion_system' | transloco }}</p>
					</div>
					<div class="pagination">
						<app-pagination (pageChange)="onPageChange($event)" prompt="testimony" [itemsPerPage]="itemsPerPage" />
					</div>
				</div>

			</div>

		</div>

	} @else {

		<app-nothing-found text="AGENCE.TESTIMONY" content="AVIS.EMPTY_MSG" />

	}

</div>
