import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NumberFormatDirective } from './number-format/number-format.directive';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { FocusBlurDirective } from './focus-blur/focus-blur.directive';
import { KeydownDirective } from './keydown/keydown.directive';

const DIRECTIVES = [
  FocusBlurDirective,
  NumberFormatDirective,
  ClickOutsideDirective,
  KeydownDirective
];

@NgModule({
  declarations: [
    ...DIRECTIVES
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class DirectivesModule { }
