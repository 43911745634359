@let items = faqs();
@let metier = tag();

@if (items.length) {
	<h2 class="display-h3 mb-40px">
		{{ (metier === 'locaux commerciaux' ? 'how to sell your professional property well' : 'frequently_asked_questions') | transloco }}
	</h2>
	<div class="flex flew-row flex-wrap faq">
		@for (faq of items; track faq) {
			<app-faq
				[ngClass]="[metier === 'locaux commerciaux' ? 'full' : 'half', 'br-10px']"
				[question]="faq.question"
				[reponse]="faq.reponse"
			/>
		}
	</div>
}
