@let isHeader = header() === true;
@let gr = agenceGroup();
@let ag = agence();

@if (ag?.social_network_url || gr?.social_network_url || isHeader) {

	<div [ngClass]="{ 'header': isHeader }"	class="network">
		@if (gr?.social_network_url?.facebook) {
			<a href="https://{{ gr?.social_network_url?.facebook }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/facebook.svg" alt="facebook" /></span>
			</a>
		} @else if (ag?.social_network_url?.facebook) {
			<a href="https://{{ ag?.social_network_url?.facebook }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/facebook.svg" alt="facebook" /></span>
			</a>
		} @else {
			<a href="https://www.facebook.com/erafrance" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/facebook.svg" alt="facebook" /></span>
			</a>
		}

		@if (gr?.social_network_url?.instagram) {
			<a href="https://{{ gr?.social_network_url?.instagram }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/instagram.svg" alt="instagram" /></span>
			</a>
		} @else if (ag?.social_network_url?.instagram) {
			<a href="https://{{ ag?.social_network_url?.instagram }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/instagram.svg" alt="instagram" /></span>
			</a>
		} @else {
			<a href="https://www.instagram.com/era_immobilier/" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/instagram.svg" alt="instagram" /></span>
			</a>
		}

		@if (gr?.social_network_url?.linkedin) {
			<a href="https://{{ gr?.social_network_url?.linkedin }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/linkedin.svg" alt="linkedin" /></span>
			</a>
		} @else if (ag?.social_network_url?.linkedin) {
			<a href="https://{{ ag?.social_network_url?.linkedin }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/linkedin.svg" alt="linkedin" /></span>
			</a>
		} @else {
			<a href="https://www.linkedin.com/company/era-immobilier?originalSubdomain=fr" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/linkedin.svg" alt="linkedin" /></span>
			</a>
		}

		@if (gr?.social_network_url?.twitter) {
			<a href="https://{{ gr?.social_network_url?.twitter }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/twitter.svg" alt="twitter" /></span>
			</a>
		} @else if (ag?.social_network_url?.twitter) {
			<a href="https://{{ ag?.social_network_url?.twitter }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/twitter.svg" alt="twitter" /></span>
			</a>
		}

		@if (gr?.social_network_url?.viadeo) {
			<a href="https://{{ gr?.social_network_url?.viadeo }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/viadeo.svg" alt="viadeo" /></span>
			</a>
		} @else if (ag?.social_network_url?.viadeo) {
			<a href="https://{{ ag?.social_network_url?.viadeo }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/viadeo.svg" alt="viadeo" /></span>
			</a>
		}

		@if (gr?.social_network_url?.youtube) {
			<a href="https://{{ gr?.social_network_url?.youtube }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/youtube.svg" alt="youtube" /></span>
			</a>
		} @else if (ag?.social_network_url?.youtube) {
			<a href="https://{{ ag?.social_network_url?.youtube }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/youtube.svg" alt="youtube" /></span>
			</a>
		} @else {
			<a href="https://www.youtube.com/user/ERAIMMOBILIERFRANCE" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/youtube.svg" alt="youtube" /></span>
			</a>
		}
	</div>

} @else {

	<div class="network flex-row" [ngClass]="footer() || profile() ? 'footer' : 'general'">
		<a href="https://www.facebook.com/erafrance" target="_blank" class="network-item" rel="nofollow">
			<span class="item"><img src="/assets/imgs/facebook.svg" alt="facebook" /></span>
		</a>
		<a href="https://www.instagram.com/era_immobilier/" target="_blank" class="network-item" rel="nofollow">
			<span class="item"><img src="/assets/imgs/instagram.svg" alt="instagram" /></span>
		</a>
		<a href="https://www.linkedin.com/company/era-immobilier?originalSubdomain=fr" target="_blank" class="network-item" rel="nofollow">
			<span class="item"><img src="/assets/imgs/linkedin.svg" alt="linkedin" /></span>
		</a>
		<a href="https://www.youtube.com/user/ERAIMMOBILIERFRANCE" target="_blank" class="network-item" rel="nofollow">
			<span class="item"><img src="/assets/imgs/youtube.svg" alt="youtube" /></span>
		</a>
	</div>

}
