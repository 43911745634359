import { Component, input } from '@angular/core';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrl: './form-errors.component.scss',
  standalone: false
})
export class FormErrorsComponent {
  readonly field = input.required<{ dirty: boolean | null, errors: Record<string, any> | null } | null>();
}
