import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, Self, input, signal } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ToolsService } from '@/services/tools.service';

type InputType = 'text' | 'textarea' | 'password' | 'number' | 'email';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrl: './form-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FormInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  readonly id = input.required<string>();
  readonly type = input<InputType>('text');
  readonly label = input<string>();
  readonly icon = input<string>();
  readonly suffix = input<string>();
  readonly options = input<Record<string, string>>();
  readonly validationErrors = input(true);
  readonly eye = input(false);
  readonly passwordTips = input(false);

  public error = signal(false);
  public inputType?: InputType;
  public inputIcon?: string;
  public showEye = false;
  public required = false;
  public disabled = false;
  public focus = false;

  private subscriptions = new Subscription();

  private onChange: any = () => {};
  private onTouched: any = () => {};
  private _value: string = '';

  /**
    The constructor uses Dependency Injection (DI) to access the NgControl directive and manually assign
    this component as the control's value accessor. This approach avoids the circular dependency issue
    caused by registering the component via NG_VALUE_ACCESSOR provider. By doing so, we directly set the
    component as the value accessor, allowing it to handle form control interactions (read and write value).
   */
  constructor(
    @Self() protected ngControl: NgControl,
    private toolsService: ToolsService,
    private el: ElementRef
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit() {
    const options = this.options();
    this.inputType = this.type();
    this.inputIcon = this.icon();
    this.showEye = this.eye();

    if (this.inputType === 'password') {
      this.inputIcon = 'icon-lock';
      this.showEye = true;
    }

    this.required = this.toolsService.hasRequiredValidator(this.ngControl.control);

    this.subscriptions.add(
      this.ngControl.statusChanges!.subscribe((status) => {
        this.error.set(!!this.ngControl.dirty && (status === 'INVALID'));
      })
    );

    if (options) {
      Object.entries(options).forEach(([key, value]) => {
        this.el.nativeElement[key] = value;
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @Input()
  get value(): string {
    return this._value;
  }

  set value(val: string) {
    if (this._value !== val) {
      this._value = val;
      this.onChange(val);
      this.onTouched();
    }
  }

  // ControlValueAccessor methods

  writeValue(val: string): void {
    this._value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Other methods

  togglePasswordVisibility() {
    this.inputType = (this.inputType === 'password') ? 'text' : 'password';
  }
}
