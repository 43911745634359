import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, input, viewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrl: './modal-container.component.scss',
  standalone: false
})
export class ModalContainerComponent implements AfterViewInit, OnInit, OnDestroy {
  readonly container = viewChild.required<ElementRef>('container');

  readonly color = input('');

  private body: HTMLElement;

  constructor(@Inject(DOCUMENT) document: Document) {
    this.body = document.getElementsByTagName('body')[0];
  }

  ngOnInit(): void {
    this.body.classList.add('no-scroll');
  }

  ngOnDestroy(): void {
    this.body.classList.remove('no-scroll');
  }

  ngAfterViewInit(): void {
    this.container().nativeElement.style['background-color'] = this.color() || 'white';
  }
}
