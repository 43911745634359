import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { Recherche } from '@/models';

@Component({
  selector: 'app-search-bar-details',
  templateUrl: './search-bar-details.component.html',
  styleUrl: './search-bar-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SearchBarDetailsComponent {
  item = input<Recherche>();

  cities = computed(() => this.item()?.searchLocations?.map((l) => l.nom));
}
