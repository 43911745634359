import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, viewChild } from '@angular/core';
import { Subscription, switchMap } from 'rxjs';
import { Location } from '@angular/common';

import { AgencesService } from '@/services/agences.service';
import { Agence, AgenceLight } from '@/models';

@Component({
  selector: 'app-statement-all',
  templateUrl: './statement-all.component.html',
  styleUrls: ['./statement-all.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class StatementAllComponent implements OnInit, OnDestroy {
  readonly content = viewChild.required<ElementRef>('content');

  private subscriptions = new Subscription();

  public agence?: Agence;
  public agences?: AgenceLight[];
  public filteredAgences?: AgenceLight[];
  public rate = 0;
  public percent = 0;
  public sumTotal = '';
  public notes: number[] = [];
  public itemsPerPage = 8;
  public currentPage = 1;
  public totalItems = 0;
  public filterByNotes: any = { avis_moyenne: '' };

  constructor(
    public agencesService: AgencesService,
    private cd: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.agencesService.agence$.pipe(
        switchMap((currentAgence) => {
          if (currentAgence) {
            this.agence = currentAgence.data;
          }
          return this.agencesService.getAllAgenceAvis();
        })
      ).subscribe(({ agences, notes }) => {
        this.totalItems = agences.length;
        this.filteredAgences = agences;
        this.agences = agences;
        this.notes = notes;
        this.computeAverageStars();
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  backButton(): void {
    this.location.back();
  }

  sumAvis(): number {
    return this.notes.reduce((a, b) => a + b, 0);
  }

  filterIndex(nb: number): void {
    this.filteredAgences = this.agences?.filter((a) => a.avis_moyenne >= nb && a.avis_moyenne < nb + 1);
    this.totalItems = this.filteredAgences?.length || 0;

    // TODO-EL: voir pourquoi la somme de toutes les moyennes n'est pas la même que celle calculée par le back
  }

  onPageChange(page: number): void {
    this.currentPage = page;

    window.scrollTo({
      top: this.content().nativeElement.getBoundingClientRect().top,
      behavior: 'smooth'
    });
  }

  private computeAverageStars(): void {
    this.rate = 0;

    if (this.agences) {
      const total = this.agences.map((a) => a.avis_moyenne).reduce((a, b) => a + b, 0);

      this.rate = total / this.agences.length;
      this.sumTotal = this.rate.toFixed(1);

      const sumNotes = this.notes.reduce((a, b) => a + b, 0);
      this.percent = Math.round(sumNotes / 5);
    }
  }
}
