import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, Self, ElementRef, ViewEncapsulation, input, signal } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormGroup, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ToolsService } from '@/services/tools.service';

@Component({
  selector: 'app-form-input-telephone',
  templateUrl: './form-input-telephone.component.html',
  styleUrls: ['./form-input-telephone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class FormInputTelephoneComponent implements OnInit, OnDestroy, ControlValueAccessor {
  readonly id = input.required<string | number>();
  readonly label = input<string>();
  readonly icon = input<string>();
  readonly options = input<Record<string, string>>();
  readonly validationErrors = input(true);

  public langList = ['fr'];
  public required = false;
  public focus = false;
  public parentForm!: FormGroup;
  public error = signal<any>(false);
  public formControlName!: string | number;

  private subscriptions = new Subscription();

  constructor(
    @Self() protected ngControl: NgControl,
    private controlContainer: ControlContainer,
    private toolsService: ToolsService,
    private el: ElementRef
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    const options = this.options();

    // Store a reference to the parent form group in 'parentForm' by accessing the control container.
    // This allows us to interact with the form group that contains this component's control.
    this.parentForm = this.controlContainer.control as FormGroup;

    // Assign the name of this control (from ngControl) to 'formControlName'.
    // This ensures the control is correctly registered within the parent form for validation and value tracking.
    this.formControlName = this.ngControl.name!;

    this.required = this.toolsService.hasRequiredValidator(this.ngControl.control);

    this.subscriptions.add(
      this.ngControl.statusChanges?.subscribe((status) => {
        this.error.set(this.ngControl.dirty && status === 'INVALID');
      })
    );

    if (options) {
      Object.entries(options).forEach(([key, value]) => {
        this.el.nativeElement[key] = value;
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // ControlValueAccessor methods
  writeValue(): void {}
  registerOnChange(): void {}
  registerOnTouched(): void {}

  // Other methods
  handleFocus(focus: boolean): void {
    this.focus = focus;
  }
}
