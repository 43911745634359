import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: false
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, nbWords: number): unknown {
    return value ? value.split(' ').splice(0, nbWords).join(' ') + '...' : '';
  }
}
