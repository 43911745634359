import { ChangeDetectionStrategy, Component, HostBinding, OnChanges, SimpleChanges, input, signal } from '@angular/core';

import { ButtonAspect, ButtonSize } from '../button/button.component';

@Component({
  selector: 'app-button-telephone',
  templateUrl: './button-telephone.component.html',
  styleUrl: './button-telephone.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ButtonTelephoneComponent implements OnChanges {
  readonly telephone = input<string>();
  readonly aspect = input<ButtonAspect>('arrow-red-dark');
  readonly size = input<ButtonSize>('fit');
  readonly extraClasses = input('');

  @HostBinding('style.width') width!: string;

  public showTel = signal(false);
  public phone?: string;

  ngOnChanges(changes: SimpleChanges): void {
    const { telephone, size } = changes;

    if (telephone) {
      this.phone = this.normalyzePhoneString(this.telephone());
      this.showTel.set(false);
    }

    if (size && (size.currentValue !== size.previousValue)) {
      this.width = (this.size() === 'large') ? '100%' : 'fit-content';
    }
  }

  show(): void {
    this.showTel.update((showTel) => !showTel);
  }

  private normalyzePhoneString(phone?: string): string | undefined {
    return phone ? phone.replace(/\d{2}(?=.)/g, '$& ').replaceAll('.', '') : undefined;
  }
}
