import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { SeoCrossLink } from '@/models';

@Component({
  selector: 'app-cross-links',
  templateUrl: './cross-links.component.html',
  styleUrls: ['./cross-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CrossLinksComponent {
  readonly listes = input<SeoCrossLink[]>([]);
  readonly prefix = input('');
  readonly load = input(false);

  hidden: boolean[] = [true, true, true, true];
}
