import { Component, input, output } from '@angular/core';

@Component({
  selector: 'app-lightbox-container',
  templateUrl: './lightbox-container.component.html',
  styleUrl: './lightbox-container.component.scss',
  standalone: false
})
export class LightboxContainerComponent {
  readonly contentType = input<'video' | 'images'>('images');

  readonly closeLightbox = output();

  handleClose(): void {
    this.closeLightbox.emit();
  }
}
