<div class="civility-field" [formGroup]="parentForm">
  <app-form-select
    id="civilite"
    [formControlName]="formControlName"
    [items]="civilities"
    itemKey="id"
    itemLabel="value"
    label="civility"
    icon="icon-user"
    [validationErrors]="validationErrors()"
  />
</div>
