import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ApiNegociateurResponse, ApiTeamsResponse, SelectOption, TeamsSearchFilters } from '@/models';
import { ConfigService } from './config.service';
import { ToolsService } from './tools.service';

type JobTitles = {
  [key: string]: string[];
};

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  private baseUrl: string;

  public currentPage?: number;
  public totalItems?: number;
  public lastPage?: number;
  public isLoading = false;

  constructor(
    private http: HttpClient,
    private toolsService: ToolsService,
    configService: ConfigService
  ) {
    this.baseUrl = `${configService.config.url}/effectifs`;
  }
  /**
   * Retrieves a negotiator by their ID or slug.
   * @param {string | number} id_or_slug - The ID or slug of the negotiator.
   * @returns {Observable<ApiNegociateurResponse>} An observable containing the negotiator's data.
   */
  getNegociateurById(id_or_slug: string | number): Observable<ApiNegociateurResponse> {
    return this.http.get<ApiNegociateurResponse>(`${this.baseUrl}/${id_or_slug}`);
  }

  /**
   * Retrieves a paginated list of teams with optional filters.
   * @param {number} itemsPerPage - Number of items per page.
   * @param {number} currentPage - The current page number.
   * @param {object} filters - Optional parameters for filtering, such as function and location.
   * @param {boolean} shouldTriggerLoad - Determines if loading animations should be triggered.
   * @returns {Observable<ApiTeamsResponse>} An observable containing the team data.
   */
  getTeams(
    itemsPerPage: number,
    currentPage: number,
    filters?: TeamsSearchFilters,
    shouldTriggerLoad = false
  ): Observable<ApiTeamsResponse> {

    this.isLoading = shouldTriggerLoad ? true : false; // Only trigger loading animations when needed
    const searchFilters = { ...filters, page: currentPage || 1 };
    const { searchLocations, ...keepFilters } = searchFilters;

    keepFilters.per_page = itemsPerPage;

    // Search locations parsing
    if (searchLocations?.length) {
      keepFilters.geo_departement_id = this.toolsService.extractIdsFromLocations('departement', searchLocations);
      keepFilters.geo_region_id = this.toolsService.extractIdsFromLocations('region', searchLocations);
      keepFilters.geo_ville_id = this.toolsService.extractIdsFromLocations('ville', searchLocations);
    }

    const params = new HttpParams({
      fromObject: this.toolsService.sanityzeObject(keepFilters)
    });

    return this.http.get<ApiTeamsResponse>(this.baseUrl, { params });
  }

  /**
   * Retrieves job titles and formats them into select options.
   * @returns {Observable<SelectOption[]>} An observable containing a list of select options for job titles.
   */
  getJobTitles(): Observable<SelectOption[]> {
    return this.http.get<JobTitles>(`${this.baseUrl}/functions`).pipe(
      map((roles: JobTitles) => this.transformRolesToOptions(roles))
    );
  }

  /**
   * Transforms a job titles object into an array of select options.
   * @param {jobTitles} roles - An object containing job titles.
   * @returns {SelectOption[]} An array of select options with job title labels.
   * @private
   */
  private transformRolesToOptions(roles: JobTitles): SelectOption[] {
    return Object.entries(roles).map(([key, values]) => ({ key, label: values.join(' / ') }));
  }
}
