import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-text-round-separator',
  templateUrl: './text-round-separator.component.html',
  styleUrls: ['./text-round-separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TextRoundSeparatorComponent {
}
