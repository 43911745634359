import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-button-circle',
  templateUrl: './button-circle.component.html',
  styleUrl: './button-circle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ButtonCircleComponent {
  readonly icon = input.required<string>();
  readonly shadow = input(true);
  readonly hoverShadow = input(false);
}
