import { ChangeDetectionStrategy, Component, TemplateRef, ViewEncapsulation, input } from '@angular/core';

@Component({
  selector: 'app-tags-container',
  templateUrl: './tags-container.component.html',
  styleUrl: './tags-container.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TagsContainerComponent {
  readonly template = input.required<TemplateRef<any>>();
  readonly tags = input.required<any[]>();
  readonly name = input<string>();
}
