@let a = agence();

<div class='mini-card' [ngClass]="{ 'active' : selected }">
  <p class="display-text-18px">{{ a.enseigne || a.nom }}</p>

  <div class="address-container">
    <p class="display-text-16px">{{ a.adresse }}</p>
    <p class="display-text-16px">{{ a.codepostal }} {{ a.ville }}</p>
  </div>

  <div class="buttons">
    <app-button-telephone
      [telephone]="a.telephone"
      [aspect]="selected ? 'arrow-white' : 'blue'"
      type='button'
      size='large'
    />

    @if (!selected) {
      <app-button
        text='SELL.SELECT'
        (click)="emitAgenceId()"
        aspect='blue'
        size='large'
        type='button'
      />
    }
  </div>

</div>