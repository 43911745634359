@let metier = tag();

<form class="form-contact" [formGroup]="personnalDetails" autocomplete="off">

  @if (!estimation()) {
    @if (!metier) {
      <p class="display-text-18px mb-45px">
        {{ (currentRoute.type === MainRoutes.Effectifs ? 'contact_me' : 'contact_us') | transloco }}
      </p>
    } @else if ((metier === 'gestion locative' || metier === 'gestion de syndic')) {
      <p class="display-text-18px mb-45px">
        {{ ( 'entrust_project' | transloco ) + metier }}
      </p>
    } @else if ((metier === 'locaux commerciaux')) {
      <p class="display-text-18px mb-45px">
        {{ 'Entrust_us_your_project' | transloco }}
      </p>
    }
  }

	<div class="form-inputs">
    @if (showCivility) {
      <app-form-civility
        formControlName="civilite"
        [validationErrors]="submitted"
      />
    }

    <app-form-input
      id="firstname"
      label="firstname"
      icon="icon-user"
      formControlName="firstname"
      [validationErrors]="submitted"
    />

    <app-form-input
      id="lastname"
      label="name"
      icon="icon-user"
      formControlName="lastname"
      [validationErrors]="submitted"
    />

    <app-form-input-telephone
      id="phone"
      label="portable"
      formControlName="phone"
      [validationErrors]="submitted"
    />

    <app-form-input
      id="email"
      label="email"
      icon="icon-email"
      formControlName="email"
      [validationErrors]="submitted"
    />

    @if (showInputGeoloc) {
      <app-form-geoloc
        mode="address"
        (geolocChanged)="handleAutocompleteSelect($event)"
      />
    }

    @if (agenciesOfDepartment.length > 0) {
      <app-form-select
        id="agence_id"
        formControlName="agence_id"
        [items]="agenciesOfDepartment"
        itemKey="era_id"
        itemLabel="enseigne"
        label="select agency"
      />
    }

    @if (metier === 'locaux commerciaux') {
      <div class="switch">
        <label class="switch-item">
          <input type="radio" formControlName="transaction_type" value="TRANSAC_TYPE.FOR_SELL" name="transaction_type" />
          <span> {{ 'to_sell' | transloco }}</span>
        </label>
        <label class="switch-item">
          <input type="radio" formControlName="transaction_type" value="TRANSAC_TYPE.FOR_RENT" name="transaction_type" />
          <span> {{ 'to_rent' | transloco }}</span>
        </label>
      </div>
      <app-form-input
        id="project"
        label="describe project"
        type="textarea"
        formControlName="project"
      />
    }

    @if (showSwitchPropertyToSell) {
      <app-form-switch
        formControlName="has_property_to_sell"
        label="selling"
      />
    }
	</div>

  <app-form-checkbox
    id="contact_agreement"
    label="authorization"
    formControlName="contact_agreement"
  />

  <app-form-checkbox
    id="partner_agreement"
    label="authorization_data"
    formControlName="partner_agreement"
  />

	<div class="block-contact-agency-asterisque">
		<p>{{ 'data' | transloco }} {{ 'data_link' | transloco }}</p>
	</div>

  @if (showSubmitButtonAnnonceAgence) {
    <div class="input-submit">
      @if (messageSuccess) {
        <div class="mt-20px mb-20px">{{ 'form_sent_success' | transloco }}</div>
      }
      @if (noAgencies) {
        <div class="mt-20px mb-20px text-error">{{ 'form_sent_error' | transloco }}</div>
      }

      @if (annonce()) {
        <app-button-loading
          (click)="sendApplicationFormAnnonce()"
          [disabled]="personnalDetails.invalid && submitted"
          [loading]="loading"
          text="appointment"
        />
      }

      @if (agence()) {
        <app-button-loading
          (click)="sendApplicationFormAgence()"
          [disabled]="personnalDetails.invalid && submitted"
          [loading]="loading"
          text="appointment"
        />
      }
    </div>
  }

  @if (estimation()) {
    <div class="input-submit">
      <app-button-loading
        (click)="sendApplicationFormEstimation()"
        [disabled]="personnalDetails.invalid && submitted"
        [loading]="loading"
        text="submit"
      />
    </div>
  }

  @if (showSubmitButtonMetier) {
    <div class="input-submit">
      @if (messageSuccess) {
        <div class="mt-20px mb-20px">{{ 'form_sent_success' | transloco }}</div>
      }
      @if (noAgencies) {
        <div class="mt-20px mb-20px text-error">{{ 'form_sent_error' | transloco }}</div>
      }

      <app-button-loading
        (click)="sendApplicationFormMetier()"
        [disabled]="personnalDetails.invalid && submitted"
        [loading]="loading"
        text="submit"
      />
    </div>
  }

  @if (annonce(); as a) {
    <p class="display-text-16px reference text-center">
      {{ 'reference' | transloco }} {{ 'ad' | transloco }} : <span>{{ a.reference }}</span>
    </p>
  }

	<input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" />
	<input type="hidden" name="action" value="validate_captcha" />
</form>
