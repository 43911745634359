import { Component, OnInit, input } from '@angular/core';
import { Router } from '@angular/router';

import { Agence, Negociateur } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss'],
  standalone: false
})
export class TeamCardComponent implements OnInit {
  readonly team = input.required<Negociateur>();
  readonly agence = input<Agence>();
  readonly column = input(false);
  readonly isList = input(false);

  public MainRoutes = MainRoutes;
  public showAddress: boolean;
  public showCoords = false;
  public langues = '';

  constructor({ url }: Router) {
    this.showAddress = (url.includes('/groupes') || url.includes('/effectifs') || url.includes('/equipes'));
  }

  ngOnInit() {
    this.langues = this.team().langues.filter((langue) => langue !== 'Français').join(' - ');
  }
}
