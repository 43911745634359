import { ChangeDetectionStrategy, Component, Input, input } from '@angular/core';

import { Agence, AgenceLight } from '@/models';

@Component({
  selector: 'app-statement-agence-card',
  templateUrl: './statement-agence-card.component.html',
  styleUrls: ['./statement-agence-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class StatementAgenceCardComponent {
  @Input() agence!: Agence | AgenceLight;
  readonly notes = input<number[]>([]);
}
