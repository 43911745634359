@let dis = disabled();
@let ico = icon();
@let hr = href();

<button
  #ref
  class="abtn {{ extraClasses() }} {{ size() }}"
  [ngClass]="[aspect() ? 'abtn-' + aspect() : '', dis ? 'disabled' : '']"
  data-testid="button"
>
  @if (text(); as t) {
    @let btnText = translate() ? (t | transloco) : t;

    @if (link(); as l) {
      <a [routerLink]="l" [queryParams]="queryParams()" [fragment]="fragment()" [ngClass]="{ 'wrap': wrap() }" data-testid="link">
        <p [ngClass]="{ 'abtn-text-transition': !dis, 'wrap': wrap() }" data-testid="text">
          {{ btnText }}
        </p>
        @if (ico && !dis) {
          <span class="{{ ico }} fs-24px abtn-opacity-transition" data-testid="icon"></span>
        }
      </a>
    } @else if (hr) {
      <a [href]="hr" [target]="target()" [ngClass]="{ 'wrap': wrap() }" data-testid="href">
        <p [ngClass]="{ 'abtn-text-transition': !dis, 'wrap': wrap() }" data-testid="text">
          {{ btnText }}
        </p>
        @if (ico && !dis) {
          <span class="{{ ico }} fs-24px abtn-opacity-transition" data-testid="icon"></span>
        }
      </a>
    } @else {
      <p [ngClass]="{ 'abtn-text-transition': !dis, 'wrap': wrap() }" data-testid="text">
        {{ btnText }}
      </p>
      @if (ico && !dis) {
        <span class="{{ ico }} fs-24px abtn-opacity-transition" data-testid="icon"></span>
      }
    }
  }
  <ng-content />
</button>
