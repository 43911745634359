import { ChangeDetectionStrategy, ChangeDetectorRef, Component, input, OnDestroy, OnInit, signal } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-password-tips',
  templateUrl: './password-tips.component.html',
  styleUrl: './password-tips.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PasswordTipsComponent implements OnInit, OnDestroy {
  readonly field = input.required<AbstractControl | NgControl>();

  private subscriptions = new Subscription();

  constructor(private cd: ChangeDetectorRef) {}

  rules = signal([
    { key: 'MIN_LENGTH_8', label: 'PASSWORD_TIPS.MIN_LENGTH_8', valid: false, regex: /.{8,}/ },
    { key: 'NOT_SPECIAL', label: 'PASSWORD_TIPS.NOT_SPECIAL', valid: true, regex: /^[A-Za-z0-9]*$/ },
    { key: 'ONE_NUMBER', label: 'PASSWORD_TIPS.ONE_NUMBER', valid: false, regex: /\d/ },
    { key: 'ONE_LOWERCASE', label: 'PASSWORD_TIPS.ONE_LOWERCASE', valid: false, regex: /[a-z]/ },
    { key: 'ONE_UPPERCASE', label: 'PASSWORD_TIPS.ONE_UPPERCASE', valid: false, regex: /[A-Z]/ }
  ]);

  ngOnInit(): void {
    this.subscriptions.add(
      this.field().valueChanges!.subscribe((value: string) => {
        this.validatePassword(value);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private validatePassword(value: string): void {
    this.rules.update((rules) => rules.map((rule) => ({
      ... rule,
      valid: rule.regex.test(value)
    })));
  }
}
