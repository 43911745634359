import { Component, OnDestroy, OnInit, input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SeoRouteName } from '@/models';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss'],
  standalone: false
})
export class LandingHeaderComponent implements OnInit, OnDestroy {
  readonly title = input<string>('');
  readonly edito = input<string>('');
  readonly image = input<string>('assets/imgs/landing-header.png');
  readonly selectPlaceholder = input<string>('Sélectionner une région');
  readonly options = input<SeoRouteName[]>([]);

  private subscriptions = new Subscription();
  public route = new FormControl('');

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.valueChanges.subscribe((newRoute) => {
        if (newRoute) {
          this.router.navigateByUrl(newRoute);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
