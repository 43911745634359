<div class="metiers">
	<div id="wrapper-metier">
		<div id="main-metier">

			@if ((metier === 'gestion locative') && gestion_locative) {

				<div
					class="title-container bg-cover bg-top bg-no-repeat bg-custom p-80px"
					[ngStyle]="{ 'background-image': gestion_locative.image ?
						'url(' + url_photos_from_BO + '/' + gestion_locative.image + ')' :
						'url(assets/imgs/landing-header.png)'
					}"
				>
					<div class="title">
						<h1 class="display-h1-34px text-white mb-20px">
							{{ gestion_locative.title }}
						</h1>
						<p class="display-text-16px text-white mb-80px">
							{{ gestion_locative.subtitle }}
						</p>
						<div class="chiffres">
							<div class="rond">
								<p class="display-text-24px mb-5px font-bold">{{ gestion_locative.stats_number_1 }}</p>
								<p class="display-text-12px text-center uppercase">{{ gestion_locative.stats_text_1 }}</p>
							</div>
							<div class="rond">
								<p class="display-text-24px mb-5px font-bold">{{ gestion_locative.stats_number_2 }}</p>
								<p class="display-text-12px text-center uppercase">{{ gestion_locative.stats_text_2 }}</p>
							</div>
							<div class="rond">
								<p class="display-text-24px mb-5px font-bold">{{ gestion_locative.stats_number_3 }}</p>
								<p class="display-text-12px text-center uppercase">{{ gestion_locative.stats_text_3 }}</p>
							</div>
						</div>
					</div>
				</div>

			} @else if ((metier === 'gestion de syndic') && syndic_corpro) {

				<div
					class="title-container bg-cover bg-top bg-no-repeat bg-custom p-80px"
					[ngStyle]="{ 'background-image': syndic_corpro.image ?
						'url(' + url_photos_from_BO + '/' + syndic_corpro.image + ')' :
						'url(assets/imgs/landing-header.png)'
					}"
				>
					<div class="title">
						<h1 class="display-h1-34px text-white mb-20px">
							{{ syndic_corpro.title }}
						</h1>
						<p class="display-text-16px text-white mb-80px">
							{{ syndic_corpro.subtitle }}
						</p>
						<div class="chiffres flex">
							<div class="rond">
								<p class="display-text-24px mb-5px font-bold">{{ syndic_corpro.stats_number_1 }}</p>
								<p class="display-text-12px text-center uppercase">{{ syndic_corpro.stats_text_1 }}</p>
							</div>
							<div class="rond">
								<p class="display-text-24px mb-5px font-bold">{{ syndic_corpro.stats_number_2 }}</p>
								<p class="display-text-12px text-center uppercase">{{ syndic_corpro.stats_text_2 }}</p>
							</div>
							<div class="rond">
								<p class="display-text-24px mb-5px font-bold">{{ syndic_corpro.stats_number_3 }}</p>
								<p class="display-text-12px text-center uppercase">{{ syndic_corpro.stats_text_3 }}</p>
							</div>
						</div>
					</div>
				</div>

			} @else if ((metier === 'locaux commerciaux') && locaux_commerciaux) {

				<div
					class="title-container bg-cover bg-top bg-no-repeat bg-custom p-80px"
					[ngStyle]="{ 'background-image': locaux_commerciaux.image ?
						'url(' + url_photos_from_BO + '/' + locaux_commerciaux.image + ')' :
						'url(assets/imgs/landing-header.png)'
					}"
				>
					<div class="title">
						<h1 class="display-h1-34px text-white mb-20px">
							{{ locaux_commerciaux.title }}
						</h1>
						<p class="display-text-16px text-white mb-80px">
							{{ locaux_commerciaux.subtitle }}
						</p>
						<div class="chiffres flex">
							<div class="rond">
								<p class="display-text-24px mb-5px font-bold">{{ locaux_commerciaux.stats_number_1 }}</p>
								<p class="display-text-12px text-center uppercase">{{ locaux_commerciaux.stats_text_1 }}</p>
							</div>
							<div class="rond">
								<p class="display-text-24px mb-5px font-bold">{{ locaux_commerciaux.stats_number_2 }}</p>
								<p class="display-text-12px text-center uppercase">{{ locaux_commerciaux.stats_text_2 }}</p>
							</div>
							<div class="rond">
								<p class="display-text-24px mb-5px font-bold">{{ locaux_commerciaux.stats_number_3 }}</p>
								<p class="display-text-12px text-center uppercase">{{ locaux_commerciaux.stats_text_3 }}</p>
							</div>
						</div>
					</div>
				</div>

			}

			<div class="main-containers">

				@if (metier === 'gestion locative' || metier === 'gestion de syndic') {

					<div class="w-full">
						@if ((metier === 'gestion locative') && gestion_locative) {
							<div>
								<p class="display-h2 mb-40px">{{ 'advantages era' | transloco }}</p>
								<div class="card-metiers p-20px mb-40px">
									<div class="image-container">
										<img src="{{ url_photos_from_BO + '/' + gestion_locative.avantages_image_1 }}" alt="" />
									</div>
									<p class="mt-25px mb-20px">{{ gestion_locative.avantages_title_1 }}</p>
									<div class="display-text-16px" [innerHtml]=" gestion_locative.avantages_content_1"></div>
								</div>
								<div class="card-metiers p-20px mb-40px">
									<div class="image-container">
										<img src="{{ url_photos_from_BO + '/' + gestion_locative.avantages_image_2 }}" alt="" />
									</div>
									<p class="mt-25px mb-20px">{{ gestion_locative.avantages_title_2 }}</p>
									<div class="display-text-16px" [innerHtml]=" gestion_locative.avantages_content_2"></div>
								</div>
								<div class="card-metiers p-20px mb-40px">
									<div class="image-container">
										<img src="{{ url_photos_from_BO + '/' + gestion_locative.avantages_image_3 }}" alt="" />
									</div>
									<p class="mt-25px mb-20px">{{ gestion_locative.avantages_title_3 }}</p>
									<div class="display-text-16px" [innerHtml]=" gestion_locative.avantages_content_3"></div>
								</div>
							</div>

						} @else if ((metier === 'gestion de syndic') && syndic_corpro) {

							<div>
								<p class="display-h2 mb-40px">{{ 'advantages era' | transloco }}</p>
								<div class="card-metiers p-20px mb-40px">
									<div class="image-container">
										<img src="{{ url_photos_from_BO + '/' + syndic_corpro.avantages_image_1 }}" alt="" />
									</div>
									<p class="mt-25px mb-20px">{{ syndic_corpro.avantages_title_1 }}</p>
									<div class="display-text-16px" [innerHtml]=" syndic_corpro.avantages_content_1"></div>
								</div>
								<div class="card-metiers p-20px mb-40px">
									<div class="image-container">
										<img src="{{ url_photos_from_BO + '/' + syndic_corpro.avantages_image_2 }}" alt="" />
									</div>
									<p class="mt-25px mb-20px">{{ syndic_corpro.avantages_title_2 }}</p>
									<div class="display-text-16px" [innerHtml]=" syndic_corpro.avantages_content_2"></div>
								</div>
								<div class="card-metiers p-20px mb-40px">
									<div class="image-container">
										<img src="{{ url_photos_from_BO + '/' + syndic_corpro.avantages_image_3 }}" alt="" />
									</div>
									<p class="mt-25px mb-20px">{{ syndic_corpro.avantages_title_3 }}</p>
									<div class="display-text-16px" [innerHtml]=" syndic_corpro.avantages_content_3"></div>
								</div>
							</div>

						}

						<!-- ACTUALITES -->
						@if (actualites?.length) {
							<div>
								<div class="actualites-title flex justify-between items-center mb-40px">
									<p class="display-h2">{{ 'real estate news' | transloco }}</p>
									<span class="display-text-12px cursor-pointer underline ml-106px" [routerLink]="[blogLink]" fragment="{{ metier }}">
										{{ 'see all news' | transloco }}
									</span>
								</div>
								<div class="actualites flex flex-row flex-wrap">
									@for (actualite of actualites; track actualite.id) {
										<app-blog-card class="mb-30px" [actualite]="actualite" />
									}
								</div>
							</div>
						}
					</div>
				}

				@if (metier === 'locaux commerciaux') {

					<!-- FAQS -->
					<div class="flex w-full flex-col faqs mb-80px">
						<app-faq-container [tag]="'locaux commerciaux'" [faqs]="faqs" />
					</div>

					<!-- ANNONCES -->
					<div class="w-full mb-160px">
						<p class="display-h2 mb-40px flex">
							{{ totalBiens }} {{ 'property' | transloco }}{{totalBiens > 1 ? 's' : ''}} professionnel{{ totalBiens > 1 ? 's' : ''}} {{ 'in our network' | transloco }}
						</p>
						<div class="annonces flex flex-row flex-wrap">
							@for (annonce of annonces | slice: 0:3; track annonce.id) {
								<app-annonce-card [annonce]="annonce" [listAnnonces]="false" />
							}
							@if (annonces.length > 3) {
								<div class="flex">
									<div class="flex cardAnnonce special">
										<div class="cardAnnonce-img">
											<img src="/assets/imgs/house-agence.png" alt="" />
										</div>
										<div class="cardAnnonce-text">
											<h5 class="display-h5">{{ 'discover our selection' | transloco }}</h5>
											@if (isGroupe) {
												<app-button
													[link]="['/acheter-local commercial,fonds de commerce,bureaux,entrepôts,terrains,cession de bail,murs']"
													[queryParams]="{  groupe_id: agence?.id, geoloc_lat: agence?.geoloc?.lat, geoloc_lng: agence?.geoloc?.lng }"
													text="see_all_properties"
												/>
											} @else {
												<app-button
													[link]="['/acheter-local commercial,fonds de commerce,bureaux,entrepôts,terrains,cession de bail,murs']"
													[queryParams]="{ agence_id: agence?.id, geoloc_lat: agence?.geoloc?.lat, geoloc_lng: agence?.geoloc?.lng }"
													text="see_all_properties"
												/>
											}
										</div>
									</div>
								</div>
							}
						</div>
					</div>

					<!-- AVIS CLIENTS -->
					<div class="w-full avis-locaux-commerciaux">
						<div class="avis-container">
							<div class="flex justify-between items-center flex-wrap mb-40px">
								<p class="display-h2 pb-20px flex">
									{{ 'words about clients' | transloco }}
								</p>
								<p [routerLink]="[avisLink]" class="display-text-14px pb-20px text-transition underline cursor-pointer">
									Voir les {{ avisCount ?? allAvisCount }} avis
								</p>
							</div>
							<div class="avis">
								@for (avis of topAvisAgences; track avis) {
									<app-statement-card [agence]="agence" [avis]="avis" />
								}
							</div>
						</div>
					</div>

				}

        <app-contact-us-card [tag]="metier" [agence]="agence" id="sc-metier" source="SOURCE.SITE_NATIONAL" />

			</div>
		</div>
	</div>
</div>