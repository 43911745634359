import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-dot-loader',
  templateUrl: './dot-loader.component.html',
  styleUrls: ['./dot-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class DotLoaderComponent {
}
