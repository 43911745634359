import { Component, Input } from '@angular/core';

import { MainRoutes } from '@/constants';
import { EmploiPoste } from '@/models';

@Component({
  selector: 'app-poste-card',
  templateUrl: './poste-card.component.html',
  styleUrls: ['./poste-card.component.scss'],
  standalone: false
})
export class PosteCardComponent {
  @Input() poste!: EmploiPoste;
  @Input() emploiId?: number;

  public MainRoutes = MainRoutes;
}
