@let op = opening();
@let a = agence();

<div class="box flex flex-col">
	<div class="image-container">
		<p class="tag display-text-12px">{{ a?.enseigne || a?.nom }} </p>
		<img src="/assets/imgs/opening-era-default.jpg" alt="" />
	</div>
	<div class="details">
		<h2 class="display-h5 mb-12px mt-25px display-text-20px">{{ 'open door' | transloco }} : {{ op?.libelle }}</h2>
		<div class="flex items-center my-auto">
			<span class="icon-calendar color-location display-text-20px"></span>
			<p class="display-text-16px lh-32px mt-auto">{{ op?.openhouse }}</p>
		</div>
	</div>

	@if (op && op.descriptif) {
		<div class="description mt-30px">
			<p class="display-text-16px lh-32px mt-auto whitespace-pre-wrap" [ngClass]="{ descriptif: !more }">
				{{ more ? op.descriptif : op.descriptif | truncate:100 }}
			</p>
			@if (op.descriptif.length > 100) {
				<div (click)="showMore()" class="mt-10px cursor-pointer" id="opening-click">
					<p class="display-text-16px">{{ (more ? 'see_less' : 'see_more') | transloco }}</p>
				</div>
			}
		</div>
	}

	<div class="buttons flex justify-between mt-35px w-full">
		<app-button [link]="MainRoutes.Annonce + '/' + op?.id" text="see_ad" aspect="arrow-white" size="large" />
    <app-button text="register_event" size="large" (click)="onOpenModalOpening()" />
	</div>
</div>

@if (openModalOpening) {
	<div class="modal-opening-subscribe-overlay">
		<div class="modal-opening-subscribe">
			<div class="container">
				<div class="container-header flex mb-15px">
					<h5 class="display-h3-28px">{{ op?.libelle }}</h5>
					<div (click)="onCloseModalOpening()">
						<span class="icon-close closeDrawModal"></span>
					</div>
				</div>
				<div class="container-content">
					<div class="infos">
						<div class="date-time mr-80px">
							<div class="flex items-center my-auto mb-36px">
								<span class="icon-calendar color-location display-text-20px"></span>
								<p class="display-text-16px lh-32px mt-auto">{{ op?.openhouse }}</p>
							</div>
						</div>
					</div>

					<div class="details mb-30px">
						<div class="display-text-20px mb-30px">{{ 'property_description' | transloco }}</div>
						<p class="display-text-16px whitespace-pre-wrap">{{ op?.descriptif }}</p>
					</div>

					<div class="user-info mt-30px">
						<div class="display-text-20px mb-46px">{{ 'subscribe_event' | transloco }}</div>
						<form [formGroup]="form" class="form-contact" (ngSubmit)="onSubmit()">
							<div class="form-inputs">

								<div class="fullname">
									<div class="field">
										<app-form-input
											id="nom"
											label="name"
											icon="icon-user"
											formControlName="nom"
											[validationErrors]="submitted"
										/>
									</div>

									<div class="field">
										<app-form-input
											id="prenom"
											label="firstname"
											icon="icon-user"
											formControlName="prenom"
											[validationErrors]="submitted"
										/>
									</div>
								</div>

								<app-form-input-telephone
									id="telephone"
									formControlName="telephone"
									label="portable"
									[validationErrors]="submitted"
								/>

								<div class="email">
									<app-form-input
										id="email"
										label="email"
										icon="icon-email"
										formControlName="email"
										[validationErrors]="submitted"
									/>
								</div>

								<app-button-loading
									[disabled]="form.invalid && submitted"
									[loading]="loading"
									text="event_form_send"
									size="large"
								/>

								<div class="block-contact-agency-asterisque mb-20px">
									<p>{{ 'data' | transloco }} {{ 'data_link' | transloco }}</p>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
}
