@let nego = team();
@let av = avis();
@let agc = agence();
@let grp = groupeAgence();

<div
  class="statement-card"
  [ngClass]="(!av.satisfaction && !av.recommandation) || (nego && av.satisfaction && av.recommandation) || !nego ? 'h-full' : 'height-team'"
>

	<div class="statement-card-star">
    <app-rating-stars [rate]="av.avg_score" />
		<span>{{ av.avg_score | number:'':'fr-FR' }}</span>
	</div>

  <div class="statement-card-title">
    <div class="statement-card-title-picto">
      @if (av.firstname && av.lastname) {
        <p>{{ av.firstname.charAt(0) }} {{ av.lastname.charAt(0) }}</p>
      } @else {
        <p>{{ av.name.charAt(0) }} {{ av.name.charAt(av.name.length - 2) }}</p>
      }
    </div>

    <div>
      @if (av.firstname && av.lastname) {
        <p class="display-text-18px">
          {{ av.firstname }}
          <span>{{ av.lastname.charAt(0) }}.</span>
        </p>
      } @else {
        <p class="display-text-18px">{{ av.name }}</p>
      }
      <p class="display-text-12px">{{ 'published at' | transloco }} {{ av.posted_at }}</p>
    </div>
  </div>

  @if (agc || grp) {
    <div class="statement-card-address">
      @if (agc?.enseigne) {
        <p class="display-text-14px">{{ agc?.enseigne }}</p>
      }
      @if (grp?.nom) {
        <p class="display-text-14px">{{ grp?.nom }}</p>
      } @else if (agc?.nom) {
        <p class="display-text-14px">{{ agc?.nom }}</p>
      }
    </div>
  }

  <div class="statement-card-contact">
    @if (av.comment && av.comment.length) {
      <p id="comment">
        <span #commentText>"{{ av.comment }}"</span>
      </p>
    }
  </div>

  @if (showMoreButton()) {
    <div class="show-more" (click)="showMore = !showMore">
      <p class="display-text-14px underline cursor-pointer">
        {{ 'see_more' | transloco }}
      </p>
    </div>
  }

  @if (showMore) {
    <div (click)="showMore = !showMore" class="show-more-content">
      <span (click)="showMore = !showMore" class="icon-close"></span>
      <p>{{ av.comment }}</p>
    </div>
  }

  @if (av.satisfaction && av.recommandation) {
    <div class="recommandation">
      <div class="recommandation-content">
        <app-donut
          size="tiny"
          text="{{ av.satisfaction }} %"
          [first]="av.satisfaction / 100"
          [second]="1 - (av.satisfaction / 100)"
        />
        <p class="display-text-12px">{{ 'satisfaction' | transloco }}</p>
      </div>
      <div class="recommandation-content">
        <app-donut
          size="tiny"
          text="{{ av.recommandation }} %"
          [first]="av.recommandation / 100"
          [second]="1 - (av.recommandation / 100)"
        />
        <p class="display-text-12px">{{ 'recommandation' | transloco }}</p>
      </div>
    </div>
  } @else if (nego) {
    <div class="statement-card-address red">
      @if (nego.agence?.enseigne) {
        <p class="display-text-14px">{{ nego.agence?.enseigne }}</p>
      }
    </div>
  }
</div>
