export enum MainRoutes {
  Estimation = '/estimation',
  Estimer = '/estimer',
  ToutesNosAgences = '/toutes-nos-agences',
  AgenceImmobiliere = '/agence-immobiliere',
  Agences = '/agences',
  Annonce = '/annonces',
  Contact = '/contact',
  Groupes = '/groupes',
  Effectifs = '/effectifs',
  Recrutement = '/recrutement',
  OffreEmploi = '/emploi-immobilier',
  Faqs = '/questions-frequentes',
  SitePlan = '/plan-site',
  PersonalData = '/donnees-personnelles',
  Favoris = '/mes-favoris',
  Guide = '/guide',
  Blog = '/blog-immobilier',
  Avis = '/avis',
  MentionsLegales = '/mentions-legales',
  GestionLocative = '/gestion-locative',
  Syndic = '/syndic',
  LocauxCommerciaux = '/locaux-commerciaux',
  ResetPassword = '/nouveau-mot-passe',
  Profile = '/profile',
  NotFound = '/page-non-trouvee',
  Acheter = '/acheter',
  Louer = '/louer',
  Metiers = '/metiers',
  Achat = '/achat',
  Location = '/location'
}

export type MainPath = `${MainRoutes}`;

export enum ProfileRoutes {
  SuiviBiens = '/suivi-des-biens',
  Favoris = '/favoris',
  Notifications = '/notifications',
  Recherches = '/recherches',
  Infos = '/infos'
}

export enum EstimationRoutes {
  Address = '/adresse',
  Type = '/type-bien',
  Criteria = '/criteres',
  Personal = '/infos-personnelles',
  Result = '/resultat'
}

export const FavorisRoute = MainRoutes.Profile + ProfileRoutes.Favoris;
