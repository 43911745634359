import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ContentService } from '@/services/content.service';
import { HomePageContent } from '@/models';

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss'],
  standalone: false
})
export class LegalNoticeComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public legal_notice?: HomePageContent;

  constructor(private contentService: ContentService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.contentService.getContent().subscribe(((data) => {
        this.legal_notice = data;
      }))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
