@let horaires = agence?.horaires ?? agenceGroup?.horaires;

@if (isHoraireModalVisible && horaires) {
  <app-modal-container>
    <div class="w-full flex-column p-30px">
      <div class="w-full flex justify-between items-center mb-30px">
        <h3 class="display-h3">{{ 'AGENCE.SCHEDULES' | transloco }}</h3>
        <app-button-circle icon="icon-close" (click)="showHoraireModal(false)" />
      </div>
      @if (horaires) {
        <app-agence-horaires [now]="false" [height]="16" [horaires]="horaires" />
      }
    </div>
  </app-modal-container>
}

<div class="agence-presentation">
  <div class="agence-presentation-flex">

    <div class="agence-presentation-image">
      @let isVideo = !!(agence?.video_presentation || agenceGroup?.video_presentation);

      @if (isVideo) {
        @if (isVideoModalVisible) {
          <app-modal-container color="transparent">
            <div class="video">
              <app-button-circle icon="icon-close" (click)="closeVideo()" />
              <iframe
                allow="autoplay"
                allowfullscreen
                (ended)="closeVideo()"
                width="400"
                height="300"
                [src]="videoUrl | sanitizeUrl"
              ></iframe>
            </div>
          </app-modal-container>
        } @else {
          <span (click)="playVideo()" class="button-play"></span>
        }
      }

      @if (agence) {
        <img [src]="agence.images?.little || agence.photo" (error)="onImgError($event)" alt="Agence {{ agence.enseigne }}" />
      } @else if (agenceGroup) {
        <img [src]="agenceGroup.picture_group_url" (error)="onImgError($event)" alt="Agence {{ agenceGroup.nom }}" />
      }
    </div>

    <div class="agence-presentation-text">
      @if (agence && agence.avis.length) {
        <a
          [routerLink]="['/' + agence.slug + '/avis']"
          class="agence-presentation-text-review"
          [attr.aria-label]="'avis' | transloco"
        >
          <app-rating-stars [rate]="average" />
          <p class="display-text-14px">{{ agence.avis.length }} {{ 'customers_opinion' | transloco }}</p>
        </a>
      }

      @if (agenceGroup && agenceGroup.avis_total) {
        <a
          [routerLink]="[MainRoutes.Groupes + '/' + agenceGroup.slug + '-' + agenceGroup.id + '/temoignages']"
          class="agence-presentation-text-review"
          [attr.aria-label]="'avis' | transloco"
        >
          <app-rating-stars [rate]="agenceGroup.avis_total" />
        </a>
      }

      @if (agence || agenceGroup) {
        <div>
          @if (agence && agence.enseigne && agence.statut !== 2) {
            <h1 class="display-h1-34px">
              {{ agence.enseigne }}
            </h1>
          } @else if (agenceGroup) {
            <h1 class="display-h1-34px">{{ agenceGroup.nom }}</h1>
          } @else if (agence) {
            <h1 class="display-h1-34px">
              ({{ 'shortly' | transloco }}) ERA Immobilier {{ agence.ville + "," + agence.codepostal }}
            </h1>
          }
        </div>
      }

      <div class="agence-presentation-contact">
        <div class="hours">
          @if (horaires) {
            <div class="flex flex-row items-center">
              <app-agence-horaires [now]="true" [height]="16" [horaires]="horaires" />
            </div>
          }
          <div class="display-text-14px underline cursor-pointer" (click)="showHoraireModal(true)">
            {{ 'AGENCE.SCHEDULES' | transloco }}
          </div>
        </div>

        <div class="address">
          <div class="flex mb-12px items-center">
            <span class="icon-location fs-20px mr-10px grey-icon"></span>
            <p class="display-text-16px adresse">
              {{ agenceGroup?.adresse }}
              <span class="display-block">{{ agenceGroup?.codepostal }} {{ agenceGroup?.ville }}</span>
              {{ agence?.adresse }} <span class="display-block">{{ agence?.codepostal }} {{ agence?.ville }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="agence-contact">
        <div class="contactContent display-text-16px">
          @let phone = agenceGroup ? agenceGroup.telephone : (agence && agence.statut !== 2) ? agence.telephone : undefined;
          <app-button-telephone aspect="arrow-red" [telephone]="phone" />
        </div>
        <div class="contactContent display-text-16px">
          @if (agence && agence.groupe_id) {
            <app-button aspect="arrow-red" [link]="[MainRoutes.Groupes + '/' + agence.groupe?.slug]" text="see group site" />
          }
        </div>
      </div>
    </div>

    <app-network [agenceGroup]="agenceGroup" [agence]="agence" />
  </div>

  @if (agenceGroup && agenceGroup.agences) {
    <div class="agences-groupement">
      <header>
        <p>
          {{ 'the group' | transloco }} <span class="font-semibold">{{ agenceGroup.nom }}</span> {{ 'is composed of' | transloco }}
          <span class="font-semibold">{{ agenceGroup.agences.length }}</span> {{ 'agency' | transloco }}s&nbsp;:
        </p>
      </header>
      <div class="agences-groupement-list" #agences>
        @for (agence of agenceGroup.agences; track agence.id) {
          <app-agence-groupement [agence]="agence" />
        }
      </div>
    </div>
  }
</div>
