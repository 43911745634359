import { Annonce } from './annonce.model';
import { Avis, AvisGlobal } from './avis.model';
import { Emploi } from './emploi.model';
import { FeaturesDisplay } from './features.model';
import { Coordinates, GeoDepartement } from './geoloc.model';
import { SocialUrls } from './social.model';
import { Negociateur } from './team.model';

export type Agence = {
  id: number;
  nom?: string;
  era_id: number;
  groupe_id?: number;
  groupe?: AgenceGroupe;
  email?: string;
  photo?: string;
  enseigne: string;
  adresse?: string;
  codepostal?: string;
  horaires: AgenceHoraire[];
  telephone?: string;
  nb_annonces: number;
  nb_annonces_vente: number;
  nb_annonces_location: number;
  slug: string;
  coords?: Coordinates;
  location: any;
  geoloc?: Coordinates;
  geo_departement: GeoDepartement;
  opinion_id?: number;
  nbr_avis?: number;
  avis_moyenne?: number;
  avis: Avis[];
  avis_global?: AvisGlobal;
  avis_notes?: number[];
  ville: string;
  statut: number;
  images?: {
    little?: string;
    big?: string;
  };
  video_presentation?: string;
  is_club_manager?: boolean;
  is_club_manager_premium?: boolean;
  is_qualite_service?: boolean;
  feature_display?: FeaturesDisplay;
  emplois: Emploi[];
  effectifs: Negociateur[];
  arrondissement: string;
  annonces?: Annonce[];
  annonces_location?: Annonce[];
  annonces_vendus?: Annonce[];
  annonces_vente?: Annonce[];
  picture_theme_url?: string;
  social_network_url?: SocialUrls;
  raison_sociale?: string;
  adresse_siege_social?: string;
  forme_juridique?: string;
  capital?: number;
  siren?: string;
  rcs?: string;
  tva_cee?: string;
  carte_transac?: string;
  carte_gestion?: string;
  cartes_prefecture?: string;
  caisse_garantie?: string;
  caisse_garantie_adresse?: string;
  non_detention_fond?: string;
  montant_garantie?: string;
  bareme?: string;
  mediateur?: {
    nom: string;
    email: string;
    adresse: string;
  },
  service?: string[];
};

export type AgenceGroupe = Agence & {
  era_agence_principale_id: number;
  picture_group_url?: string;
  avis_total: number;
  agences: Agence[];
  service?: {
    service: string;
    agences: number[];
  }[];
};

export type AgenceLight = {
  id: number;
  era_id: number;
  enseigne: string;
  slug: string;
  adresse: string;
  codepostal: string;
  ville: string;
  telephone: string;
  site_web: string;
  photo: string;
  bareme: string;
  opinion_id: number;
  avis_moyenne: number;
  nbr_avis: number;
  horaires: AgenceHoraire[];
};

export type AgenceHoraireDay = 'lu' | 'ma' | 'me' | 'je' | 've' | 'sa' | 'di';

export enum AgenceHoraireStatus {
  Open = 'o',
  Closed = 'f'
}

export type AgenceHoraire = {
  horaires: string[];
  jour: AgenceHoraireDay;
  statut: AgenceHoraireStatus;
};

export const agenceGeoloc = ({ geoloc, location }: Agence): Coordinates | undefined => {
  return geoloc ? geoloc : location ? { lat: location.split(',')[0], lng: location.split(',')[1] } : undefined;
};

export type CurrentAgence = {
  data: Agence | AgenceGroupe;
  type: 'agence' | 'groupe';
};

export type AgencesAvis = {
  agences: AgenceLight[];
  nbr_avis: number;
  moyenne: number;
  notes: number[];
};

export type GroupeAgencesAvis = {
  avis: Avis[];
  avis_total: number;
  avis_moyenne: number[];
  notes: number[];
};
