import { ChangeDetectionStrategy, Component, OnChanges, SimpleChanges, input, output } from '@angular/core';

import { Agence, AgenceGroupe } from '@/models';

@Component({
  selector: 'app-agence-mini-card',
  templateUrl: './agence-mini-card.component.html',
  styleUrl: './agence-mini-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AgenceMiniCardComponent implements OnChanges {
  readonly selectedAgenceId = input<number>();
  readonly agence = input.required<Agence>();

  readonly cardClicked = output<number>();

  private agenceId!: number;
  public selected = false;

  ngOnChanges(changes: SimpleChanges): void {
    const agence = this.agence();

    if (changes['selectedAgenceId']) {
      this.agenceId = (agence as AgenceGroupe).era_agence_principale_id || agence.era_id;
      this.selected = this.agenceId === this.selectedAgenceId();
    }
  }

  emitAgenceId(): void {
    this.cardClicked.emit(this.agenceId);
  }
}
