import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { combineLatest, Subscription } from 'rxjs';

import { AgencesService } from '@/services/agences.service';
import { OffreEmploiService } from '@/services/offre-emploi.service';
import { MetadataService } from '@/services/metadata.service';
import { RouteObserverService } from '@/services/route-observer.service';
import { Agence, AgenceGroupe, EmploiPoste, SeoFaqEntry } from '@/models';

@Component({
  selector: 'app-emploi',
  templateUrl: './emploi.component.html',
  styleUrls: ['./emploi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EmploiComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  public totalOffres?: number;
  public postes: EmploiPoste[] = [];
  public faqs: SeoFaqEntry[] = [];
  public eventsLink!: string;
  public agence?: Agence;

  constructor(
    private gtmService: GoogleTagManagerService,
    private routeObserver: RouteObserverService,
    private emploisService: OffreEmploiService,
    private agencesService: AgencesService,
    private meta: MetadataService,
    private cd: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    const { data: { title, description, ogTitle, ogDescription, ogUrl, type }, url } = this.routeObserver.currentRoute;

    this.meta.setMetadata({
      title: title,
      description: description,
      ogTitle: ogTitle,
      ogDescription: ogDescription,
      ogUrl: ogUrl
    });

    if (type === 'agence') {
      this.getCurrentAgenceEmplois();
    } else {
      this.getAllEmplois();
    }

    this.eventsLink = `${url}/evenements`;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getCurrentAgenceEmplois(): void {
    this.subscriptions.add(
      this.agencesService.agence$.subscribe((agence) => {
        if (agence) {
          const { data, type } = agence;

          this.agence = data;

          this.gtmService.pushTag({
            'event': 'Pageview',
            'id-agence': this.agence.id,
            'nom-agence': this.agence.enseigne,
            'pagetype': 'Recrutement'
          });

          if (type === 'groupe') {
            const groupe = (data as AgenceGroupe);

            this.postes = groupe.agences.filter(
              (agence) => !!agence.emplois
            ).flatMap(
              (agence) => agence.emplois?.map((emploi) => ({
                ...emploi.poste,
                enseigne: emploi.agence.enseigne,
                emploiId: emploi.id
              }))
            );
          } else {
            this.postes = this.agence.emplois.map((emploi) => ({
              ...emploi.poste,
              emploiId: emploi.id
            }));
          }

          this.totalOffres = this.postes.length;
          this.cd.markForCheck();
        }
      })
    );
  }

  private getAllEmplois(): void {
    this.subscriptions.add(
      combineLatest([
        this.emploisService.getPostes([1, 2]),
        this.emploisService.getEmplois()
      ]).subscribe(([poste, { nbr_postes }]) => {
        this.totalOffres = nbr_postes;
        this.postes = poste.data;
        this.cd.markForCheck();
      })
    );
  }

  back() {
    this.location.back();
  }
}
