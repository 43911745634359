<div class="porte-ouverte">
	<div class="breadcrumb w-full">
		<div class="breadcrumb-content">
			<div class="breadcrumb-back" (click)="goBack()">
				<div class="breadcrumb-back-arrow"><span class="icon-arrow-left"></span></div>
				<p class="display-text-16px">{{ 'previous' | transloco }}</p>
			</div>
			<div class="breadcrumb-links">
				<a routerLink="/">{{ 'homepage' | transloco }}</a>
				<span class="icon-chevron-right"></span>
				@if (agence?.enseigne) {
					<a [routerLink]="['/' + agence?.slug]">{{ agence?.enseigne }}</a>
					<span class="icon-chevron-right"></span>
				}
				@if (agence?.nom) {
					<a [routerLink]="[MainRoutes.Groupes + '/' + agence?.slug]">{{ agence?.nom }}</a>
					<span class="icon-chevron-right"></span>
				}
				<span>{{ 'our' | transloco }} {{ 'open doors' | transloco }} </span>
			</div>
		</div>
	</div>

	<div class="main-containers" [ngClass]="{ 'no-event': !openings.length }">
		@if (loading) {
			<div class="map-loader">
				<div class="background-opacifier"></div>
				<div class="map-loader">
					<app-dot-loader />
				</div>
			</div>
		}

		<div class="flex w-full title">
			<h1 class="display-h2 mr-auto mb-40px">
				<em>{{ 'our' | transloco }} {{ 'open doors' | transloco }} </em>
			</h1>
		</div>

		<div class="portes-ouvertes">
			@for (opening of openings | paginate: { itemsPerPage, currentPage, totalItems }; track opening.id) {
				<app-open-houses-card [opening]="opening" [agence]="agence" [teamId]="teamId" />
			}
			<div class="flex flex-col items-center">
				<app-pagination-link prompt="open doors" [itemsPerPage]="itemsPerPage" />
			</div>
		</div>

		<div class="not-found" [hidden]="!!openings.length">
			<span class="display-h2 mr-10px">0 </span> <span>{{ 'open doors' | transloco }}</span>
			<img src="/assets/imgs/no-event.png" />
			<p class="display-text-15px">{{ 'no open doors' | transloco }}</p>
		</div>
	</div>
</div>
