import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Agence } from '@/models';

@Component({
  selector: 'app-agence-groupement',
  templateUrl: './agence-groupement.component.html',
  styleUrls: ['./agence-groupement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AgenceGroupementComponent {
  @Input() agence!: Agence;
}
