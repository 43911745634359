import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap, throwError } from 'rxjs';

import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    configService: ConfigService
  ) {
    this.baseUrl = `${configService.config.url}/leads`;
  }

  newsletter(email: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/newsletter`, { email });
  }

  contact(details: Record<string, any>): Observable<boolean> {
    return this.http.post<{ error: string, error_description: string }>(`${this.baseUrl}/contact`, details).pipe(
      switchMap(({ error, error_description }) => {
        if (error) {
          return throwError(() => new Error(error_description));
        }
        return of(true);
      })
    );
  }

  annonceContact(annonce_details: object): Observable<any> {
    return this.http.post(`${this.baseUrl}/annonce-contact`, annonce_details);
  }

  customMetier(form_details: object, metier: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/${metier}`, form_details);
  }

  gestion(form_details: object): Observable<any> {
    return this.http.post(`${this.baseUrl}/gestion`, form_details);
  }

  syndic(form_details: object): Observable<any> {
    return this.http.post(`${this.baseUrl}/syndic`, form_details);
  }

  entrepriseCommerce(form_details: object): Observable<any> {
    return this.http.post(`${this.baseUrl}/entreprise-commerce`, form_details);
  }

  submitAgency(details: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/estimation`, details);
  }
}
