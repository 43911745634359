import { Component, input, output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  standalone: false
})
export class PaginationComponent {
  readonly itemsPerPage = input(1);
  readonly prompt = input<string>();

  readonly pageChange = output<number>();

  onPageChange(event: number): void {
    this.pageChange.emit(event);
  }
}
