import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { SeoBottomContent } from '@/models';

@Component({
  selector: 'app-bottom-content',
  templateUrl: './bottom-content.component.html',
  styleUrls: ['./bottom-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BottomContentComponent {
  readonly content = input<SeoBottomContent[]>([]);
  readonly pictureUrl = input<string>();
  readonly title = input<string>();

  public full = false;
}
