@let geoMode = mode();

<div class="input">
  <div class="input-label" [ngClass]="[showError() && address.errors ? 'error' : '']">
    <input
      [ngClass]="!address.errors?.['required'] ? 'transformed' : ''"
      [formControl]="address"
      id="address"
      type="text"
    />

    <label for="address">
      <span class="icon-location not-color"></span> {{ geoMode | transloco }}<span>*</span>
    </label>

    @if (showCloseButton()) {
      <span class="close icon-close" (click)="handleClose('input')"></span>
    }
  </div>

  @if (showError() && address.errors) {
    <app-form-errors [field]="address" />
  }

  @if (showDropdown()) {
    @let list = suggestions();

    @if (list.departements?.length || list.villes?.length || list.adresses?.length) {

      <div class="dropdown" (clickOutside)="handleClose('dropdown')">
        <ul>
          @if (list.villes?.length) {
            <app-form-geoloc-suggestions
              (selected)="handleLocationSelect($event)"
              [list]="list.villes"
              title="cities"
              [mode]="geoMode"
            />
          }
          @if (list.departements?.length) {
            <app-form-geoloc-suggestions
              (selected)="handleLocationSelect($event)"
              [list]="list.departements"
              title="departments"
              [mode]="geoMode"
            />
          }
          @if (list.adresses?.length) {
            <app-form-geoloc-suggestions
              (selected)="handleLocationSelect($event)"
              [list]="list.adresses"
              [mode]="geoMode"
            />
          }
        </ul>
      </div>
    }
  }

</div>
