import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy, OnInit, signal, input, output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, Subscription, switchMap } from 'rxjs';

import { GeographyService } from '@/services/geography.service';
import { GeoAdresse, GeolocFeature, GeolocMode, SearchGeoAutoCompletion, SearchLocation } from '@/models';
import { SEARCH_MIN_LENGTH, SEARCH_DEBOUNCE } from '@/constants';

@Component({
  selector: 'app-form-geoloc',
  templateUrl: './form-geoloc.component.html',
  styleUrl: './form-geoloc.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormGeolocComponent),
    multi: true
  }],
  standalone: false
})
export class FormGeolocComponent implements ControlValueAccessor, OnDestroy, OnInit {
  readonly mode = input<GeolocMode>('address');
  readonly showCloseButton = input(false);
  readonly showError = input(false);
  readonly showCities = input(true);

  readonly geolocChanged = output<SearchLocation>();
  readonly closeClicked = output();

  public suggestions = signal<SearchGeoAutoCompletion>({} as SearchGeoAutoCompletion);
  public address = new FormControl('', Validators.required);
  public showDropdown = signal(false);

  private subscriptions = new Subscription();

  private onChange: any = () => {};
  private onTouched: any = () => {};

  constructor(private geographyService: GeographyService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.address.valueChanges.pipe(
        filter((value) => {
          if (!value) {
            this.clearSuggestions();
            this.onChange('');
            return false;
          }
          return value.length >= SEARCH_MIN_LENGTH;
        }),
        distinctUntilChanged(),
        debounceTime(SEARCH_DEBOUNCE),
        switchMap((value) => (
          this.geographyService.getAutocompletion(value!, this.mode())
        ))
      ).subscribe({
        next: (suggestions) => {
          this.suggestions.set(suggestions);
          this.showDropdown.set(true);
        },
        error: (error) => {
          console.error('GeolocInputComponent error', error);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleLocationSelect(location: SearchLocation): void {
    this.address.setValue(location.nom, { emitEvent: false });

    this.geolocChanged.emit(location);

    this.showDropdown.set(false);
    this.onChange(location.nom);
    this.onTouched();
  }

  handleClose(type: 'input' | 'dropdown'): void {
    this.address.reset();
    switch (type) {
      case 'input':
        this.closeClicked.emit();
        break;
      case 'dropdown':
        this.showDropdown.set(false);
        break;
    }
  }

  getGeoAddress(geoloc: GeolocFeature): GeoAdresse {
    return geoloc?.properties as GeoAdresse;
  }

  writeValue(value: string): void {
    this.address.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private clearSuggestions(): void {
    this.suggestions.set({} as SearchGeoAutoCompletion);
    this.showDropdown.set(false);
  }
}
