import { ChangeDetectionStrategy, Component, HostListener, Input, OnChanges, output } from '@angular/core';

import { HeaderService } from '@/services/header.service';
import { MainRoutes, YOUTUBE_URL } from '@/constants';
import { Agence, AgenceGroupe } from '@/models';

@Component({
  selector: 'app-agence-presentation',
  templateUrl: './agence-presentation.component.html',
  styleUrls: ['./agence-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AgencePresentationComponent implements OnChanges {
  @Input() agenceGroup?: AgenceGroupe;
  @Input() agence?: Agence;

  readonly network = output();

  public MainRoutes = MainRoutes;

  public isHoraireModalVisible = false;
  public isVideoModalVisible = false;
  public videoUrl = '';
  public percent = 0;
  public average = 0;

  constructor(headerService: HeaderService) {
    headerService.setHeaderType('agence');
  }

  ngOnChanges(): void {
    if (this.agence?.avis?.length) {
      const total = this.agence.avis.map((score) => score.avg_score).reduce((a, b) => a + b, 0);
      const sum = total / this.agence.avis.length;

      this.percent = Math.round((sum / 5) * 100);
      this.average = +sum.toPrecision(3);
    }
    this.videoUrl = `${YOUTUBE_URL}${this.getVideoUrl()}?rel=0&autoplay=1`;
  }

  playVideo(): void {
    this.isVideoModalVisible = true;
  }

  closeVideo(): void {
    this.isVideoModalVisible = false;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.isVideoModalVisible = false;
    }
  }

  getVideoUrl(): string {
    // eslint-disable-next-line @stylistic/max-len
    const regex = /(?:https?:)?(?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/;

    if (this.agence) {
      const match = this.agence.video_presentation?.match(regex);
      return match ? match[1] : this.agence.video_presentation ?? '';
    } else if (this.agenceGroup) {
      const match = this.agenceGroup.video_presentation?.match(regex);
      return match ? match[1] : this.agenceGroup.video_presentation ?? '';
    }

    return '';
  }

  onImgError(event: Event): void {
    (event.target as HTMLImageElement).src = '/assets/imgs/agence.jpg';
  }

  showHoraireModal(show: boolean): void {
    this.isHoraireModalVisible = show;
  }
}
