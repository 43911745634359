import { Component, Input, input, output } from '@angular/core';

import { Agence } from '@/models';

@Component({
  selector: 'app-agences-properties',
  templateUrl: './agences-properties.component.html',
  styleUrls: ['./agences-properties.component.scss'],
  standalone: false
})
export class AgencesPropertiesComponent {
  readonly agences = input.required<Agence[]>();
  readonly itemsPerPage = input.required<number>();
  readonly totalItems = input.required<number>();
  @Input() currentPage!: number;
  readonly loading = input(true);
  readonly navByLink = input(false);

  readonly pageChanged = output<number>();

  changePage(page: number): void {
    this.currentPage = page;
    this.pageChanged.emit(this.currentPage);
  }
}
