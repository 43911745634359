@let cities = topCities();

<div class="top-cities">
	<div class="flex justify-between mb-41px">
		<h2 class="display-h2">{{ 'HOME.EXPLORE' | transloco }}</h2>
		<div class="slider-buttons ml-auto">
			<button (click)="scrollRight()" class="icon-chevron-left" aria-label="prev"></button>
			<button (click)="scrollLeft()" class="icon-chevron-right" aria-label="next"></button>
		</div>
	</div>
	<div class="top-cities-container scroll-smooth" id="top-ten">
		@for (city of cities; track city; let i = $index) {
			<a class="city" [routerLink]="city.link.route" [queryParams]="city.link.queryParams">
				<img
					loading="lazy"
					[src]="'/assets/imgs/photo_villes/' + city.id + '.jpg'"
					[alt]="city.nom"
					(error)="onImgError($event)"
				/>
				<h3 class="city-item">
					<div class="city-type">
						<p>{{ city.type_bien | transloco }} {{ (city.type === 'location' ? 'to_rent' : 'to_sell') | transloco }}</p>
					</div>
					<div class="city-name">{{ city.nom }}</div>
				</h3>
			</a>
		}
	</div>
</div>
