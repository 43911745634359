@let actu = actualite();
@let pageMode = page();
@let idx = index();

<div
  class="image-container"
  [ngClass]="{
    firstCardBlog: idx === 0 && pageMode === 'blog',
    secondAndThirdCardBlog: (idx === 1 || idx === 2) && pageMode === 'blog',
    otherCardBlog: idx > 2 && pageMode === 'blog',
    firstCardHome: idx === 0 && pageMode === 'home',
    secondAndThirdCardHome: (idx === 1 || idx === 2) && pageMode === 'home',
    fourthCardHome: idx === 3 && pageMode === 'home',
    cardAgence: pageMode === 'agence'
  }"
>
  <a [routerLink]="[link]" [attr.aria-label]="actu.titre">
    <img
      [src]="actu.image ? actu.image : '/assets/imgs/actualite-era-default.jpg'"
      (error)="onImgError($event)"
      [alt]="actu.titre"
    />
  </a>

  <div class="tags">
    @for (tag of actu.tags; track tag.id) {
      <div class="tag display-text-12px mb-5px" [ngClass]="pageMode === 'home' ? 'home' : ''">
        {{ tag.nom }}
      </div>
    }
  </div>

  <div class="publication-date-tag display-text-12px">
    {{ actu.published_at | date }}
  </div>

  <div class="social-network-tag">
    <div class="network">
      <div class="network-item" (click)="shareArticleDetails()">
        <span class="item"><img src="/assets/imgs/facebook.svg" alt="facebook" /></span>
      </div>
      <a
        href="https://twitter.com/share?url={{ url_front }}/blog-immobilier/article/{{ actu.id }}"
        rel="nofollow"
        target="_blank"
        class="network-item"
      >
        <span class="item"><img src="/assets/imgs/twitter.svg" alt="twitter" /></span>
      </a>
      <a
        href="mailto:?subject=Actualit&eacute;%20&body={{ url_front }}/blog-immobilier/article/{{ actu.id }}"
        rel="nofollow"
        target="_blank"
        class="network-item"
      >
        <span class="item"><img src="/assets/imgs/mail.svg" alt="mail" /></span>
      </a>
    </div>
  </div>
</div>

<a class="details" [routerLink]="[MainRoutes.Blog + '/article/' + actu.id]">
  <p class="title">
    {{ actu.titre }}
  </p>
  @if (actu.accroche && (idx !== 0 && idx !== 1 && idx !== 2)) {
    <p [hidden]="idx === 3 && pageMode === 'home'" class="display-text-16px accroche">
      <span>{{ actu.accroche.slice(0, 75) + "..." }}</span>
    </p>
  }
</a>
