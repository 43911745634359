<pagination-template class="pagination" #p="paginationApi">
	@let totalItems = p.getTotalItems();
	@let currentPage = p.getCurrent();
	@let lastPage = p.isLastPage();
	@let countPerPage = itemsPerPage();

	@if ((totalItems / countPerPage) > 1) {
		<div class="pagination-inner">
			@if (!p.isFirstPage()) {
				@let prev = getUrlPage(currentPage - 1);
				<a class="nav" [routerLink]="prev.route" [queryParams]="prev.queryParams">
					<span class="icon-arrow-left fs-20px"></span>
				</a>
			}

			@for (page of p.pages; track page.label) {
				<div class="pagination-buttons" [class.current]="currentPage === page.value">
					@let item = getUrlPage(page.value);
					@if (currentPage === page.value) {
						<div class="cur">
							<span>{{ page.label }}</span>
						</div>
					} @else if (page.label === '...') {
						<div class="dots"	[routerLink]="item.route" [queryParams]="item.queryParams">
							<span>{{ page.label }}</span>
						</div>
					} @else {
						<a class="unactive" [routerLink]="item.route" [queryParams]="item.queryParams">
							<span>{{ page.label }}</span>
						</a>
					}
				</div>
			}

			@if (!lastPage) {
				@let next = getUrlPage(currentPage + 1);
				<a class="nav" [routerLink]="next.route" [queryParams]="next.queryParams">
					<span class="icon-arrow-right fs-20px"></span>
				</a>
			}
		</div>

		<div class="pagination-resume">
			{{ prompt() | transloco }} {{ 'of' | transloco }}
			<span class="value">
				{{ (totalItems === 0) ? 0 : (currentPage === 1) ? 1 : countPerPage * currentPage - countPerPage + 1 }}
			</span>
			{{ 'to' | transloco }}
			<span class="value">
				{{ lastPage ? totalItems : countPerPage * currentPage }}
			</span>
			{{ 'on' | transloco }}
			<span class="value">{{ totalItems }}</span>
		</div>
	}
</pagination-template>
