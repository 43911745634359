import { Component, input, ChangeDetectionStrategy, output } from '@angular/core';

import { MainRoutes } from '@/constants';
import { AnnonceLight } from '@/models';

@Component({
  selector: 'app-favoris-card',
  templateUrl: './favoris-card.component.html',
  styleUrls: ['./favoris-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FavorisCardComponent {
  readonly annonce = input.required<AnnonceLight>();
  readonly index = input.required<number>();

  readonly toRemoveFromfavoris = output<number>();

  public MainRoutes = MainRoutes;
  public showCoords = [];

  onImgError(event: Event): void {
    (event.target as HTMLImageElement).src = '/assets/imgs/erabw.png';
  }

  numberWithSpaces(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  onRemoveFromfavoris(): void {
    this.toRemoveFromfavoris.emit(this.annonce().id);
  }
}
