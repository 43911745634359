import { Component, output } from '@angular/core';

@Component({
  selector: 'app-carousel-arrows',
  templateUrl: './carousel-arrows.component.html',
  styleUrl: './carousel-arrows.component.scss',
  standalone: false
})
export class CarouselArrowsComponent {
  readonly next = output();
  readonly prev = output();

  handlePrev(): void {
    this.prev.emit();
  }

  handleNext(): void {
    this.next.emit();
  }
}
