@let an = annonce();
@let ag = agence();
@let nego = team();

<div class="block-contact-agency">
  <app-presentation-agency-card type="noPhone" [agence]="ag" [annonce]="an" />

  <div class="block-contact-agency-link">
    @if (an || nego) {
      @let text = nego ? ('of my agency' | transloco) : '(' + (annoncesAgenceMere()?.length ?? ag?.annonces_vente?.length ?? 0) + ')';

      <app-button
        size="large"
        [link]="MainRoutes.Acheter"
        [queryParams]="{ agence_id: ag?.id }"
        text="{{ 'see_all_properties' | transloco }} {{ text }}"
        [translate]="false"
      />

      <div (click)="onOpenModalLegalNotice()" class="link flex mt-20px">
        {{ 'legal_notice_agency' | transloco }}
      </div>

      @if (an && an.agence && an.agence.avis.length) {
        <div class="agence-presentation-text-review">
          <div class="display-flex">
            @if (an.agence.avis.length > 1) {
              <p class="display-text-14px">
                {{ an.agence.avis.length }} {{ 'customers_opinion' | transloco }}
              </p>
            } @else if (an.agence.avis.length === 1) {
              <p class="display-text-14px">
                {{ an.agence.avis_global?.total }} {{ 'customers_opinion' | transloco }}
              </p>
            }
            @if (averageStar(); as average) {
              <app-rating-stars [rate]="average" />
            }
          </div>
          @for (avis of an.agence.avis | slice: 0:1; track avis) {
            <div class="avis">
              <p class="display-text-16px">"{{ avis.comment | truncate:100 }}"</p>
            </div>
          }
          <a [routerLink]="['/' + ag?.slug + '/avis']" class="link mt-20px">
            {{ 'see_others_opinion' | transloco }}
          </a>
        </div>
      }

      @if ((pathUrl()?.startsWith('/agence-') || nego) && ag && ag.avis.length) {
        <div class="agence-presentation-text-review">
          <div class="display-flex">
            @if (ag.avis.length > 1) {
              <p class="display-text-14px">
                {{ ag.avis.length }} {{ 'customers_opinion' | transloco }}
              </p>
            } @else if (ag.avis.length === 1) {
              <p class="display-text-14px">
                {{ ag.avis_global?.total }} {{ 'customers_opinion' | transloco }}
              </p>
            }
            @if (averageStar(); as average) {
              <app-rating-stars [rate]="average" />
            }
          </div>
          @for (avis of ag.avis | slice: 0:1; track avis) {
            <div class="avis">
              <p class="display-text-16px">"{{ avis.comment | truncate:100 }}"</p>
            </div>
          }
          <a [routerLink]="['/' + ag.slug + '/avis']" class="link mt-20px">
            {{ 'see_others_opinion' | transloco }}
          </a>
        </div>
      }
    }
  </div>

  <app-contact-card [agence]="ag" />

  <div class="block-contact-agency-asterisque">
    <p>
      {{ 'data' | transloco }}
      <a class="underline" target="_blank" rel="nofollow" href="{{ 'data_link' | transloco }}">
        {{ 'data_link' | transloco }}
      </a>
    </p>
  </div>
</div>
