<div class="faq">
	<div class="faq-container flex justify-between" (click)="isOpen = !isOpen">
		<h3 class="faq-title display-text-16px flex p-20px" [innerHTML]="question()"></h3>
		@if (isOpen) {
			<span class="icon-chevron-up fs-20px"></span>
		} @else {
			<span class="icon-chevron-down fs-20px"></span>
		}
	</div>
	<div class="faq-content display-text-16px p-20px" [hidden]="!isOpen" [innerHTML]="reponse()"></div>
</div>
