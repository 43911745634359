<div class="statement-page" #content>
	<div class="breadcrumb">
		<div class="breadcrumb-content">
			<div (click)="backButton()" class="header-close">
				<span class="icon-arrow-left"></span>
				<p class="display-text-16px">{{ 'previous' | transloco }}</p>
			</div>
			<div class="breadcrumb-links">
				<span (click)="backButton()">{{ 'homepage' | transloco }}</span>
				<span class="icon-chevron-right"></span>
				@if (agence?.id) {
					<a [routerLink]="['/' + agence?.slug]">{{ agence?.enseigne }}</a>
					<span class="icon-chevron-right"></span>
				}
				<span>{{ 'AGENCE.TESTIMONY' | transloco }}</span>
			</div>
		</div>
	</div>

	@if (filteredAgences) {
		<div class="flex flex-col items-center justify-center w-full">
			<!-- AGENCE AVIS -->
			<div class="main-containers">
				<div class="w-full flex items-center justify-between mb-41px">
					<h1 class="display-h2">
						{{ 'AGENCE.TESTIMONY' | transloco }}
						@if (agence?.id) {
							<span>{{ agence?.enseigne }}</span> ({{ sumAvis() }})
						}
					</h1>
				</div>

				<div class="statement-flex">
					<div class="statement-status">
						<div class="statement-status-item">
							<p class="display-text-18px">{{ 'AGENCE.NOTE' | transloco }}</p>
							<app-rating-stars [rate]="rate" />
							<p class="display-text-18px">
								{{ sumTotal | number:'':'fr-FR' }} <span>{{ 'on' | transloco }}</span> 5
							</p>
						</div>
						<app-rating-bars [notes]="notes" [total]="sumAvis()" (barClicked)="filterIndex($event)" />
					</div>

					<div class="statement-container">
						@for (a of filteredAgences | paginate:{ currentPage, itemsPerPage, totalItems }; track a.id) {
							<app-statement-agence-card [agence]="a" />
						}

						<div class="opinion">
							<p class="display-text-10px grey-icon">{{ 'opinion_system' | transloco }}</p>
						</div>

						<div class="pagination">
							<app-pagination (pageChange)="onPageChange($event)" prompt="testimony" [itemsPerPage]="itemsPerPage" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- AVIS -->
		@if (agence && agence.avis.length) {
			<div class="main-containers statement">
				<div class="w-full flex items-center justify-between mb-41px">
					<h1 class="display-h2">
						{{ 'AGENCE.TESTIMONY' | transloco }}
						@if (agence.id) {
							<span>{{ agence.enseigne }}</span> ({{ agence.avis.length }})
						}
					</h1>
				</div>

				<div class="flex w-full items-center flex-start flex-wrap statement-flex">
					<div class="statement-status">
						<div class="statement-status-item">
							<p class="display-text-18px">{{ 'AGENCE.NOTE' | transloco }}</p>
							<app-rating-stars [rate]="rate" />
							<p class="display-text-18px">
								{{ sumTotal | number:'':'fr-FR' }} <span>{{ 'on' | transloco }}</span> 5
							</p>
						</div>
						<app-rating-bars [notes]="agence.avis_notes ?? []" [total]="agence.avis.length" />
					</div>

					<div class="statement-container">
						@for (avis of agence.avis | paginate:{ currentPage, itemsPerPage, totalItems }; track avis) {
							<app-statement-card [agence]="agence" [avis]="avis" />
						}

						<div class="opinion">
							<p class="display-text-10px grey-icon">{{ 'opinion_system' | transloco }}</p>
						</div>

						<div class="pagination">
							<app-pagination (pageChange)="onPageChange($event)" prompt="testimony" [itemsPerPage]="itemsPerPage" />
						</div>
					</div>
				</div>
			</div>
		}
	}
</div>
