@let showError = error() && validationErrors();

<div class="input-field">
  <div class="input-field-label" [ngClass]="{ 'error': showError }">

    <input readonly [id]="id()" type="number" [disabled]="disabled" />

    <label [for]="id()" class="transformed">
      @if (icon(); as i) {
        <span class="{{ i }} not-color"></span>
      }

      {{ label() | transloco }}

      @if (required) {
        <span>*</span>
      }
    </label>
  </div>

  <div class="input-field-counter" [ngClass]="{ 'error': showError }">
    <div class="input-field-counter-button" (click)="decrement(value)"><span>-</span></div>
    <output>{{ value }}</output>
    <div class="input-field-counter-button plus" (click)="increment(value)"><span>+</span></div>
  </div>
</div>

@if (showError) {
  <app-form-errors [field]="ngControl" />
}
