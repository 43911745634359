import {
  Component, QueryList, AfterContentInit, ChangeDetectionStrategy, ElementRef,
  ContentChildren, OnDestroy, signal, ViewEncapsulation, HostBinding,
  input,
  output
} from '@angular/core';

import { startWith, Subscription } from 'rxjs';

import { CarouselSlideComponent } from './carousel-slide/carousel-slide.component';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CarouselComponent implements AfterContentInit, OnDestroy {
  readonly lightbox = input(false);
  readonly showDots = input(false);
  readonly arrows = input(true);
  readonly height = input<number>();

  readonly changeSlide = output<number>();

  @ContentChildren(CarouselSlideComponent, { read: ElementRef }) slides = new QueryList<ElementRef>();
  @HostBinding('style.height') containerHeight!: string;

  private subscriptions = new Subscription();
  readonly defaultHeight = 390;

  public dots = signal<number[]>([]);
  public currentSlide = 0;

  ngAfterContentInit(): void {
    this.subscriptions.add(
      this.slides.changes.pipe(
        startWith(undefined)
      ).subscribe(() => {
        this.setContainerHeight();
        this.dots.set(Array.from(Array(this.slides.length).keys()));
        this.changeActiveSlide(0);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onPrevClick(e?: Event) {
    e?.stopImmediatePropagation();
    const previous = this.currentSlide - 1;
    this.changeActiveSlide(previous < 0 ? this.slides.length - 1 : previous);
  }

  onNextClick(e?: Event) {
    e?.stopImmediatePropagation();
    const next = this.currentSlide + 1;
    this.changeActiveSlide(next === this.slides.length ? 0 : next);
  }

  changeActiveSlide(slideIndex: number): void {
    const currSlide = this.slides.get(this.currentSlide);
    const nextSlide = this.slides.get(slideIndex);

    this.showSlide(currSlide, false);
    this.showSlide(nextSlide, true);

    this.currentSlide = slideIndex;
    this.changeSlide.emit(slideIndex);
  }

  private showSlide(el?: ElementRef, show = false): void {
    if (el) {
      el.nativeElement.style['visibility'] = show ? 'visible' : 'hidden';
      el.nativeElement.style['opacity'] = show ? 1 : 0;
    }
  }

  private setContainerHeight(): void {
    const firstSlideChild = this.slides.first?.nativeElement?.firstChild;

    if (firstSlideChild && (typeof firstSlideChild?.getBoundingClientRect === 'function')) {
      const slideHeight = firstSlideChild?.getBoundingClientRect().height ?? this.height() ?? this.defaultHeight;
      this.containerHeight = Math.round(slideHeight) + 'px';
    }
  }
}
