import { BehaviorSubject, Observable, throwError, catchError, map, switchMap, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  AnnonceLight, ApiResponse, ConnectedUser, LoginType, Recherche, SearchFilters,
  UpdateUserParams, User, UserApiResponse
} from '@/models';

import { StorageKey, StorageService } from './storage.service';
import { SocialLoginService } from './social-login.service';
import { ConfigService } from './config.service';
import { ToolsService } from './tools.service';

type UserAccount = {
  user: User;
  token: string;
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _currentUserSubject = new BehaviorSubject<ConnectedUser>(undefined);
  private _loginType: LoginType;
  private _authToken = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    private toolsService: ToolsService,
    private configService: ConfigService,
    private storageService: StorageService,
    private socialLoginService: SocialLoginService
  ) {
    const type = this.storageService.getString(StorageKey.LoginType);
    this._loginType = type ? (type as LoginType) : 'default';

    const user = this.storageService.getObject<User>(StorageKey.ConnectedUser);
    this._currentUserSubject.next(user);

    this._authToken = this.storageService.getString(StorageKey.AuthToken) ?? '';
  }

  /**
   * Since AuthService is needed by the authentication guard but Angular launch in parallel APP_INITIALIZER
   * tasks and initial navigation => the auth guard is called before app config file has been retrieved from server.
   * Initializing baseUrl in the service constructor is not possible because config is not yet known.
   * Since we only need to access the baseUrl during user interactions (login, logout, ...),
   * its not a problem to defer the config access via this getter.
   */
  public get baseUrl(): string {
    return `${this.configService.config.url}/user`;
  }

  public get connectedUser$(): Observable<ConnectedUser> {
    return this._currentUserSubject.asObservable();
  }

  public get connectedUser(): ConnectedUser {
    return this._currentUserSubject.value;
  }

  private set connectedUser(user: ConnectedUser) {
    if (user) {
      this.storageService.setObject(StorageKey.ConnectedUser, user);
    } else {
      this.storageService.remove(StorageKey.ConnectedUser);
      this.storageService.remove(StorageKey.SocialUser);
      this.storageService.remove(StorageKey.LoginType);
    }

    this._currentUserSubject.next(user);
  }

  public get authToken(): string | undefined {
    return this._authToken;
  }

  private set authToken(token: string) {
    if (token) {
      this.storageService.setString(StorageKey.AuthToken, token);
    } else {
      this.storageService.remove(StorageKey.AuthToken);
    }

    this._authToken = token;
  }

  public get loginType(): LoginType {
    return this._loginType;
  }

  private set loginType(type: LoginType) {
    this.storageService.setString(StorageKey.LoginType, type);
  }

  public isConnected(): boolean {
    return !!this.connectedUser;
  }

  // USER AUTHENTICATION

  /**
   * Login to the application with email/password
   * @param email The user email
   * @param password The user password
   * @returns An observable ConnectedUser object
   */
  login(email: string, password: string): Observable<ConnectedUser> {
    return this.http.post<UserAccount>(`${this.baseUrl}/login`, {
      email: email.toLowerCase(),
      password
    }).pipe(
      map((result) => {
        this.loginType = 'default';
        this.connectedUser = result.user;
        this.authToken = result.token;
        return this.connectedUser;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  /**
   * Login to the application with Google
   * @returns An observable ConnectedUser object
   */
  loginFb(): Observable<ConnectedUser> {
    const login = this.socialLoginService.socialLogin('facebook');
    return login ? login.pipe(
      switchMap(({ user, accessToken }) => {
        return this.http.post<UserAccount>(`${this.baseUrl}/login/facebook?access_token=${accessToken}`, user);
      }),
      map((result) => {
        this.loginType = 'facebook';
        this.connectedUser = result.user;
        this.authToken = result.token;
        return this.connectedUser;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    ) : of(undefined);
  }

  /**
   * Login to the application with Facebook
   * @returns An observable ConnectedUser object
   */
  loginGo(): Observable<ConnectedUser> {
    const login = this.socialLoginService.socialLogin('google');
    return login ? login.pipe(
      switchMap(({ user, idToken }) => {
        return this.http.post<UserAccount>(`${this.baseUrl}/login/google?client_id=${idToken}`, user);
      }),
      map((result) => {
        this.loginType = 'google';
        this.connectedUser = result.user;
        this.authToken = result.token;
        return this.connectedUser;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    ) : of(undefined);
  }

  /**
   * Logout from application. First do social logout if needed, then local one.
   * @returns An observable boolean, always true in face.
   */
  logout(): Observable<boolean> {
    return this.connectedUser ? this.socialLoginService.socialLogout().pipe(
      map(() => {
        this.connectedUser = undefined;
        this.authToken = '';
        this.router.navigate(['/']);
        return true;
      })
    ) : of(true);
  }

  // USER ACCOUNT

  /**
   * Create a new user account
   * @param accountDetails The user details.
   * @returns A UserAccount observable
   */
  register(accountDetails: object): Observable<UserAccount> {
    return this.http.post<UserAccount>(`${this.baseUrl}/register`, accountDetails);
  }

  /**
   * Request an account password reset.
   * @param email The user account email.
   * @returns A request observable
   */
  resetPassword(email: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/request-new-password`, { email });
  }

  /**
   * Change user account request
   * @param token The token received from backend
   * @param email The account email
   * @param password The new account password
   * @param password_confirmation FOR COMPATIBILITY ONLY
   * @returns A request observable
   */
  newPassword(token: string, email: string, password: string, password_confirmation: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/reset-password`, {
      password,
      password_confirmation,
      email,
      token
    });
  }

  /**
   * Update user account
   * @param userDetails A partial account object contailing onnly changed informations
   * @returns A UserApiResponse observable.
   */
  updateUser(userDetails: UpdateUserParams): Observable<UserApiResponse> {
    const {
      optin_era, optin_partners, optin_nl, email, nom, prenom, telephone,
      password, new_password
    } = userDetails;

    const params: any = { optin_nl, optin_era, optin_partners };

    if (email) params.email = email;
    if (nom) params.nom = nom;
    if (prenom) params.prenom = prenom;
    if (telephone) params.telephone = telephone;
    if (password) params.password = password;
    if (new_password) params.new_password = new_password;

    const httpParams = new HttpParams({ fromObject: params });

    return this.http.post<UserApiResponse>(this.baseUrl, httpParams).pipe(
      map((response) => {
        if (response.success) {
          this.connectedUser = response.user;
        }
        return response;
      })
    );
  }

  /**
   * Remove user account
   * @returns A request observable.
   */
  removeUser(): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.baseUrl}/unsubscribe`, {});
  }

  // SAVED FAVORITES

  /**
   * Get user favorites annonces list
   * @returns A AnnonceLight observable array
   */
  getUserFavorites(): Observable<ApiResponse<AnnonceLight[]>> {
    return this.http.get<ApiResponse<AnnonceLight[]>>(`${this.baseUrl}/favoris`);
  }

  /**
   * Add a new favorite annonce to the user account
   * @param annonce_id The annonce identifier
   * @returns An ApiResponse observable.
   */
  addFavorisToUserAccount(annonce_id: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.baseUrl}/favoris/add`, { annonce_id });
  }

  /**
   * Remove a favorite annonce from the user account
   * @param annonce_id The annonce identifier
   * @returns An ApiResponse observable
   */
  removeFavorisFromUserAccount(annonce_id: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.baseUrl}/favoris/remove`, { annonce_id }).pipe(
      map((result) => {
        if (result.success && this.connectedUser) {
          this.connectedUser = {
            ...this.connectedUser,
            favoris: this.connectedUser.favoris.filter((annonce) => annonce.id !== annonce_id)
          };
        }
        return result;
      })
    );
  }

  // SAVED SEARCHS

  /**
   * Save a new search to the user account. Update user object upon completion.
   * @param search The search parameters
   * @returns A Recherche observable
   */
  addAnnoncesSearchToUserAccount(search: SearchFilters): Observable<boolean> {
    const { searchLocations, ...rest } = search;

    const allDetails = {
      ...rest,
      // REMOVE GEOLOC type_bien: search.typeBien?.toString(),
      // REMOVE GEOLOC ville: search.searchLocations?.map((el) => el.nom).toString(),
      geo_ville_id: this.toolsService.extractIdsFromLocations('ville', searchLocations),
      geo_departement_id: this.toolsService.extractIdsFromLocations('departement', searchLocations)
    };

    return this.http.post<Recherche>(`${this.baseUrl}/recherche/add`, allDetails).pipe(
      map((result) => {
        if (result?.id && this.connectedUser) {
          const updatedUser = {
            ...this.connectedUser,
            recherches: [
              ...this.connectedUser.recherches,
              result
            ]
          };
          this.connectedUser = updatedUser;
          return true;
        }
        return false;
      })
    );
  }

  /**
   * Update a user search parameters. Update user object upon completion.
   * @param recherche_id The search object identifier
   * @param alert The alert flag
   * @param alert_frequency The alert frequency mode
   * @returns A Recherche observable
   */
  editAnnoncesSearchFromUserAccount(recherche_id: number, alert: boolean, alert_frequency = ''): Observable<boolean> {
    return this.http.post<Recherche>(`${this.baseUrl}/recherche/edit`, new HttpParams({
      fromObject: { recherche_id, alert, alert_frequency }
    })).pipe(
      map((result) => {
        if (result?.id && this.connectedUser) {
          this.connectedUser = {
            ...this.connectedUser,
            recherches: this.connectedUser.recherches.map((r) => (r.id === recherche_id) ? result : r)
          };
          return true;
        }
        return false;
      })
    );
  }

  /**
   * Remove the given search from the user account. Update user object upon completion.
   * @param recherche_id The search object identifier
   * @returns An ApiResponse observable
   */
  removeAnnoncesSearchIntoUserAccount(recherche_id: number): Observable<ApiResponse> {
    const params = new HttpParams({
      fromObject: { recherche_id }
    });

    return this.http.post<ApiResponse>(`${this.baseUrl}/recherche/remove`, params).pipe(
      map((result) => {
        if (result.success && this.connectedUser) {
          this.connectedUser = {
            ...this.connectedUser,
            recherches: this.connectedUser.recherches.filter((r) => (r.id !== recherche_id)) ?? []
          };
        }
        return result;
      })
    );
  }
}
