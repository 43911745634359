import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-donut',
  templateUrl: './donut.component.html',
  styleUrl: './donut.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class DonutComponent {
  readonly size = input<'large' | 'small' | 'tiny'>('small');
  readonly first = input(0);
  readonly second = input(0);
  readonly text = input<string>();
  readonly textSub = input<string>();
}
