import { afterNextRender, Injectable, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';

import { LoginType } from '@/models';
import { ConfigService } from './config.service';
import { StorageKey, StorageService } from './storage.service';

/// <reference path="../../../node_modules/@types/gapi/index.d.ts" />
/// <reference path="../../../node_modules/@types/gapi.auth2.index.d.ts" />
declare let gapi: any;

declare let FB: any;

export type SocialUser = {
  user: {
    id: string | number;
    name: string;
    email: string;
  };
  idToken?: string;
  accessToken: string;
};

@Injectable({
  providedIn: 'root'
})
export class SocialLoginService {
  private authInstance?: gapi.auth2.GoogleAuth;
  private gapiSetup = false;

  constructor(
    private storageService: StorageService,
    private configService: ConfigService,
    private ngZone: NgZone
  ) {
    afterNextRender(() => {
      this.initFB();
      this.initGO();
    });
  }

  socialLogin(type: LoginType): Observable<SocialUser> | undefined {
    switch (type) {
      case 'facebook':
        return this.fbLogin();
      case 'google':
        return this.goLogin();
    }
    return undefined;
  }

  socialLogout(): Observable<any> {
    switch (this.storageService.getString(StorageKey.LoginType)) {
      case 'facebook':
        return this.fbLogout();
      case 'google':
        return this.goLogout();
    }
    return of(undefined);
  }

  // GOOGLE

  initGO(): void {
    this.ngZone.run(() => {
      gapi.load('auth2', () => {
        gapi.auth2.init({
          client_id: this.configService.config.google_client_id,
          plugin_name: 'ERA'
        }).then((auth: gapi.auth2.GoogleAuth) => {
          this.authInstance = auth;
          this.gapiSetup = true;
        }).catch((error: any) => {
          console.error('GAPI error', error);
        });
      });
    });
  }

  private goLogin(): Observable<SocialUser> {
    return new Observable((observer) => {
      if (this.authInstance) {
        this.authInstance.signIn().then((gUser) => {
          const { access_token, id_token } = gUser.getAuthResponse(true);
          const googleUser = this.authInstance!.currentUser.get();
          const profile = googleUser.getBasicProfile();

          const user = {
            id: profile.getId(),
            name: profile.getName(),
            email: profile.getEmail()
          };

          observer.next({ user, accessToken: access_token, idToken: id_token });
          observer.complete();
        }).catch((error) => {
          observer.error(error);
          observer.complete();
        });
      } else {
        observer.error(new Error('Google API not initialized'));
        observer.complete();
      }
    });
  }

  private goLogout(): Observable<void> {
    return new Observable((observer) => {
      const logout = window.open('https://www.google.com/accounts/Logout', 'popup', 'width=320, height=400');
      setTimeout(() => {
        logout?.close();
        observer.next();
        observer.complete();
      }, 500);
    });
  }

  // FACEBOOK

  private initFB(): void {
    (window as any).fbAsyncInit = () => {
      FB.init({
        appId: this.configService.config.facebook_client_id,
        cookie: true,
        xfbml: true,
        version: 'v3.1'
      });
      FB.AppEvents.logPageView();
    };

    (function(d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s) as HTMLImageElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  private fbLogin(): Observable<SocialUser> {
    return new Observable((observer) => {
      FB.login((response: any) => {
        if (response.authResponse) {
          const { userID, accessToken } = response.authResponse;
          FB.api(userID, (user: any) => {
            if (user && !user.error) {
              observer.next({ user, accessToken });
              observer.complete();
            }
          });
        } else {
          observer.error(new Error('Facebook login failed'));
          observer.complete();
        }
      }, { scope: 'email' });
    });
  }

  private fbLogout(): Observable<any> {
    return new Observable((observer) => {
      FB.getLoginStatus((response: any) => {
        if (response.status === 'connected') {
          FB.logout((responseLogout: any) => {
            observer.next(responseLogout);
            observer.complete();
          });
        } else {
          observer.next();
          observer.complete();
        }
      });
    });
  }
}
