import { Directive, ElementRef, HostListener, output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[clickOutside]',
  standalone: false
})
export class ClickOutsideDirective {
  readonly clickOutside = output();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onMouseEnter(targetElement: Element) {
    if (!this.elementRef.nativeElement.contains(targetElement)) {
      this.clickOutside.emit();
    }
  }
}
