import { Component, input } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  standalone: false
})
export class FaqComponent {
  readonly question = input.required<string>();
  readonly reponse = input.required<string>();

  public isOpen = false;
}
