import { ControlContainer, ControlValueAccessor, FormGroup, NgControl } from '@angular/forms';
import { ChangeDetectionStrategy, Component, OnInit, Self, input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

type Civility = {
  id: number;
  value: string
};

@Component({
  selector: 'app-form-civility',
  templateUrl: './form-civility.component.html',
  styleUrl: './form-civility.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FormCivilityComponent implements OnInit, ControlValueAccessor {
  readonly validationErrors = input(true);

  public formControlName!: string | number;
  public parentForm!: FormGroup;
  public civilities: Civility[];

  constructor(
    @Self() public ngControl: NgControl,
    private controlContainer: ControlContainer,
    translate: TranslocoService
  ) {
    ngControl.valueAccessor = this;
    this.civilities = [
      { id: 1, value: translate.translate('civility_male') },
      { id: 2, value: translate.translate('civility_female') }
    ];
  }

  ngOnInit(): void {
    this.parentForm = this.controlContainer.control as FormGroup;
    this.formControlName = this.ngControl.name!;
  }

  // ControlValueAccessor methods
  writeValue(): void {}
  registerOnChange(): void {}
  registerOnTouched(): void {}
}
