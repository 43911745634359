import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, viewChild } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { MetadataService } from '@/services/metadata.service';
import { AgencesService } from '@/services/agences.service';
import { Agence, Avis, CurrentAgence } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class StatementComponent implements OnInit, OnDestroy {
  readonly content = viewChild.required<ElementRef>('content');

  private subscriptions = new Subscription();

  public MainRoutes = MainRoutes;
  public agence?: Agence;
  public name?: string;
  public avis?: Avis[];
  public itemsPerPage = 8;
  public currentPage = 1;
  public isGroupe = false;
  public moyenne = 0;
  public notes: number[] = [];
  public isLoading = true;

  constructor(
    private agencesService: AgencesService,
    private route: ActivatedRoute,
    private location: Location,
    private meta: MetadataService,
    private gtmService: GoogleTagManagerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.agencesService.agence$.subscribe((agence) => {
        this.setAgence(agence);
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  backButton(): void {
    this.location.back();
  }

  private setAgence(agence?: CurrentAgence) {
    if (agence) {
      this.isGroupe = (agence.type === 'groupe');
      this.name = this.isGroupe ? agence.data.nom : agence.data.enseigne;
      this.agence = agence.data;

      const { title, description, ogTitle, ogDescription, ogUrl } = this.route.snapshot.data;
      const { id, slug, ville, codepostal } = this.agence;

      this.gtmService.pushTag({
        'event': 'Pageview',
        'id-agence': id,
        'nom-agence': this.name,
        'pagetype': 'Avis'
      });

      this.meta.setMetadata({
        title,
        description,
        ogTitle,
        ogDescription,
        ogUrl,
        params: {
          nom_agence: this.name,
          slug_agence: slug,
          ville: ville,
          code_postal: codepostal
        }
      });

      if (this.isGroupe) {
        this.subscriptions.add(
          this.agencesService.getGroupeAgencesReviewById(id).subscribe({
            next: (data) => {
              this.moyenne = data.avis_moyenne[0];
              this.notes = data.notes;
              this.avis = data.avis;
              this.isLoading = false;
              this.cd.markForCheck();
            },
            error: (error) => {
              console.error('Error loading groupe statements', error);
              this.isLoading = false;
              this.cd.markForCheck();
            }
          })
        );
      } else {
        this.moyenne = this.agence.avis_moyenne ?? 0;
        this.notes = this.agence.avis_notes ?? [];
        this.avis = this.agence.avis;
        this.isLoading = false;
        this.cd.markForCheck();
      }
    }
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.content().nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
