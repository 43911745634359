import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { Agence, Annonce, Emploi } from '@/models';

@Component({
  selector: 'app-presentation-agency-card',
  templateUrl: './presentation-agency-card.component.html',
  styleUrls: ['./presentation-agency-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PresentationAgencyCardComponent {
  readonly agence = input<Agence>();
  readonly annonce = input<Annonce>();
  readonly emploi = input<Emploi>();
  readonly type = input<'era' | 'negociateur' | 'agence' | 'annonce' | 'team' | 'noPhone'>();
}
