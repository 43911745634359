import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, input, signal, viewChild } from '@angular/core';

import { Agence, AgenceGroupe, Avis, Negociateur } from '@/models';

@Component({
  selector: 'app-statement-card',
  templateUrl: './statement-card.component.html',
  styleUrls: ['./statement-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class StatementCardComponent implements AfterViewInit {
  readonly commentText = viewChild<ElementRef>('commentText');

  readonly avis = input.required<Avis>();
  readonly agence = input<Agence>();
  readonly groupeAgence = input<AgenceGroupe>();
  readonly team = input<Negociateur>();

  public showMoreButton = signal(false);
  public showMore = false;

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.commentText()?.nativeElement;
      if (element) {
        this.showMoreButton.set(element.scrollHeight > element.clientHeight);
      }
    });
  }
}
