import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import { SearchLocation } from '@/models';

@Component({
  selector: 'app-form-geoloc-suggestions',
  templateUrl: './form-geoloc-suggestions.component.html',
  styleUrl: './form-geoloc-suggestions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FormGeolocSuggestionsComponent {
  list = input<SearchLocation[]>();
  title = input('');
  mode = input('');

  selected = output<SearchLocation>();

  handleLocationSelect(item: SearchLocation): void {
    this.selected.emit(item);
  }
}
