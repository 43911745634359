import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { RouteObserverService } from '@/services/route-observer.service';
import { MetadataService } from '@/services/metadata.service';
import { AgencesService } from '@/services/agences.service';
import { ConfigService } from '@/services/config.service';
import { Agence, Annonce } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-open-houses',
  templateUrl: './open-houses.component.html',
  styleUrls: ['./open-houses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class OpenHousesComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  public MainRoutes = MainRoutes;
  public openings: Annonce[] = [];
  public agence?: Agence;
  public currentPage = 1;
  public itemsPerPage = 8;
  public totalItems = 0;
  public loading = true;
  public teamId?: number;

  constructor(
    private routeObserver: RouteObserverService,
    private agencesService: AgencesService,
    private configService: ConfigService,
    private meta: MetadataService,
    private cd: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    const { data, url, fragment } = this.routeObserver.currentRoute;

    // SEO
    this.meta.setMetadata({
      title: data.title,
      description: data.description,
      ogTitle: data.ogTitle,
      ogDescription: data.ogDescription,
      ogUrl: `${this.configService.config.url_front}${url}`
    });

    this.teamId = fragment ? +fragment : undefined;
    this.loading = true;

    this.subscriptions.add(
      this.agencesService.agence$.subscribe((currentAgence) => {
        if (currentAgence) {
          this.agence = currentAgence.data;
          this.openings = this.agence!.annonces?.filter((annonce: Annonce) => annonce.openhouse) ?? [];
          this.totalItems = this.openings.length;
          this.loading = false;
          this.cd.markForCheck();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  goBack() {
    this.location.back();
  }
}
