import { Component } from '@angular/core';

@Component({
  selector: 'app-agence-card-loader',
  templateUrl: './agence-card-loader.component.html',
  styleUrls: ['./agence-card-loader.component.scss'],
  standalone: false
})
export class AgenceCardLoaderComponent {
}
