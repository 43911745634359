import { Component, input } from '@angular/core';

@Component({
  selector: 'app-nothing-found',
  templateUrl: './nothing-found.component.html',
  styleUrls: ['./nothing-found.component.scss'],
  standalone: false
})
export class NothingFoundComponent {
  readonly text = input.required<string>();
  readonly content = input.required<string>();
}
