import { ChangeDetectionStrategy, Component, OnInit, input } from '@angular/core';

import { Agence, Avis, Negociateur, AgenceGroupe } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-opinion',
  templateUrl: './opinion.component.html',
  styleUrls: ['./opinion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class OpinionComponent implements OnInit {
  readonly agence = input<Agence>();
  readonly team = input<Negociateur>();
  readonly groupeAgence = input<AgenceGroupe>();

  public MainRoutes = MainRoutes;

  public rate = 0;
  public avisLink = '';
  public avisList: Avis[] = [];
  public total = 0;

  ngOnInit(): void {
    const groupeAgence = this.groupeAgence();
    const agenceValue = this.agence();
    const team = this.team();

    if (agenceValue) {
      const { avis, avis_moyenne, slug } = agenceValue;

      this.setData(avis.length, avis_moyenne ?? 0, `/${slug}/avis`, avis);
    }

    if (groupeAgence) {
      const { agences, avis_total, avis_moyenne, slug } = groupeAgence;
      const list = agences.flatMap((agence) => agence.avis);

      this.setData(avis_total, avis_moyenne ?? 0, `${MainRoutes.Groupes}/${slug}/temoignages`, list);
    }

    if (team) {
      const { avis, id } = team;
      const rate = this.total > 0 ? avis.map((item) => item.avg_score).reduce((a, b) => a + b, 0) / this.total : 0;

      this.setData(avis.length, rate, `${MainRoutes.Effectifs}/${id}/avis`, avis);
    }
  }

  private setData(total: number, rate: number, link: string, list: Avis[]): void {
    this.total = total;
    this.rate = +rate.toFixed(2);
    this.avisLink = link;
    this.avisList = list.filter((item) => item.comment.length > 0);
  }
}
