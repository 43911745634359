import { Component, ChangeDetectionStrategy, OnInit, input, output } from '@angular/core';

import { RouteObserverService } from '@/services/route-observer.service';
import { ConfigService } from '@/services/config.service';
import { Actualite, Agence, CurrentRoute, BlogPageMode, BlogCardEvent } from '@/models';
import { FACEBOOK_URL, MainRoutes } from '@/constants';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BlogCardComponent implements OnInit {
  readonly actualite = input.required<Actualite>();
  readonly page = input<BlogPageMode>();
  readonly agence = input<Agence>();
  readonly groupe = input(false);
  readonly index = input(0);

  readonly actualiteEmt = output<BlogCardEvent>();

  public MainRoutes = MainRoutes;
  public route: CurrentRoute;
  public url_front: string;
  public link!: string;

  constructor(
    routeObserver: RouteObserverService,
    configService: ConfigService
  ) {
    this.url_front = configService.config.url_front;
    this.route = routeObserver.currentRoute;
  }

  ngOnInit(): void {
    const article = this.actualite();
    const agence = this.agence();

    if (!agence) {
      this.link = `${MainRoutes.Blog}/article/${article.id}`;
    } else if (this.route.type === MainRoutes.Groupes) {
      this.link = `${MainRoutes.Groupes}/${agence.slug}${MainRoutes.Blog}/article/${article.id}`;
    } else {
      this.link = `/${agence?.slug}${MainRoutes.Blog}/article/${article.id}`;
    }
  }

  shareArticleDetails(): void {
    const article = this.actualite();
    const urlToShare = `${FACEBOOK_URL}?u=${this.url_front}${MainRoutes.OffreEmploi}/article/${article.id};src=sdkpreparse`;

    this.actualiteEmt.emit({ article, urlToShare });
  }

  onImgError(event: Event): void {
    (event.target as HTMLImageElement).src = '/assets/imgs/actualite-era-default.jpg';
  }
}
