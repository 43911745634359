import { Component, input, output } from '@angular/core';

import { Agence, Annonce, Negociateur } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-contact-agency-card',
  templateUrl: './contact-agency-card.component.html',
  styleUrls: ['./contact-agency-card.component.scss'],
  standalone: false
})
export class ContactAgencyCardComponent {
  readonly annonce = input<Annonce>();
  readonly agence = input<Agence>();
  readonly pathUrl = input<string>();
  readonly team = input<Negociateur>();
  readonly annoncesAgenceMere = input<Annonce[]>();

  readonly openModalLegalNotice = output();

  public MainRoutes = MainRoutes;

  averageStar(): number {
    const annonce = this.annonce();
    const agence = this.agence();

    if (annonce && annonce.agence.avis) {
      const total = annonce.agence.avis.map((score) => score.avg_score).reduce((a, b) => a + b, 0);
      const sum = total / annonce.agence.avis.length;

      return +sum.toFixed(2);
    }

    if (agence && agence.avis) {
      const total = agence.avis.map((score) => score.avg_score).reduce((a, b) => a + b, 0);
      const sum = total / agence.avis.length;

      return +sum.toFixed(2);
    }

    return 0;
  }

  onOpenModalLegalNotice() {
    this.openModalLegalNotice.emit();
  }
}
