import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, signal } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

import { GeographyService } from '@/services/geography.service';
import { AnnoncesService } from '@/services/annonces.service';
import { FilterService, RouteResult } from '@/services/filter.service';
import { FilterTypeAnnonce, SearchFilters, TopDatum } from '@/models';
import { SCROLL_AMOUNT } from '@/constants';

type TypeBien = 'appartement' | 'maison';

type CityRoute = {
  id: number;
  nom: string;
  type: FilterTypeAnnonce;
  type_bien: TypeBien;
  link: RouteResult;
};

@Component({
  selector: 'app-top-city',
  templateUrl: './top-city.component.html',
  styleUrls: ['./top-city.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TopCityComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  public topCities = signal<CityRoute[]>([]);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private geographyService: GeographyService,
    private annoncesService: AnnoncesService,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.annoncesService.getTopCities('ville', 'Maison,Appartement').subscribe(({ Location, Vente }) => {

        const locationFlat = Location!['Appartement'].ville.map((ville) => this.mapVille(ville, 'appartement', 'location'));
        const locationHouse = Location!['Maison'].ville.map((ville) => this.mapVille(ville, 'maison', 'location'));
        const sellingFlat = Vente!['Appartement'].ville.map((ville) => this.mapVille(ville, 'appartement', 'vente'));
        const sellingHouse = Vente!['Maison'].ville.map((ville) => this.mapVille(ville, 'maison', 'vente'));

        this.topCities.set([...sellingFlat, ...sellingHouse, ...locationFlat, ...locationHouse]);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  scrollLeft() {
    this.document.getElementById('top-ten')!.scrollLeft += SCROLL_AMOUNT;
  }

  scrollRight() {
    this.document.getElementById('top-ten')!.scrollLeft -= SCROLL_AMOUNT;
  }

  onImgError(event: Event): void {
    (event.target as HTMLImageElement).src = '/assets/imgs/photo_villes/default.jpg';
  }

  private mapVille = (ville: TopDatum, type_bien: TypeBien, type: FilterTypeAnnonce): CityRoute => {
    const location = this.geographyService.toLocation(ville)!;

    const filters: SearchFilters = {
      searchLocations: [location],
      type_annonce: type,
      typeBien: [type_bien]
    };

    const link = this.filterService.buildRouteFromFilters({ mode: 'annonces', navigate: false }, filters);

    return { id: ville.id, nom: ville.nom, type_bien, type, link };
  };
}
