import { Component, input } from '@angular/core';

import { Agence, AgenceGroupe } from '@/models';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],
  standalone: false
})
export class NetworkComponent {
  readonly agence = input<Agence>();
  readonly agenceGroup = input<AgenceGroupe>();
  readonly header = input(false);
  readonly footer = input(false);
  readonly profile = input(false);
}
