import { Component, OnInit, ElementRef, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Inject, viewChild } from '@angular/core';
import { filter, Observable, Subscription, switchMap } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { DOCUMENT, Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { RouteObserverService } from '@/services/route-observer.service';
import { AnnoncesService } from '@/services/annonces.service';
import { AgencesService } from '@/services/agences.service';
import { ToolsService } from '@/services/tools.service';
import { Agence, Annonce, ApiAnnoncesResponse } from '@/models';
import { SortType } from '@/constants';

type SortSelect = {
  value: SortType;
  label: string;
};

@Component({
  selector: 'app-vendu',
  templateUrl: './vendu.component.html',
  styleUrls: ['./vendu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class VenduComponent implements OnInit, OnDestroy {
  readonly container = viewChild.required<ElementRef>('container');

  private subscriptions = new Subscription();

  public annonces?: Annonce[];
  public agence?: Agence;
  public currentPage = 1;
  public itemsPerPage = 9;
  public totalItems = 0;
  public isLoading = true;
  public sorts: SortSelect[];
  public sort = new FormControl<SortType>('desc');

  private sortValue?: SortType;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private routeObserver: RouteObserverService,
    private annoncesService: AnnoncesService,
    private agencesService: AgencesService,
    private toolsService: ToolsService,
    private cd: ChangeDetectorRef,
    private location: Location,
    private router: Router,
    translate: TranslocoService
  ) {
    this.sorts = [
      { value: 'asc', label: translate.translate('older') },
      { value: 'desc', label: translate.translate('recent') }
    ];
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.routeObserver.currentRoute$.pipe(
        switchMap((route) => {
          this.currentPage = (+route.queryParams['page'] || 1);
          this.sortValue = route.queryParams['sort'] as SortType || 'desc';
          this.sort.setValue(this.sortValue);
          return this.agencesService.agence$;
        }),
        filter((data) => !!data),
        switchMap(({ data }) => {
          this.agence = data;
          this.cd.markForCheck();
          return this.getAnnoncesSorted();
        })
      ).subscribe({
        next: ({ data, meta }) => {
          this.totalItems = meta.total || 0;
          this.annonces = data;
          this.isLoading = false;
          this.cd.markForCheck();
        },
        error: (error) => {
          console.error('Error reading annonces list', error);
          this.isLoading = false;
          this.cd.markForCheck();
        }
      })
    );

    this.subscriptions.add(
      this.sort.valueChanges.subscribe((newSort) => {
        if (this.sortValue !== newSort) {
          const { route, queryParams } = this.toolsService.updateSearchParams(this.document, { page: '1', sort: newSort ?? 'desc' });
          this.router.navigate([route], { queryParams });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getAnnoncesSorted(): Observable<ApiAnnoncesResponse> {
    this.annonces = undefined;
    this.isLoading = true;
    this.totalItems = 0;

    return this.annoncesService.getAllAnnonces(
      this.itemsPerPage,
      this.sortValue,
      ['2'],
      'Vente',
      this.currentPage,
      this.agence?.era_id,
      false,
      'date_vente'
    );
  }

  back() {
    this.location.back();
  }
}
