import { ChangeDetectionStrategy, ChangeDetectorRef, Component, input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { RouteObserverService } from '@/services/route-observer.service';
import { ToastService } from '@/modules/toast/services/toast.service';
import { AnnoncesService } from '@/services/annonces.service';
import { AgencesService } from '@/services/agences.service';
import { ConfigService } from '@/services/config.service';
import { Agence, Annonce } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-open-houses-card',
  templateUrl: './open-houses-card.component.html',
  styleUrls: ['./open-houses-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class OpenHousesCardComponent {
  readonly opening = input<Annonce>();
  readonly agence = input<Agence>();
  readonly teamId = input<number>();

  private subscriptions = new Subscription();

  public MainRoutes = MainRoutes;

  public form = new FormGroup({
    nom: new FormControl('', Validators.required),
    prenom: new FormControl('', Validators.required),
    telephone: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email])
  });

  public submitted = false;
  public loading = false;
  public openModalOpening = false;
  public url_front: string;
  public more = false;

  get telephone() {
    return this.form.get('telephone') as FormControl;
  }

  constructor(
    private routeObserver: RouteObserverService,
    private annoncesService: AnnoncesService,
    public agencesService: AgencesService,
    private toastService: ToastService,
    private cd: ChangeDetectorRef,
    configService: ConfigService
  ) {
    this.url_front = configService.config.url_front;
  }

  showMore() {
    this.more = !this.more;
  }

  onOpenModalOpening() {
    this.openModalOpening = true;
  }

  onCloseModalOpening() {
    this.openModalOpening = false;
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;

      const { url } = this.routeObserver.currentRoute;
      let source = 'SOURCE.SITE_NATIONAL';

      if (url.includes('/agence')) {
        source = 'SOURCE.SITE_AGENCE';
      } else if (url.includes('groupes')) {
        source = 'SOURCE.SITE_GROUPE';
      }

      const allDetails: any = {
        ...this.form.value,
        telephone: this.telephone.value.nationalNumber,
        annonce_id: this.opening()?.id,
        api_source: source
      };

      const agence = this.agence();
      const teamId = this.teamId();

      if (teamId) {
        allDetails.effectif_id = teamId;
      } else {
        allDetails.agence_id = agence?.id;
      }

      this.subscriptions.add(
        this.annoncesService.subscribeToOpenHouses(allDetails).subscribe({
          next: () => {
            this.form.reset();
            this.toastService.show({
              title: 'AGENCE.PORTES_OUVERTES.TOAST.TITLE',
              body: 'AGENCE.PORTES_OUVERTES.TOAST.SUCCESS',
              type: 'success'
            });

            this.submitted = false;
            this.loading = false;
            this.cd.markForCheck();
          },
          error: () => {
            this.toastService.show({
              title: 'AGENCE.PORTES_OUVERTES.TOAST.TITLE',
              body: 'AGENCE.PORTES_OUVERTES.TOAST.ERROR',
              type: 'error'
            });

            this.loading = false;
            this.cd.markForCheck();
          }
        })
      );
    }
  }
}
