<div
	class="bg-cover bg-top bg-no-repeat items-center landing-header"
	[ngStyle]="{ 'background-image': 'url(' + image() + ')' }"
>
	<h1 class="display-h1-34px">{{ title() }}</h1>
	<p class="edito display-text-18px lh-28px text-center mt-20px mb-40px">
		{{ edito() }}
	</p>
	@if (options.length) {
		<app-form-select
			[formControl]="route"
			[items]="options()"
			itemKey="route"
			itemLabel="label"
			[label]="selectPlaceholder()"
			icon="icon-location"
			id="item_type"
			class="select-route"
		/>
	}
</div>
