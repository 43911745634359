import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

// TODO-EL: dynamicaly load only the file corresponding to the active language
import * as jsonfr from 'src/assets/seo/annonces/main-fr.json';
import * as jsonen from 'src/assets/seo/annonces/main-en.json';

type TranslationFiles = {
  fr: any;
  en: any;
};

const jsonFiles: TranslationFiles = {
  fr: jsonfr,
  en: jsonen
};

@Pipe({
  name: 'seo',
  standalone: false
})
export class SeoPipe implements PipeTransform {
  private _json: TranslationFiles;

  constructor(private translate: TranslocoService) {
    this._json = (jsonFiles as any)[this.translate.getActiveLang()];
  }

  get json(): any {
    return this._json;
  }

  /**
   * Get the dotted string value and acces the corresponding json item
   * @param value
   * @param replacements
   * @returns
   */
  transform(value: string, replacements: Record<string, string | number>): string {
    try {
      const jsonString = value.split('.').reduce((o, i) => o[i], this.json);
      return this.replace(jsonString, replacements);
    } catch {
      // Not found in json file
      return value;
    }
  }

  /**
   * Iterate over keys and replace all {{keys}} in the text by it's value
   * @param value String template
   * @param replacements Keys/values object for substitutions
   * @returns The completed string
   */
  private replace(value: string, replacements: Record<string, string | number>): string {
    if (replacements && value) {
      Object.keys(replacements).forEach((key) => {
        const pattern = `{{${key}}}`;
        if (value.includes(pattern)) {
          value = value.split(pattern).join(`${replacements[key]}`);
        }
      });
    }
    return value;
  }
}
