import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';

import { Agence, AgenceGroupe } from '@/models';

@Component({
  selector: 'app-modal-legal-notice',
  templateUrl: './modal-legal-notice.component.html',
  styleUrls: ['./modal-legal-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ModalLegalNoticeComponent {
  readonly agence = input<Agence | AgenceGroupe>();
  readonly pageType = input<'agence' | 'annonce'>();

  readonly closeModalLegalNotice = output();

  public agences = computed(() => (this.agence() as AgenceGroupe)?.agences);

  onCloseModalLegalNotice() {
    this.closeModalLegalNotice.emit();
  }
}
