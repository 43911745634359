@let links = listes();

@if (load()) {
	@if (links.length === 0) {
		<div class="crosslinks flex justify-between">
			@for (list of [0,1,2,3]; track list) {
				<div class="crosslink flex flex-col w-1/4">
					<span class="bg-gray-300 h-24 mx-2 animate-pulse"></span>
				</div>
			}
		</div>
	}
} @else if (links.length > 0) {
	<div class="crosslinks flex justify-between">
		@for (list of links; track list; let lindex = $index) {
			<div class="crosslink flex flex-col" [ngClass]="links.length !== 2 ? 'w-1/4' : 'w-1/2'">
				@if (list.title) {
					<h3 class="mb-20px display-h5">{{ list.title | transloco }}</h3>
				}
				<ul>
					@for (item of list.content; track item; let i = $index) {
						<li class="display-text-16px">
							<a routerLink="{{ item.route }}" [ngClass]="i > 5 && hidden[lindex] ? 'hidden' : ''">
								{{ prefix() + item.label }}
							</a>
						</li>
					}
				</ul>
				@if (list.content.length > 5) {
					@if (hidden[lindex]) {
						<span class="mt-26px flex display-text-14px underline cursor-pointer" (click)="hidden[lindex] = false">
							{{ 'see_more' | transloco }}
						</span>
					} @else {
						<span class="mt-26px flex display-text-14px underline cursor-pointer" (click)="hidden[lindex] = true">
							{{ 'see_less' | transloco }}
						</span>
					}
				}
			</div>
		}
	</div>
}
